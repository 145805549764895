import { Box, Typography } from "@mui/material";
import Footer from "../../components/global/Footer";
import { Link } from "@mui/material";

const Certifikacia = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#d8d8d8",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography>
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              marginTop: "45px",
              marginBottom: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: 38, xs: 22 },
                fontFamily: "VisbyCF-Bold",
                color: "#1b144a",
                lineHeight: "44px",
                textAlign: "center",
              }}
            >
              Certifikačná skúška k ISTQB
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { md: "row", xs: "column" },
                alignItems: { md: "start", xs: "center" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "52px",
                    fontSize: { md: 22, xs: 16 },
                    fontFamily: "VisbyCF-Medium",
                    color: "#1d1a56",
                    lineHeight: "28px",
                    maxWidth: "772px",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  Certifikačnú skúšku k jednotlivým školeniam zabezpečujeme
                  prostredníctvom certifikačnej autority CaSTB. Bližšie
                  informácie a postup komunikujeme prihláseným účastníkom
                  konkrétneho kurzu. <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>
                    Prečo získať certifikačnú skúšku k ISTQB?
                    <br />
                    <br />
                  </span>
                  Program ISTQB® Certified Tester je navrhnutý tak, aby ťa
                  naučil efektívne overovať a testovať softvér. Certifikát
                  potvrdzuje tvoje zručnosti a znalosti v oblasti softvérového
                  testovania a overovania a zaručuje ti ešte lepšiu
                  konkurencieschopnosť.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>
                    Výhody ISTQB certifikácie pre profesionálov
                    <br />
                    <br />
                  </span>
                  - Zručnosti nadobudnuté na školení sú medzinárodne uznávané.{" "}
                  <br />- Získaš oprávnenie používať logo "Certifikovaný tester"
                  (označujúce úroveň certifikácie). <br />- Podporuje kariéru od
                  základnej až po expertnú úroveň.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>
                    Výhody ISTQB certifikácie pre spoločnosti
                    <br />
                    <br />
                  </span>
                  - Certifikácia ISTQB® prináša spoločnostiam konkurenčnú výhodu
                  v podobe dôveryhodnosti aplikácií, vyvíjaných prostredníctvom
                  efektívnych testovacích postupov odvodených od kompetencií
                  ISTQB®. <br />- Konzultačné spoločnosti s certifikovaným
                  personálom môžu ponúkať vyššiu úroveň služieb zákazníkom, čím
                  sa zvýšia príjmy a hodnota značky. <br />- ISTQB® definovalo
                  "Partnerský program" pre spoločnosti, ktoré zamestnávajú veľký
                  počet certifikovaných testerov.
                  <br />
                  <br />V prípade súkromnej skúšky (skupina tvorená našimi
                  účastníkmi školenia) prebieha certifikačná skúška v školiacej
                  miestnosti spoločnosti AAS Slovakia. Certifikácia CTFL je
                  predpokladom absolvovania akejkoľvek inej certifikačnej skúšky
                  ISTQB. Okrem expertnej úrovne majú všetky ISTQB® certifikáty
                  neobmedzenú platnosť. Úspešní držitelia certifikátov ISTQB
                  môžu byť na základe vlastnej preferencie pridaní do
                  centrálneho registra držiteľov (ISTQB® Successful Candidate
                  Register:
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://scr.istqb.org/"
                  >
                    scr.istqb.org
                  </Link>
                  ).
                  <br />
                  <br />
                  Kompletné informácie k portfóliu skúšok, forme skúšok,
                  predpokladom a priebehu online skúšky, ako aj k cenám
                  jednotlivých skúšok poskytovaných našim partnerom -
                  certifikačnou autoritou CaSTB, nájdete na stránke:
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://castb.org/examination/"
                  >
                    https://castb.org/examination/
                  </Link>
                  .
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>
                    Výdavky spojené s ISTQB® certifikačnou skúškou nie sú
                    súčasťou ceny školenia.
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Typography>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default Certifikacia;
