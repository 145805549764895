import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "../../components/global/Header";

const NotFound = () => {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontFamily: "VisbyCF-Bold", fontSize: 32 }}>
          404 - Stránka nenájdená!
        </Typography>
        <Typography sx={{ fontFamily: "VisbyCF-Medium", fontSize: 18 }}>
          Stránka, na ktorú sa pokúšate dostať neexistuje.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
