import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Collapse, Link as LinkMui, Button } from "@mui/material";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/global/Footer";
import Home from "../../components/global/Map";
import styles from "../../pages/academy/academy.module.css";
import Form from "../../components/global/FormKontakt";
import { useLocation } from "react-router-dom";
import FormKontaktGlobal from "../../components/global/FormKontaktGlobal";
import FormNewsletter from "../../components/global/FormNewsletter";
import Header from "../../components/global/Header";
import arrow from "../../assets/arrow_modra.svg";

const Kontakt = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const background = useRef(null);
  const [openContactForm, setContactForm] = useState(false);

  const handleClick = (e) => {
    if (e.target.id === "bg") setContactForm(false);
  };

  const handlePhoneClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor element
    const phoneNumber = event.target.getAttribute("href");
    window.location.href = phoneNumber; // Initiates the phone call
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  // newsletter handling
  const backgroundNews = useRef(null);
  const [openNewsForm, setNewsForm] = useState(false);
  const handleClickNews = (e) => {
    if (e.target.id === "bgnews") setNewsForm(false);
  };

  // btn fin
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200; // Adjust this value as needed

      if (scrollPosition > threshold) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // width handling

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      {/* {showContent && windowWidth > 1100 && <ButtonFinancovanie />} */}
      <Header />
      <Box
        sx={{
          backgroundColor: "#d8d8d8",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            zIndex: 3,
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <img
            className={styles.element}
            style={{
              position: "absolute",
              top: 20,
              zIndex: 2,
            }}
            src={require("../../assets/elements6.png")}
            alt="floating_icons"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: { md: "row", xs: "column" },
              alignItems: { md: "unset", xs: "center" },
              maxWidth: "1440px",
              width: "100%",
              zIndex: 3,
            }}
          >
            <Box
              sx={{
                maxWidth: { md: "40%", xs: "unset" },
                width: { md: "100%", xs: "80%" },
                marginLeft: { md: "9vw", xs: "0px" },
                zIndex: 3,
                display: { md: "block", xs: "flex" },
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
                paddingLeft: { md: "0", xs: "20px" },
                paddingRight: { md: "0", xs: "20px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "28px",
                  lineHeight: "32px",
                  color: "#1b144a",
                  marginBottom: "20px",
                  marginTop: "50px",
                  width: { md: "auto", xs: "100%" },
                  textAlign: "center",
                }}
              >
                AAS Solutions a.s.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                }}
              >
                Adresa:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                  marginBottom: "10px",
                }}
              >
                Kalinčiakova 27, 831 04 Bratislava
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                }}
              >
                Telefón:
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#1b144a",
                    textDecoration: "none",
                  }}
                  href="tel:+421220620621"
                  onClick={handlePhoneClick}
                >
                  +421 220 620 621
                </LinkMui>
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "#1b144a",
                    marginBottom: "10px",
                    textDecoration: "none",
                  }}
                  href="tel:+421915110948"
                  onClick={handlePhoneClick}
                >
                  +421 915 110 948
                </LinkMui>
              </Box>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                }}
              >
                E-mail:
              </Typography>
              <LinkMui
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                  textDecoration: "none",
                }}
                href="mailto:academy@aassolutions.sk"
              >
                academy@aassolutions.sk
              </LinkMui>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                  marginTop: "10px",
                }}
              >
                Informácie o školeniach:
              </Typography>
              <LinkMui
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#1b144a",
                  textDecoration: "none",
                }}
                href="mailto:robert.kotuliak@aassolutions.sk"
              >
                robert.kotuliak@aassolutions.sk
              </LinkMui>
              <Box sx={{ width: { md: "auto", xs: "100%" } }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { md: "unset", xs: "center" },
                    alignItems: { md: "unset", xs: "center" },
                    marginBottom: "20px",
                    marginTop: "50px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Bold",
                      fontSize: "28px",
                      lineHeight: "32px",
                      color: "#1b144a",
                      textAlign: { md: "unset", xs: "center" },
                    }}
                  >
                    Fakturačné údaje
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      marginLeft: "15px",
                      marginTop: isOpen ? "-25px" : "-15px",
                      position: "relative",
                    }}
                    onClick={toggleCollapse}
                  >
                    <img
                      style={{
                        rotate: isOpen ? "0deg" : "180deg",
                        width: "70px",
                        // height: "18px",
                        position: "absolute",
                        bottom: windowWidth < 900 ? -50 : "unset",
                      }}
                      src={arrow}
                      alt="arrow_more"
                    />
                  </Box>
                </Box>
                <Collapse in={isOpen} timeout={300} unmountOnExit>
                  <Box
                    sx={{
                      display: { md: "block", xs: "flex" },
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      // paddingRight: { md: "0px", xs: "15px" },
                      // paddingLeft: { md: "0px", xs: "15px" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      Spoločnosť:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      AAS Solutions a.s.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        textAlign: { md: "unset", xs: "center" },
                      }}
                    >
                      Adresa:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      Kalinčiakova 27, 831 04 Bratislava
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      Štát:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      SLOVENSKO{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      IČO:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      55 491 821
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      DIČ:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      2122013421
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      IČ DPH:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      SK2122013421
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        textAlign: { md: "unset", xs: "center" },
                      }}
                    >
                      Zapísaná:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      OR Mestského súdu Bratislava III, Oddiel Sa, Vložka č: 7573/B
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        textAlign: { md: "unset", xs: "center" },
                      }}
                    >
                      Bankové spojenie:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      UniCredit Bank Czech Republic and Slovakia
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Bold",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                      }}
                    >
                      IBAN:
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#1b144a",
                        marginBottom: "10px",
                      }}
                    >
                      SK29 1111 0000 0017 5820 5004
                    </Typography>
                  </Box>
                </Collapse>
              </Box>
            </Box>
            <Box sx={{ zIndex: 3, display: { md: "block", xs: "flex" } }}>
              <Form />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: "500px", zIndex: 99 }}>
          <Home />
        </Box>
        {/* newsletter subscription */}
        <Newsletter setNewsForm={setNewsForm} />
        {/* form newsletter */}
        {openNewsForm && (
          <Box
            onClick={handleClickNews}
            id="bgnews"
            ref={backgroundNews}
            sx={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
              overflow: "auto",
              zIndex: 50, // Set an appropriate z-index value
            }}
          >
            <FormNewsletter setNewsForm={setNewsForm} />
          </Box>
        )}
        <Footer setContactForm={setContactForm}></Footer>
        {openContactForm && (
          <Box
            onClick={handleClick}
            id="bg"
            ref={background}
            sx={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
              overflow: "auto",
              zIndex: 50, // Set an appropriate z-index value
            }}
          >
            <FormKontaktGlobal setContactForm={setContactForm} />
            {/* </Box> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Kontakt;
