import React, { useState, useRef } from "react";
import axios from "axios";
import {
  TextField,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "./formCourse.css";

const FormUcastnik = () => {
  const [SubmitDisabled, setSubmitDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
  const recaptchaRef = useRef(null);

  const handleExpiredToken = () => {
    let token = recaptchaRef.current.getValue();
    if (token) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  };

  const textStyle = {
    "& .MuiOutlinedInput-root": {
      "& input": {
        // padding: "10px",
        // fontSize: "14px", // Font size for the input text
        fontFamily: "VisbyCF-Medium", // Font family for the input text
      },
      "& input::placeholder": {
        // fontSize: "14px", // Font size for the placeholder text
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
    "& .MuiInputLabel-root": {
      typography: {
        // fontSize: "14px", // Font size for the label
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
  };

  const checkBox = {
    height: "20px",
    "& .MuiTypography-root": {
      fontFamily: "VisbyCF-Medium",
      fontSize: 14,
    },
  };

  const [zaujemOCertifikaciu, setZaujemOCertifikaciu] = useState(false);
  const [formValues, setFormValues] = useState({
    training_order_id: "",
    name: "",
    email: "",
    street: "",
    psc: "",
    city: "",
    email: "",
    phone: "",
    birth_date: "",
    title: "",
    rc: "",
    note: "",
    certification: zaujemOCertifikaciu ? "t" : "f",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleZaujemOCertifikaciuChange = (e) => {
    setZaujemOCertifikaciu(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      birth_date: formValues.birth_date,
      certification: zaujemOCertifikaciu ? "t" : "f",
      city: formValues.city,
      email: formValues.email,
      name: formValues.name,
      note: formValues.note,
      phone: formValues.phone,
      psc: formValues.psc,
      rc: formValues.rc,
      street: formValues.street,
      title: formValues.title,
      training_order_id: formValues.training_order_id,
      g_recaptcha_response: recaptchaRef.current.getValue(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}training_participants`,
        requestBody
      );
      const data = response.data;
      setSubmitted(true);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setErrorModal(true);
    }
  };

  return (
    <Box sx={{ display: "flex", paddingBottom: "50px" }}>
      {!submitted && !errorModal && (
        <Box
          sx={{
            background: "white",
            zIndex: 5,
            maxWidth: { md: "unset", xs: "300px" },
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "22px",
            border: "1px solid",
            borderColor: "black",
            paddingBottom: "20px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            ></Box>
            <Box sx={{ display: { md: "flex", xs: "block" }, gap: "10px" }}>
              <TextField
                sx={textStyle}
                id="training_order_id"
                label="Training order id"
                placeholder="Training order id"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                name="training_order_id"
                value={formValues.training_order_id}
                onChange={handleInputChange}
              />
              <TextField
                sx={textStyle}
                id="name"
                label="Meno a Priezvisko"
                placeholder="Meno a Priezvisko"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 30, // Maximum character limit
                }}
              />

              <TextField
                sx={textStyle}
                id="email"
                label="E-mail"
                placeholder="E-mail"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 50, // Maximum character limit
                }}
              />

              <TextField
                sx={textStyle}
                id="title"
                label="Titul"
                placeholder="Titul"
                variant="outlined"
                fullWidth
                margin="normal"
                name="title"
                value={formValues.title}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 10, // Maximum character limit
                }}
              />
            </Box>

            <TextField
              sx={textStyle}
              id="note"
              label="Poznámka"
              placeholder="Poznámka"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              name="note"
              value={formValues.note}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 100,
              }}
            />

            <Box sx={{ display: { md: "flex", xs: "block" }, gap: "10px" }}>
              <TextField
                sx={textStyle}
                id="ulica-popisne-cislo"
                label="Ulica a popisné číslo"
                placeholder="Ulica a popisné číslo"
                variant="outlined"
                fullWidth
                margin="normal"
                name="street"
                value={formValues.street}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 50, // Maximum character limit
                }}
              />

              <TextField
                sx={textStyle}
                id="city"
                label="Mesto"
                placeholder="Mesto"
                variant="outlined"
                fullWidth
                margin="normal"
                name="city"
                value={formValues.city}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 30, // Maximum character limit
                }}
              />

              <TextField
                sx={textStyle}
                id="psc"
                label="PSČ"
                placeholder="PSČ"
                variant="outlined"
                fullWidth
                margin="normal"
                name="psc"
                value={formValues.psc}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 6, // Maximum character limit
                }}
              />

              <TextField
                sx={textStyle}
                id="phone"
                label="Telefón"
                placeholder="Telefón"
                variant="outlined"
                fullWidth
                margin="normal"
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 15, // Maximum character limit
                }}
              />
            </Box>

            <Box sx={{ display: { md: "flex", xs: "block" }, gap: "10px" }}>
              <TextField
                sx={textStyle}
                id="rodne-cislo"
                label="Rodné číslo"
                placeholder="Rodné číslo"
                variant="outlined"
                fullWidth
                margin="normal"
                name="rc"
                value={formValues.rc}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 11,
                }}
              />

              <TextField
                sx={textStyle}
                type="date"
                id="datum-narodenia"
                // label="Dátum narodenia"
                placeholder="Dátum narodenia"
                variant="outlined"
                fullWidth
                margin="normal"
                name="birth_date"
                value={formValues.birth_date}
                onChange={handleInputChange}
              />
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  checked={zaujemOCertifikaciu}
                  onChange={handleZaujemOCertifikaciuChange}
                  name="zaujemOCertifikaciu"
                />
              }
              label="Záujem o certifikáciu"
              sx={checkBox}
            />

            <Box
              sx={{
                height: "auto",
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={SITE_KEY}
                onChange={handleExpiredToken}
              />
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={!SubmitDisabled ? false : true}
                sx={{ fontFamily: "VisbyCF-Medium", fontSize: 16 }}
              >
                Objednať
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {submitted && (
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            onClick={() => window.location.reload()}
            style={{
              cursor: "pointer",
              rotate: "45deg",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            src={require("../../assets/red_plus.png")}
            alt="arrow_close"
          />
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            GRAATULUJEME!
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Účastník kurzu bol pridaný!
          </Typography>
        </Box>
      )}
      {errorModal && (
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            onClick={() => setErrorModal(false)}
            style={{
              cursor: "pointer",
              rotate: "45deg",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            src={require("../../assets/red_plus.png")}
            alt="arrow_close"
          />
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Nastala chyba
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormUcastnik;
