import { Box, Button, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import checkmark from "../../assets/checkmark.svg";

const Accredited = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#7b7cd5",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        paddingTop: "85px",
      }}
    >
      <img
        className={styles.element}
        style={{
          position: "absolute",
          zIndex: 2,
          bottom: 110,
        }}
        src={require("../../assets/elements_c.png")}
        alt="intro_floating_icons"
      />
      <Box
        id="online-skolenie"
        sx={{
          maxWidth: "1440px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <Box sx={{ position: "absolute", bottom: -5 }}>
          <img
            src={require("../../assets/man_thinking.png")}
            alt="thinking_man"
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 3,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              marginBottom: "19px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.7 }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-ExtraBold",
                    maxWidth: { sm: "100%", xs: "350px" },
                    fontSize: 50,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  accredited <span style={{ color: "#3fcaea" }}>and </span>
                  <span style={{ color: "#f6961d" }}>skilled</span>
                </Typography>
              </Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 38,
                  color: "#1d1a56",
                  marginTop: "20px",
                  marginBottom: "20px",
                  lineHeight: "44px",
                  maxWidth: { sm: "100%", xs: "400px" },
                }}
              >
                Čo ti nesmie chýbať
                <br /> ak plánuješ absolvovať
                <br /> online školenie
              </Typography>
            </motion.div>
          </Box>
          <motion.div
            ref={products}
            initial={{ opacity: 0, y: 50 }}
            animate={
              inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
            }
            transition={{ duration: 0.7 }}
          >
            <Box
              sx={{
                display: "flex",
                width: "1125px",
                justifyContent: { lg: "space-between", xs: "center" },
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "183px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={checkmark}
                    style={{ width: "61px" }}
                    alt="checkmark"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 22,
                      color: "white",
                      marginTop: "33px",
                      lineHeight: "28px",
                    }}
                  >
                    Počítač/laptop, monitor
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "262px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={checkmark}
                    style={{ width: "61px" }}
                    alt="checkmark"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 22,
                      color: "white",
                      marginTop: "33px",
                      lineHeight: "28px",
                    }}
                  >
                    Spoľahlivé pripojenie na internet
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "183px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={checkmark}
                    style={{ width: "61px" }}
                    alt="checkmark"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 22,
                      color: "white",
                      marginTop: "33px",
                      lineHeight: "28px",
                    }}
                  >
                    Webkamera
                    <br />
                    <br />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "249px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={checkmark}
                    style={{ width: "61px" }}
                    alt="checkmark"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 22,
                      color: "white",
                      marginTop: "33px",
                      lineHeight: "28px",
                    }}
                  >
                    Headset so vstavaným mikrofónom
                  </Typography>
                </Box>
              </Box>
            </Box>
          </motion.div>
          <Box
            sx={{
              width: "680px",
              display: "flex",
              justifyContent: { lg: "space-between", xs: "center" },
              marginBottom: { lg: "130px", xs: "380px" },
              marginTop: "20px",
              gap: { sm: "12px", xs: "5px" },
            }}
          >
            <Button
              component="a"
              href="/skolenia/istqb/1#online"
              target="_blank"
              sx={{
                borderRadius: "28px",
                border: "2px solid",
                borderColor: "#1b144a",
                fontFamily: "VisbyCF-Medium",
                fontSize: 16,
                cursor: "pointer",
                color: "#1b144a",
                height: "44px",
                width: { sm: "190px", xs: "180px" },
                textTransform: "initial",

                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Viac o online školení
            </Button>
            <Button
              onClick={() => scrollToSection("header")}
              sx={{
                borderRadius: "28px",
                backgroundColor: "#f6961d",
                fontFamily: "VisbyCF-Bold",
                fontSize: 16,
                cursor: "pointer",
                color: "white",
                height: "44px",
                width: { sm: "186px", xs: "180px" },
                textTransform: "initial",
                marginRight: { sm: "70px", xs: "0px" },
                "&:hover": {
                  backgroundColor: "#3fcaea",
                },
              }}
            >
              Vybrať školenie
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Accredited;
