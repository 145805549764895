let coursesSpecialized;

export default coursesSpecialized = [
  {
    id: 6,
    length: "3-dňový kurz",
    tags: [
      "Praktické a interaktívne cvičenia",
      "Praktická skúška",
      "Certifikát",
    ],
    gain: "Po absolvovaní praktického a interaktívneho školenia budeš spoľahlivo ovládať potrebnú terminológiu, výrazy a termíny používané pri testovaní SW, ich vzájomné súvislosti. Taktiež potrebnú teóriu, teoretické vedomosti v oblasti testovania SW ako aj všetko, čo by mal vedieť každý tester - súvisiace procesy, ako vzájomne interagujú a súvisia počas testovania SW, ako správne testovať, akým chybám sa vyvarovať. V neposlednom rade si predstavíme aj testovacie nástroje a na príkladoch získaš praktické skúsenosti.",
    gainMore: "",
    for: "Školenie Junior SW tester je ideálne pre začínajúcich manuálnych aj automatizovaných testerov, ako aj pre záujemcov, ktorí sa ešte len chcú stať testermi. Poskytne tiež mnoho odpovedí všetkým, ktorí sa pripravujú na pracovný pohovor na pozíciu softvérový tester. V neposlednom rade je školenie určené pre ľudí, ktorí si chcú rozšíriť obzory, testerskú terminológiu a pochopiť základné princípy práce testerov, ich zmýšľanie a postupy, napríklad pre programátorov, analytikov, databázistov, či projektových manažérov.",
    lectors: [5],
    nameShort: "Junior SW tester",
    name: "Junior SW tester 3-dňový kurz",
    description:
      "Ideálne pre začínajúcich manuálnych aj automatizovaných testerov a ašpirujúcich testerov.",
    showPrice: true,
    price: "220",
    dates: [
      { start: "2023-07-03", end: "2023-07-05" },
      { start: "2023-08-07", end: "2023-08-09" },
      { start: "2023-09-04", end: "2023-09-06" },
      { start: "2023-10-09", end: "2023-10-11" },
      { start: "2023-11-06", end: "2023-11-08" },
      { start: "2023-12-04", end: "2023-12-06" },
    ],
    process: [
      {
        header: "Teoretická časť",
        headerTrvanie: "Trvanie: 2 dni",
        text: "Obsahom je teória, metodika, typy testov, špecifiká testovania, testovacie scenáre, reportovanie bugov, funkčné manuálne testovanie, nástroje v praxi testera, základné príkazy linuxu, práca s VPN, remote pripojenie na Windows/Linux server, Putty, WinSCP, rady ako úspešne zvládnuť pracovný pohovor.",
      },
      {
        header: "Cvičná časť",
        headerTrvanie: "Trvanie: 1 deň",
        text: "Cvičenia ku školeniu Junior SW tester (manuálne funkčné testovanie v praxi, reportovanie bugov v praxi, práca s bug trackingovým nástrojom JIRA, cvičenie zamerané na SQL SELECT.",
      },
      {
        header: "Praktická časť",
        headerTrvanie: "Trvanie: 3 dni",
        text: "Zameraná na manuálny funkčný test v praxi, reportovanie bugov a písanie testovacích scenárov v JIRE + záverečný online test. Účastník školenia bude mať 3 dni na vypracovanie praktického zadania (vypracovanie praktického zadania prebieha offline). Následne po vyhodnotení praktického zadania bude vyzvaný k vyplneniu záverečného online testu. Výsledok záverečného online testu má iba informatívny charakter a nemá vplyv na vystavenie certifikátu o absolvovaní školenia.",
      },
    ],
    phrase:
      "Absolvovaním školenia získaš všetky potrebné znalosti na praktický výkon profesie junior SW tester.",
    btnTxt: "Chcem sa stať SW testerom",
  },
  {
    id: 8,
    length: "3-dňový kurz",
    tags: [
      "Praktické a interaktívne cvičenia",
      "Praktická skúška",
      "Certifikát",
    ],
    gain: "Školenie je zamerané na <b>dosiahnutie vyššej úrovne testovania</b> a to na <b>technicky orientované testovanie</b> so zameraním na nástroje využívané v praxi <b>senior testera</b> a <b>test automatizáciu</b>. Súčasťou školenia je množstvo praktických cvičení. </br>Predpokladom absolvovania školenia je znalosť základov testovania softvéru. ",
    gainMore: "",
    for: "Školenie Senior SW tester je odborné školenie, určené pre testerov so základnými testerskými znalosťami resp. pre absolventov nášho prvého školenia Junior SW tester. </br>Taktiež je vhodné pre SW testerov so záujmom o technicky orientované testovanie. Bude prínosom pre junior testerov, ktorí majú záujem o zdokonalenie a rozšírenie svojich vedomostí v oblasti SW testovania. Školenie je vhodné aj pre záujemcov, ktorí si chcú rozšíriť obzory v oblasti technicky orientovaného testovania, napríklad pre programátorov, analytikov, databázistov, či projektových manažérov.",
    nameShort: "Senior SW tester",
    lectors: [5],
    name: "Senior SW tester 3-dňový kurz",
    description:
      "Odborné školenie pre testerov so základnými testerskými znalosťami a absolventov Junior SW tester.",
    showPrice: true,
    price: "285",
    dates: [
      { start: "2023-07-10", end: "2023-07-12" },
      { start: "2023-08-14", end: "2023-08-16" },
      { start: "2023-09-11", end: "2023-09-13" },
      { start: "2023-10-16", end: "2023-10-18" },
      { start: "2023-11-13", end: "2023-11-15" },
      { start: "2023-12-11", end: "2023-12-13" },
    ],
    process: [
      {
        header: "Teoretická časť",
        headerTrvanie: "Trvanie: 2 dni",
        text: "Analýza sieťovej komunikácie, analýza objektov, teória v oblasti webových služieb, teória a metodika automatizácie testov, teória a metodika performance testov.",
      },
      {
        header: "Cvičná časť",
        headerTrvanie: "Trvanie: 1 deň",
        text: "Cvičenia k školeniu Senior SW tester (testovanie webových služieb pomocou nástroja SoapUI, servis virtualizácia a mockovanie služieb, automatizované testovanie webových stránok pomocou Selenium IDE, performance testy pomocou nástroja JMeter).",
      },
      {
        header: "Praktická časť",
        headerTrvanie: "Trvanie: 3 dni",
        text: "Zameraná na automatizáciu testov v Seleniu IDE, performance testy v nástroji JMeter a testovanie webovych služieb pomocou nástroja SoapUI + záverečný online test. Účastník školenia bude mať 3 dni na vypracovanie praktického zadania (vypracovanie praktického zadania prebieha offline). Následne po vyhodnotení praktického zadania bude vyzvaný k vyplneniu záverečného online testu. Výsledok záverečného online testu má iba informatívny charakter a nemá vplyv na vystavenie certifikátu o absolvovaní školenia.",
      },
    ],
    phrase:
      "Absolvovaním školenia získaš všetky potrebné znalosti na praktický výkon profesie senior SW tester.",
    btnTxt: "Chcem sa stať SW testerom",
  },
  {
    id: 9,
    length: "1-dňový kurz",
    tags: [
      "Testovacie nástroje",
      "Analýza targetov objektov",
      "Úvod do automatizácie testov",
      "Inštalácia nástroja Selenium IDE",
      "Úprava, debugovanie Selenium scriptov",
      "Recording scriptov",
      "Vytvoáranie testovacích scenárov",
      "Verifikácie objektov",
      "Príkazy, súšťanie testov",
      "Cross browser testovanie",
    ],
    gain: "Získaš komplexné vedomosti, ako funguje <b>automatizácia testov</b> a ako používať v praxi nástroj Selenium IDE pri testovaní webových stránok. Po absolvovaní školenia budeš vedieť samostatne <b>vytvárať scripty</b> na automatizované testovanie webových stránok, spúšťať a vyhodnocovať automatizované testy.",
    gainMore:
      "V súčasnej dobe narastá potreba opakovaných regresných testov. Efektívnym riešením tejto potreby je automatizácia testov. Školením získaš komplexné vedomosti, ako funguje automatizácia testov a ako používať v praxi nástroj Selenium IDE pri testovaní webových stránok. Súčasťou školenia je získanie teoretických a praktických skúseností pri automatizácii testov webových stránok. Po absolvovaní školenia budeš vedieť samostatne vytvárať scripty na automatizované testovanie webových stránok, spúšťať a vyhodnocovať automatizované testy. </br>Školenie je zamerané na dosiahnutie vyššej úrovne testovania a to na technicky orientované testovanie so zameraním na jeden z najrozšírenejších a najvyhľadávanejších nástrojov - Selenium IDE. Súčasťou školenia je integrácia WebDErivera a vývojového prostredia, vývoj pokročilých testov v nástroji Selenium a množstvo praktických cvičení. Absolvovaním školenia získaš všetky potrebné znalosti na praktický výkon profesie automatizovaný SW tester. Predpokladom absolvovania školenia je znalosť základov testovania softvéru a základné znalosti programovania. </br>Po ukončení školenia získaš certifikát o jeho absolvovaní.",
    for: "Školenie Automatizácia testov a Selenium IDE je odborné školenie <b>určené pre testerov so základnými testerskými skúsenosťami resp. pre absolventov nášho prvého školenia Junior SW tester</b>. Taktiež je vhodné pre SW testerov so záujmom o <b>technicky orientované a automatizované testovanie</b>.",
    forMore:
      "Školenie je rovnako prínosom pre junior testerov, ktorí majú záujem o zdokonalenie a rozšírenie svojich vedomostí v oblasti SW testovania. Je v neposlednom rade vhodné aj pre ľudí, ktorí si chcú rozšíriť obzory v oblasti technicky orientovaného testovania, napríklad pre programátorov, SW inžinierov, programátorov, či vývojárov aplikácií. </br>Školenie nie je vhodné pre absolventov školenia Senior SW tester z dôvodu duplicitného obsahu učiva. </br>Predpokladom je vlastný notebook účastníkov s prehliadačom Firefox.",
    nameShort: "Automatizácia testov a Selenium IDE",
    lectors: [5],
    name: "Automatizácia testov a Selenium IDE 1-dňový kurz",
    description:
      "Školenie pre testerov so základnými skúsenosťami zamerané na vyhľadávaný nástroj Selenium IDE.",
    showPrice: true,
    price: "165",
    dates: [
      { start: "2023-07-06", end: "2023-07-06" },
      { start: "2023-08-10", end: "2023-08-10" },
      { start: "2023-09-07", end: "2023-09-07" },
      { start: "2023-10-12", end: "2023-10-12" },
      { start: "2023-11-09", end: "2023-11-09" },
      { start: "2023-12-07", end: "2023-12-07" },
    ],
    process: [
      {
        header: "Trvanie: 1 deň",
        text: "Na online/onsite školení sa dozvieš základy test automatizácie a ako automatizovať testy webových stránok pomocou nástroja Selenium IDE. Súčasťou školenia sú aj praktické cvičenia zamerané na automatizáciu webových stránok pomocou nástroja Selenium IDE.",
      },
    ],
    phrase:
      "Absolvovaním školenia budeš vedieť samostatne vytvárať scripty na testovanie webstránok, tiež spúšťať a vyhodnocovať automatizované testy.",
    btnTxt: "Chcem ovládať automatizáciu",
  },
  {
    id: 10,
    length: "1-dňový kurz",
    tags: ["Soap UI úvod", "Nastavenie SOAP UI", "Vytvorenie SOAP UI projektu"],
    gain: "Získaš komplexné vedomosti, ako používať open source nástroj <b>Soap UI</b> a ako ho v praxi používať pri testovaní webových služieb. Súčasťou školenia je zároveň získanie praktických skúseností <b>ako virtualizovať webové služby</b> pre potreby mockovania dát na tvojom projekte.",
    gainMore:
      "V súčasnej dobe zložitosť SW systémov narastá, v dôsledku čoho mnoho systémov využíva integrácie založené na webových službách. Školením získaš komplexné vedomosti, ako používať open source nástroj Soap UI a ako ho v praxi používať pri testovaní webových služieb. Súčasťou školenia je zároveň získanie praktických skúseností, ako virtualizovať webové služby pre potreby mockovania dát na tvojom projekte. Po absolvovaní školenia budeš vedieť samostatne vytvárať sofistikované testovacie scenáre na testovanie webových služieb, spúšťať a vyhodnocovať funkčné a load testy webových služieb, a to vrátane service virtualizácie a vytvorenia mock servera. </br>SoapUI je v súčasnosti najpoužívanejší open source nástroj na testovanie SOAP a REST webových služieb. Ponúka veľa možností – testovanie SOA (Architektúra orientovaná na služby). Naučíme ťa využívať jeho možnosti v systémovom integračnom testovaní. Súčasťou školenia sú témy, ako používať SoapUI, webové služby a operácie, čo je REST, Request, Response, Grouping, tvorba requestov a množstvo praktických cvičení. Absolvovaním školenia získaš všetky potrebné znalosti na praktický výkon profesie automatizovaný SW tester. Predpokladom absolvovania školenia je znalosť základov testovania softvéru a základné znalosti programovania. </br>Po ukončení školenia získaš certifikát o jeho absolvovaní.",
    for: "Ide o odborné školenie <b>určené pre testerov s testerskými skúsenosťami, resp. pre absolventov nášho prvého školenia Junior SW tester</b>. Taktiež je vhodné pre SW testerov so záujmom o <b>technicky orientované a automatizované testovanie</b>.",
    forMore:
      "Školenie bude tiež prínosom pre junior testerov, ktorí majú záujem o zdokonalenie a rozšírenie svojich vedomostí v oblasti SW testovania. V neposlednom rade je vhodné aj pre ľudí, ktorí si chcú rozšíriť obzory v oblasti technicky orientovaného testovania, napríklad pre programátorov, SW inžinierov, testerom automatizérom, test manažérom, či vývojárov aplikácií. </br>Školenie nie je vhodné pre absolventov školenia Senior SW tester z dôvodu duplicitného obsahu učiva.",
    nameShort: "Automatizácia testov a SoapUI free – SOAP project",
    lectors: [5],
    name: "Automatizácia testov a SoapUI free – SOAP project 1-dňový kurz",
    description:
      "Zamerané na najpoužívanejší nástroj na testovanie SOAP a REST webových služieb.",
    showPrice: true,
    price: "129",
    dates: [
      { start: "2023-07-13", end: "2023-07-13" },
      { start: "2023-08-17", end: "2023-08-17" },
      { start: "2023-09-14", end: "2023-09-14" },
      { start: "2023-10-30", end: "2023-10-30" },
      { start: "2023-11-16", end: "2023-11-16" },
      { start: "2023-12-14", end: "2023-12-14" },
    ],
    process: [
      {
        header: "Trvanie: 1 deň",
        text: "Na online/onsite školení sa dozvieš, čo sú to webové služby, ako fungujú a ako ich otestovať pomocou open source verzie nástroja Soap UI. Súčasťou školenia sú aj praktické cvičenia zamerané na testovanie a mockovanie webových a REST služieb (funkčné testy a load testy) pomocou nástroja Soap UI. ",
      },
    ],
    phrase:
      "Absolvovaním školenia získaš vedomosti ako používať open source nástroj Soap UI a ako ho v praxi používať pri testovaní webových služieb.",
    btnTxt: "Chcem ovládať automatizáciu",
  },
  {
    id: 11,
    length: "2-dňový kurz",
    tags: [
      "Ako sa vytvára dátovy súbor",
      "Data management",
      "Tvorba testu",
      "Inštalácia nástroja, príprava nástroja na aktívne používanie",
      "Oboznámenie sa so zoznamom dostupných pluginov a možnostiach ich použitia",
      "Vytvorenie proof of concept testovania vybranej stránky/aplikácie",
      "Master/Slave konfigurácia Jmetra",
      "Data management ( deštruktívne/nedeštruktívne data )",
      "Spracovanie výsledkov performance testu v JMetri a ich prezentácia",
      "Ako komunikovať medzi dvoma nezávislými vláknami",
      "Ako implementovať logiku do Jmeter scriptu",
      "Asynchrónne volania",
      "Error handling - správa chybových hlásení",
    ],
    gain: "Dozvieš sa, ktoré pluginy sú užitočné pre využitie najmä v praxi <b>performance testera</b>. Nástroj ponúka veľa možností práce s ním. Pre skúsenejších performance testerov ponúkame návod, ako si poradiť s <b>komunikáciou medzi dvoma nezávislými vláknami</b>, či <b>asynchrónnymi volaniami v JMetri</b>.",
    gainMore:
      "Školenie je zamerané na oboznámenie sa s možnosťami využívania nástroja JMeter pri performance testovaní. Nástroj má dostupné veľké množstvá pluginov. Po absolvovaní školenia budeš vedieť pripraviť jednoduchý script, vytriediť kontent, vytvoriť dátový súbor. Oboznámiš sa so základmi data managementu. Získaš skúsenosť, ako nastaviť think time, pacing time, run up. Nadobudneš vedomosť o tom, čo je to user action, transakcia. Budeš vedieť nastaviť logovanie, vytvoriť script pre komunikáciu medzi dvoma nezávislými vláknami. Zistíš, ako riešiť a merať asynchrónne volania, správu chybových hlásení na názorných príkladoch. </br>Na školení sa naučíš teóriu performance testov, dizajn performance testov a performance tuning. Zoznámiš sa s komplexnou prácou s nástrojom JMeter na úrovni senior performance testera - monitoring a reporty, monitoring serverov, logovanie, reporty a vyhodnocovanie performance testov. </br>Toto školenie nesmie chýbať v zozname absolvovaných školení všetkým maximálne pokročilým testerom. </br>Predpokladom absolvovania školenia je znalosť základov testovania softvéru a základné znalosti programovania. </br>Po ukončení školenia získaš certifikát o jeho absolvovaní.",
    for: "Školenie je <b>určené pre testerov seniorov</b>. Na školení sa preberá nástroj <b>JMeter</b>, pričom školenie je <b>zamerané na performance testy</b>. Je vhodné predovšetkým pre <b>absolventov školenia Automatizácia testov</b>.",
    forMore:
      "Školenie Performance testy a JMeter je tiež vhodné pre senior SW testerov so záujmom o performance testy a znalosť nástroja JMeter. Zároveň v ňom nájdu množstvo informácií performance testeri, zamestnanci IT prevádzky na účel monitoringu a aktívni používatelia JMetra. Školenie je v neposlednom rade vhodné aj pre záujemcov, ktorí si chcú rozšíriť obzory v oblasti technicky orientovaného testovania, napríklad pre programátorov, SW inžinierov, či vývojárov aplikácií.",
    nameShort: "Performance testy a JMeter",
    lectors: [5],
    name: "Performance testy a JMeter 2-dňový kurz",
    description:
      "Školenie vhodné pre senior testerov. Zamerané na nástroj JMeter a performance testy.",
    showPrice: true,
    price: "349",
    dates: [
      { start: "2023-07-17", end: "2023-07-18" },
      { start: "2023-08-21", end: "2023-08-22" },
      { start: "2023-09-18", end: "2023-09-19" },
      { start: "2023-10-13", end: "2023-10-30" },
      { start: "2023-11-20", end: "2023-11-21" },
      { start: "2023-12-18", end: "2023-12-19" },
    ],
    process: [
      {
        header: "Trvanie: 2 dni",
        text: "Naučíš sa základné a hĺbkové možnosti využívania nástroja JMeter pri performance testovaní. Dozvieš sa, ako pripraviť infraštruktúru na vykonávanie performance testov, ako vytvoriť stratégiu testovania, ako vytvoriť performance scripty a ako spustiť a vyhodnotiť performance testy. Súčasťou školenia sú aj praktické cvičenia zamerané na performance testy webovej aplikácie pomocou nástroja JMeter. ",
      },
    ],
    phrase:
      "Absolvovaním školenia získaš všetky potrebné znalosti na praktický výkon profesie performance tester.",
    btnTxt: "Chcem sa stať testerom",
  },
  {
    id: 7,
    length: "1-dňový kurz",
    tags: [
      "Vlastný Oracle server",
      "Základný syntax",
      "SQL teória",
      "Ako písať selecty",
      "Logické, textové a matematické operátory",
      "Aliasy",
      "Zoradenie pomocou Order By",
      "Spojenie dvoch tabuliek",
      "Joiny",
      "Vnorený select",
      "Praktické cvičenia",
    ],
    gain: "Na tomto školení sa naučíš <b>pracovať s databázami</b>. Osvojíš si použitie základných príkazov používaných pre vyhľadávanie v databáze. Budeš vedieť spojiť vybrané tabuľky. S databázou je možné komunikovať pomocou <b>jazyka SQL</b>. Základy tohto jazyka sa naučíš práve v\n tomto kurze. Hlavnými prínosom školenia je, že si budeš vedieť pripraviť študijnú databázu.",
    gainMore:
      "Školením sa naučíš efektívne pracovať s databázami. V databáze sú uložené údaje a pri správnych dotazoch ich dokážu poskytnúť. Databáza údaje ukladá, dovoľuje pridávať nové, upravovať a mazať existujúce a na základe požiadavky tie správne údaje vyhľadávať. Údaje v databáze môžeme tiež analyzovať, uskutočňovať medzi nimi výpočty a zostavovať štatistiky. S databázou je možné komunikovať pomocou jazyka SQL. A základy tohto jazyka sa naučíš práve na tomto kurze. </br>Hlavnými prínosom školenia je, že si budeš vedieť pripraviť študijnú databázu. Osvojíš si použitie základných príkazov používaných pre vyhľadávanie v databáze. Budeš vedieť spojiť vybrané tabuľky. Po absolvovaní školenia budeš ovládať základy jazyka SQL pre prácu s databázou. </br>Zoznámiš sa so všeobecnými princípmi a terminológiou relačných databáz. Naučíš sa vyberať požadované údaje z tabuliek, analyzovať ich, spájať viaceré zdroje do spoločných výstupov. Od jednoduchých výberových dotazov (SELECTov) na jednej tabuľke sa postupne prechádza až ku komplexnejším dotazom, kde zoskupenie a následná analýza vyžaduje spojiť (JOIN) niekoľko vzájomne súvisiacich tabuliek. Po skončení školenia je účastník schopný samostatne pracovať s databázou cez SQL Developer, prípadne ľubovoľný iný program podporujúci SQL (Structured Query Language) na úrovni výberov. Nakoľko princípy relačných databáz sú univerzálne a jazyk SQL je štandardizovaný, nadobudnuté znalosti zo školenia sú vo veľkej miere uplatniteľné na takmer všetky štandardne používané databázové systémy. </br>Po ukončení školenia získaš certifikát o jeho absolvovaní.",
    for: "Školenie je určené <b>testerom, test analytikom, test managerom</b> a všetkým, ktorí majú záujem spoznať základy komunikácie s databázou prostredníctvom jazyka SQL. Skúsenosti so spúšťaním už nachystaných selectov sú výhodou.",
    nameShort: "SQL základy",
    lectors: [1],
    name: "SQL základy 1-dňový kurz",
    description:
      "Určené pre začiatočníkov, ktorí sa chcú naučiť pracovať s SQL databázami.",
    showPrice: true,
    price: "",
    dates: [
      { start: "2023-07-31", end: "2023-07-31" },
      { start: "2023-08-28", end: "2023-08-28" },
      { start: "2023-09-25", end: "2023-09-25" },
      { start: "2023-10-30", end: "2023-10-30" },
      { start: "2023-11-27", end: "2023-11-27" },
      { start: "2023-12-18", end: "2023-12-18" },
    ],
    process: [
      {
        header: "Trvanie: 1 deň",
        text: "Základy jazyka SQL vďaka ktorému je možné pracovať – komunikovať s databázami. Ukážeme si príklad selectu a naučíš sa samostatne písať základné selecty. Zistíš, ako sa píšu rôzne podmienky pre obmedzenie výberu, ako urobiť výpis krajší a čitateľnejší pre človeka a na záver sa zoznámime s najväčším nepriateľom testera juniora – s JOIN-ami.",
      },
    ],
    phrase:
      "Absolvovaním školenia budeš ovládať základy jazyka SQL pre prácu s databázou.",
    btnTxt: "Chcem sa naučiť základy SQL",
  },
  {
    id: 12,
    length: "2-dňový kurz",
    tags: [
      "Update záznamov",
      "Mazanie záznamov",
      "Vytvorenie Tabuľky - Create Table",
      "Zmazanie Tabuľky – Drop Table",
      "Vkladanie údajov do Tabuľky – Insert Into",
      "Update záznamov",
      "Kľúče a obmedzenie – SQL Constraints",
      "Zmena Tabuľky – Alter Table",
      "Úvod do číselných funkcií",
      "Úvod do textových funkcií",
      "Úvod do dátumových funkcií",
      "Úvod do konverzných funkcií",
      "Úvod do Xml funkcie",
      "Úvod do analytických funkcií",
      "Praktické cvičenia",
    ],
    gain: "Na tomto onsite školení sa naučíš <b>pokročilé operácie</b> s databázou. Zameriame sa na <b>pridávanie tabuliek</b>, ich <b>modifikáciu, vkladanie dát, úpravu dát</b> a mazanie nepotrebných údajov. Ukážeme si, ako by mala vyzerať <b>správne vytvorená databáza</b> a akým chybám sa vyvarovať. Na takýchto dobrých aj zlých dátach spravíme základné analýzy a vyskúšaš si tiež zopár funkcií.",
    gainMore:
      "Vzdelávanie v <b>pokročilých operáciách s databázami</b> začneš najskôr teóriou, ako vytvoriť tabuľku alebo ako ju zmazať. Tiež ich budeš vedieť dodatočne meniť. Ďalej zistíš, ako <b>pridávať</b> a <b>aktualizovať dáta</b> v tabuľke, prípadne ich z nej aj odstrániť. Tiež si povieme niečo o cudzích kľúčoch a indexoch. </br>Na takejto databáze, ktorá nebude úplne dokonalá, si potom ukážeme, ako môže vyzerať výstup, kde nie všetky tabuľky majú všetky dáta a ako také dáta odhaliť pomocou JOIN-ov. </br>V poslednej časti školenia si ukážeme zopár <b>základných funkcií</b>: textové, číselné, analytické atď. Predvedieme si, ako sa takéto funkcie používajú a čo dokážu narobiť s dátami v tabuľkách. Po ukončení školenia získaš certifikát o jeho absolvovaní.",
    for: "Školenie SQL pokročilý je <b>určené pre používateľov začiatočníkov</b>, ktorí sa chcú naučiť pracovať s <b>SQL databázami</b> a majú zvládnuté <b>základy SQL - SELECTy</b>. Na tieto výberové dotazy, preberané v školení SQL základy, nadviažeme <b>DML príkazmi</b> pre úpravu, vkladanie a odstraňovanie záznamov a s tým súvisiacimi <b>transakciami</b>.",
    forMore:
      "Po oboznámení sa so základnými dátovými typmi sa naučíš vytvárať vlastné relačné tabuľky, pohľady a ďalšie užitočné objekty. V závere školenia sa dozvieš, ako prideliť práva na svoje objekty iným používateľom. </br>Po skončení školenia budeš schopný samostatne pracovať s databázou cez SQL Developer, prípadne ľubovoľný iný program podporujúci SQL (Structured Query Language) na úrovni výberov. Nakoľko princípy relačných databáz sú univerzálne a jazyk SQL je štandardizovaný, nadobudnuté znalosti zo školenia sú vo veľkej miere uplatniteľné na takmer všetky štandardne používané databázové systémy. </br>Školenie je určené testerom, test analytikom, test managerom, absolventom školenia SQL základy. Tiež je nutné mať zvládnuté základy práce s SQL databázou.",
    nameShort: "SQL pokročilý",
    lectors: [1],
    name: "SQL pokročilý 2-dňový kurz",
    description:
      "Pre začiatočníkov so záujmom o zdokonalenie práce s SQL databázami a znalosťami základov SQL.",
    showPrice: true,
    price: "",
    dates: [
      { start: "2023-08-01", end: "2023-08-02" },
      { start: "2023-08-29", end: "2023-08-30" },
      { start: "2023-09-26", end: "2023-09-27" },
      { start: "2023-10-31", end: "2023-11-01" },
      { start: "2023-11-28", end: "2023-11-29" },
      { start: "2023-12-19", end: "2023-12-20" },
    ],
    process: [
      {
        header: "Trvanie: 2 dni",
        text: "Nadviažeme na výberové dotazy, preberané v školení SQL základy, príkazmi pre úpravu, vkladaním a odstraňovaním záznamov a s tým súvisiacimi transakciami. Po predstavení základných dátových typov sa naučíš vytvárať vlastné relačné tabuľky, pohľady a ďalšie užitočné objekty. V závere školenia sa dozvieš, ako prideliť práva na svoje objekty iným používateľom.",
      },
    ],
    phrase:
      "Absolvovaním školenia získaš potrebné znalosti o SQL databáze a staneš sa profesionálnym testerom.",
    btnTxt: "Chcem sa stať pro testerom",
  },
  {
    id: 13,
    length: "1-3 mesiace",
    tags: [],
    gain: "Praktická stáž u reálneho IT klienta na reálnych projektoch po dobu 1-3 mesiacov. Podmienkou pre praktickú stáž je absolvovanie aspoň základnej sady kurzov Akadémie AAS (Junior SW tester, Senior SW tester, SQL pokročilý) a jeden z kurzov zameraných na automatizáciu alebo performance testing.",
    gainMore: "",
    for: "",
    nameShort: "Praktická stáž",
    lectors: [],
    name: "Praktická stáž 1-3 mesiace",
    description:
      "Praktická stáž u IT klienta na reálnych projektoch. Dostupné pre absolventov sady kurzov Akadémie AAS.",
    showPrice: false,
    price: "",
    isPractice: true,
    dates: [],
    process: [],
    phrase:
      "Absolvovaním praktickej stáže získaš finálny certifikát o absolvovaní praxe + potvrdenie o absolvovaní praxe + “odporúčací list” a referencie.",
    btnTxt: "Chcem prax zadarmo",
  },
];
