import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Header from "../../components/global/Header";

const Unsubscribe = () => {
  return (
    <Box sx={{ height: "100%" }}>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#d8d8d8",
        }}
      >
        <Typography
          sx={{
            fontFamily: "VisbyCF-Bold",
            fontSize: 32,
            textAlign: "center",
            marginTop: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            lineHeight: "35px",
          }}
        >
          Nastavenie odberu newsletter
        </Typography>
        <Typography
          sx={{
            fontFamily: "VisbyCF-Medium",
            fontSize: 18,
            textAlign: "center",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginTop: "25px",
          }}
        >
          Veľmi nás mrzí, že už nechcete dostávať upozornenia o novinkách na AAS
          Academy.
        </Typography>
        <Button
          sx={{
            fontFamily: "VisbyCF-Medium",
            textDecoration: "underline",
            marginTop: "25px",
          }}
        >
          Zrušiť odber
        </Button>
      </Box>
    </Box>
  );
};

export default Unsubscribe;
