import { Box, Typography, Collapse, Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import WhyIstqb from "../../components/courses/WhyIstqb";
import AdoptSkill from "../../components/academy/AdoptSkill";
import Lectors from "../../components/Lectors";
import TeamBanner from "../../components/global/TeamBanner";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/global/Footer";
import Loopingpromo from "../../components/global/Loopingpromo";
import faq from "../../data/faq";
import styles from "../../pages/academy/academy.module.css";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FormKontaktGlobal from "../../components/global/FormKontaktGlobal";
import FormNewsletter from "../../components/global/FormNewsletter";
import Header from "../../components/global/Header";
import purplePlus from "../../assets/purple_plus.svg";
import orangeMinus from "../../assets/orange_minus.svg";
import ButtonFinancovanie from "../../components/global/ButtonFinancovanie";

const FAQ = () => {
  const location = useLocation();
  const background = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  useEffect(() => {
    // Action to perform when the URL is first accessed
    window.scrollTo(0, 0);

    // Cleanup function (optional)
    return () => {
      // Cleanup actions (if needed)
    };
  }, [location.pathname]);
  const [expandedIndices, setExpandedIndices] = useState([]);

  const handleExpand = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    } else {
      setExpandedIndices([...expandedIndices, index]);
    }
  };

  const [openContactForm, setContactForm] = useState(false);

  const handleClick = (e) => {
    if (e.target.id === "bg") setContactForm(false);
  };

  // newsletter handling
  const backgroundNews = useRef(null);
  const [openNewsForm, setNewsForm] = useState(false);
  const handleClickNews = (e) => {
    if (e.target.id === "bgnews") setNewsForm(false);
  };

  // btn fin
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200; // Adjust this value as needed

      if (scrollPosition > threshold) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // width handling

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      {/* {showContent && windowWidth > 1100 && <ButtonFinancovanie />} */}
      <Header />
      <Loopingpromo />
      <Box sx={{ backgroundColor: "#d8d8d8", overflow: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            className={styles.element}
            style={{
              position: "absolute",
              top: 300,
              zIndex: 2,
            }}
            src={require("../../assets/elements6.png")}
            alt="floating_icons"
          />
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
            style={{ zIndex: 3 }}
          >
            <Box
              sx={{
                marginTop: "70px",
                marginBottom: "60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "20px",
                marginRight: "20px",
                zIndex: 3,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#3fcaea",
                  width: "186px",
                  borderTopLeftRadius: "22px",
                  borderTopRightRadius: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "41px",
                  zIndex: 3,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-ExtraBold",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "white",
                  }}
                >
                  FAQ
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: "615px",
                  width: "100%",
                  backgroundColor: "white",
                  border: "2px solid",
                  borderColor: "#7a7cdc",
                  borderRadius: "20px",
                  paddingTop: "25px",
                  paddingBottom: "50px",
                  position: "relative",
                }}
              >
                <Button
                  component="a"
                  href="/skolenia"
                  sx={{
                    position: "absolute",
                    borderRadius: "28px",
                    backgroundColor: "#f6961d",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "white",
                    height: "44px",
                    width: "186px",
                    bottom: -20,
                    right: 50,
                    textTransform: "initial",
                    "&:hover": {
                      backgroundColor: "#3fcaea",
                    },
                  }}
                >
                  Vybrať školenie
                </Button>
                {faq.map((element, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingRight: "25px",
                          paddingLeft: "25px",
                          marginBottom: "10px",
                          paddingTop: "10px",
                          borderTop: index > 0 ? "2px solid" : "none",
                          borderColor: "#7a7cdc",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              fontFamily: "VisbyCF-Bold",
                              fontSize: { sm: "18px", xs: "16px" },
                              color: "#1b144a",
                              lineHeight: "22px",
                              // maxWidth: "80%",
                              width: "25px",
                              marginRight: "10px",
                            }}
                          >
                            {index + 1 + "."}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "VisbyCF-Bold",
                              fontSize: { sm: "18px", xs: "15px" },
                              color: "#1b144a",
                              lineHeight: "22px",
                              maxWidth: "80%",
                            }}
                          >
                            {element.question}
                          </Typography>
                        </Box>
                        <Box>
                          <img
                            style={{
                              cursor: "pointer",
                              width: "31px",
                              height: "31px",
                            }}
                            src={
                              expandedIndices.includes(index)
                                ? orangeMinus
                                : purplePlus
                            }
                            alt="collapse_faq"
                            onClick={() => handleExpand(index)}
                          />
                        </Box>
                        {/* <Button onClick={() => handleExpand(index)}>
                      Expand{index}
                    </Button> */}
                      </Box>
                      <Collapse
                        in={expandedIndices.includes(index)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-Medium",
                            fontSize: { sm: "18px", xs: "15px" },
                            color: "#1b144a",
                            lineHeight: "22px",
                            marginRight: "10px",
                            maxWidth: "80%",
                            paddingLeft: "60px",
                            marginBottom: "10px",
                          }}
                        >
                          {element.answer}
                        </Typography>
                      </Collapse>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Box>
      <WhyIstqb />
      <AdoptSkill />
      <TeamBanner />
      <Box
        sx={{
          // height: "647px",
          backgroundColor: "#1b144a",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lectors itemsPerPage={1}></Lectors>
      </Box>
      <Newsletter setNewsForm={setNewsForm} />
      {/* form newsletter */}
      {openNewsForm && (
        <Box
          onClick={handleClickNews}
          id="bgnews"
          ref={backgroundNews}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          <FormNewsletter setNewsForm={setNewsForm} />
        </Box>
      )}
      <Footer setContactForm={setContactForm} />
      {openContactForm && (
        <Box
          onClick={handleClick}
          id="bg"
          ref={background}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          > */}
          <FormKontaktGlobal setContactForm={setContactForm} />
          {/* </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default FAQ;
