import { Box, Button, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const WhyIstqb = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "67px",
              lineHeight: "44px",
              zIndex: 2,
              maxWidth: "622px",
              textAlign: "center",
              paddingLeft: { xs: "10px", sm: "0px" },
              paddingRight: { xs: "10px", sm: "0px" },
            }}
          >
            Prečo je správne investovať čas a prostriedky do{" "}
            <span style={{ color: "#3fcaea" }}>akreditovaného ISTQB </span>
            vzdelávania a certifikácie?
          </Typography>
        </motion.div>
        <Box
          sx={{
            marginTop: "57px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: 2,
          }}
        >
          <motion.div
            ref={products}
            initial={{ opacity: 0, y: 50 }}
            animate={
              inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
            }
            transition={{ duration: 0.7 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: { lg: "center", xs: "center" },
                gap: "27px",
                maxWidth: "1300px",
                paddingLeft: { xs: "10px", sm: "0px" },
                paddingRight: { xs: "10px", sm: "0px" },
                marginBottom: { sm: "125px", xs: "70px" },
              }}
            >
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "200px",
                  width: { sm: "403px", xs: "100%" },
                  maxWidth: { sm: "unset", xs: "370px" },
                  borderRadius: "22px",
                  transform: "scale(1)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    // Styles on hover
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Box sx={{ paddingTop: "35px" }}>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#3fcaea",
                      marginLeft: "19px",
                      marginBottom: "21px",
                    }}
                  >
                    Kvalifikovaný
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: { sm: 18, xs: 15 },
                      lineHeight: "22px",
                      color: "white",
                      marginLeft: "19px",
                    }}
                  >
                    Zlepšíš svoju hodnotu na trhu práce a kariérne postavenie
                    medzinárodne uznávaným certifikátom ISTQB. Zvýšiš si
                    kvalifikáciu a zdokonalíš svoje schopnosti.
                  </Typography>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "200px",
                  width: { sm: "403px", xs: "100%" },
                  maxWidth: { sm: "unset", xs: "370px" },
                  borderRadius: "22px",
                  transform: "scale(1)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    // Styles on hover
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Box sx={{ paddingTop: "35px" }}>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#f6961d",
                      marginLeft: "19px",
                      marginBottom: "21px",
                    }}
                  >
                    Certifikovaný líder
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: { sm: 18, xs: 15 },
                      lineHeight: "22px",
                      color: "white",
                      marginLeft: "19px",
                      marginRight: "19px",
                    }}
                  >
                    ISTQB® Certified Tester je aktuálne globálnym lídrom v
                    oblasti certifikácie testovania softvéru.
                  </Typography>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "200px",
                  width: { sm: "403px", xs: "100%" },
                  maxWidth: { sm: "unset", xs: "370px" },
                  borderRadius: "22px",
                  transform: "scale(1)",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    // Styles on hover
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Box sx={{ paddingTop: "35px" }}>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#7b7cd5",
                      marginLeft: "19px",
                      marginBottom: "21px",
                    }}
                  >
                    Konkurencieschopnosť
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: { sm: 18, xs: 15 },
                      lineHeight: "22px",
                      color: "white",
                      marginLeft: "19px",
                    }}
                  >
                    Mnohí klienti preferujú spoluprácu s certifikovanými
                    spoločnosťami a profesionálmi. Pomôžeš zvýšiť
                    konkurencieschopnosť tvojej spoločnosti.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default WhyIstqb;
