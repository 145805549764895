let financing;

export default financing = [
  {
    name: "Finančná flexibilita",
    description:
      "Štúdium na splátky ti umožňuje platiť kurz priebežne v menších mesačných splátkach, čo znižuje finančné zaťaženie v porovnaní s jednorazovou platbou.",
    image: "flexibility.png",
  },
  {
    name: "Vstup do kariéry",
    description:
      "Splátkový systém ti umožňuje získať kvalifikáciu a zlepšiť svoje uplatnenie aj keď si nemôžeš dovoliť zaplatiť školenie v plnej výške vopred.",
    image: "entry.png",
  },
  {
    name: "Osobná disciplína",
    description:
      "Štúdium na splátky si vyžaduje pravidelné mesačné platby, čo ti môže pomôcť zlepšiť tvoju finančnú disciplínu a plánovanie rozpočtu.",
    image: "discipline.png",
  },
];
