import { Box, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import Man from "../../assets/man.png";

const AdoptSkill = () => {
  return (
    <Box
      sx={{
        height: { md: "655px", xs: "500px" },
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#5a5ccc",
        backgroundImage: `url(${Man})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: { md: "unset", xs: "1000px" },
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontFamily: "VisbyCF-Bold",
          fontSize: { md: 65, xs: 45 },
          letterSpacing: 3.9,
          marginTop: "100px",
          marginLeft: { md: "500px", sm: "250px", xs: "150px" },
          lineHeight: 1.2,
          color: "white",
        }}
      >
        A<span style={{ color: "#f6961d" }}>dopt</span>
        <br /> A<span style={{ color: "#3fcaea" }}>nother</span>
        <br /> S<span style={{ color: "#7b7cd5" }}>kill</span>
      </Typography>
      <Box
        sx={{
          display: { md: "unset", xs: "none" },
          position: "absolute",
          top: 100,
          marginLeft: "-600px",
        }}
      >
        <img
          className={styles.element}
          src={require("../../assets/elements3.png")}
          alt="man_floating_icons"
        />
      </Box>
    </Box>
  );
};

export default AdoptSkill;
