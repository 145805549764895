import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Academy from "./pages/academy";
import Skolenia from "./pages/skolenia";
import Skolenie from "./pages/skolenie";
import SkolenieIstqb from "./pages/skolenieIstqb";
import FAQ from "./pages/faq";
import Kontakt from "./pages/kontakt";
import Certifikacia from "./pages/certifikacia";
import Podmienky from "./pages/podmienky";
import Ucastnik from "./pages/ucastnik";
import NotFound from "./pages/notFound";
import Congratulation from "./pages/congratulation";
import Unsubscribe from "./pages/unsubscribe";
import TagManager from "react-gtm-module";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PJKRVBQ", // Replace with your GTM container ID
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Academy />} />
        <Route path="/skolenia" element={<Skolenia />} />
        <Route path="/skolenie/:id" element={<Skolenie />} />
        <Route path="/skolenia/istqb/:id" element={<SkolenieIstqb />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/certifikacia" element={<Certifikacia />} />
        <Route path="/podmienky" element={<Podmienky />} />
        <Route path="/pridat_ucastnika_kurzu" element={<Ucastnik />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/congratulation" element={<Congratulation />} />
      </Routes>
    </div>
  );
}

export default App;
