import React, { useState } from "react";
import { Box, Typography, Collapse, Button, Grid } from "@mui/material";
import MyCalendar from "./ReactCalendar";
import istqbCourses from "../../../data/istqbCourses";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import purplePlus from "../../../assets/purple_plus.svg";
import purpleMinus from "../../../assets/purple_minus.svg";

const Calendar = ({ courseId, handleFormOpen, isSelectedDate, setContactForm, setContactMessage, price, terms }) => {
  const [isOpen, setIsOpen] = useState(true);
  const formatDate = (data) => {
    const date = new Date(data); // The Date object you want to convert
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("sk-SK", options);
    return formattedDate;
  };

  const course = istqbCourses.find((obj) => obj.id == courseId);

  const onButtonClick = (date) => {
    isSelectedDate(date);
    handleFormOpen();
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const openNewWindow = () => {
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open("/certifikacia", "_blank", `width=${width},height=${height},left=${left},top=${top}`);
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [third, inViewThird] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const openForm = () => {
    setContactForm(true);
    setContactMessage(`Potrebujem poradiť ohľadom kurzu ${course.name} v cene ${price ? price + "€" : ""}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <motion.div
        ref={products}
        initial={{ opacity: 0, y: 50 }}
        animate={inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Box
          sx={{
            backgroundColor: "#1b144a",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            borderBottom: "2px solid",
            borderColor: "white",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginLeft: "9vw" }}>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "22px",
                  lineHeight: "26px",
                }}
              >
                Vyber si voľný termín školenia
              </Typography>
            </Box>
            <Box
              sx={{
                marginRight: { md: "9vw", xs: "20px" },
                width: "186px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img src={isOpen ? purpleMinus : purplePlus} alt="calendar_collapse" style={{ cursor: "pointer" }} onClick={toggleCollapse} />
            </Box>
          </Box>
        </Box>
        <Collapse in={isOpen} timeout={300} unmountOnExit>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: { md: "unset", xs: "center" },
              gap: "20px",
              paddingTop: "50px",
              paddingBottom: "50px",
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Grid
              container
              sx={{
                gap: "10px",
                maxWidth: "500px",
                display: "flex",
                justifyContent: { md: "unset", xs: "center" },
              }}
            >
              {terms && terms?.length > 0 ? (
                terms.sort((a, b) => new Date(a.date_from) - new Date(b.date_from)).map((element, index) => {
                  if (new Date(element.date_from) > new Date()) {
                    return (
                      <Grid item key={index}>
                        <Button
                          onClick={() => onButtonClick(element.date_from)}
                          sx={{
                            backgroundColor: "#7a7cdc",
                            border: "2px sold",
                            borderColor: "#7a7cdc",
                            borderRadius: "22px",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                            "&:hover": {
                              backgroundColor: "#70ccee",
                              color: "white",
                            },
                          }}
                        >
                          {element.date_from === element.date_to ? (
                            <Typography
                              sx={{
                                color: "white",
                                fontFamily: "VisbyCF-Bold",
                                fontSize: "16px",
                              }}
                            >{`${formatDate(element.date_from)}`}</Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "white",
                                fontFamily: "VisbyCF-Bold",
                                fontSize: "16px",
                              }}
                            >{`${formatDate(element.date_from)} - ${formatDate(element.date_to)}`}</Typography>
                          )}
                        </Button>
                      </Grid>
                    );
                  }
                })
              ) : (
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: "22px",
                  }}
                >
                  Termín dohodou
                </Typography>
              )}
            </Grid>
            <MyCalendar courseId={courseId} terms={terms} />
          </Box>
        </Collapse>
      </motion.div>

      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#70ccee",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              marginTop: "35px",
              marginBottom: "35px",
            }}
          >
            <Box sx={{ marginLeft: { md: "9vw", xs: "0" } }}>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "22px",
                  lineHeight: "26px",
                  textAlign: { md: "start", xs: "center" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                Je objednanie školenia cez programy Úradu práce alebo úver príliš komplikované?
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "22px",
                  textAlign: { md: "start", xs: "center" },
                  lineHeight: "26px",
                }}
              >
                Nevadí, pomôžeme ti s tým.
              </Typography>
            </Box>

            <Button
              onClick={() => openForm()}
              sx={{
                borderRadius: "28px",
                backgroundColor: "#7a7cdc",
                fontFamily: "VisbyCF-Bold",
                fontSize: 16,
                cursor: "pointer",
                color: "white",
                height: "44px",
                width: "186px",
                textTransform: "initial",
                whiteSpace: "nowrap",
                marginTop: { md: "0px", xs: "25px" },
                marginRight: { md: "9vw", xs: "20px" },
                "&:hover": {
                  backgroundColor: "#3fcaea",
                },
              }}
            >
              Pomôcť vybaviť
            </Button>
          </Box>
        </Box>
      </motion.div>
      <motion.div
        ref={third}
        initial={{ opacity: 0, y: 50 }}
        animate={inViewThird ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Box
          sx={{
            backgroundColor: "#1b144a",
            display: "flex",
            justifyContent: "center",
            borderTop: "2px solid",
            borderColor: "white",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              marginTop: "35px",
              marginBottom: "35px",
            }}
          >
            <Box sx={{ marginLeft: { md: "9vw", xs: "0" } }}>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "22px",
                  lineHeight: "26px",
                  maxWidth: "500px",
                  textAlign: { md: "start", xs: "center" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                Po absolvovaní školenia budeš pripravený na certifikačnú skúšku
                <span style={{ color: "#70ccee" }}> ISTQB {istqbCourses.find((obj) => Number(obj.id) === Number(courseId)).certificate}</span>
              </Typography>
            </Box>
            <Button
              onClick={openNewWindow}
              sx={{
                borderRadius: "28px",
                backgroundColor: "#7a7cdc",
                fontFamily: "VisbyCF-Bold",
                fontSize: 16,
                cursor: "pointer",
                color: "white",
                height: "44px",
                whiteSpace: "nowrap",
                width: "249px",
                textTransform: "initial",
                marginTop: { md: "0px", xs: "25px" },
                marginRight: { md: "9vw", xs: "20px" },
                "&:hover": {
                  backgroundColor: "#3fcaea",
                },
              }}
            >
              Viac o certifikačnej skúške
            </Button>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Calendar;
