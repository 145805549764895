import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "../../components/global/Header";

const Congratulation = () => {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            GRAATULUJEME!
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Si o krok bližšie za lepšou kariérou.
          </Typography>
          <img src={require("../../assets/confetti.png")} alt="confetti" />
        </Box>
      </Box>
    </Box>
  );
};

export default Congratulation;
