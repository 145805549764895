import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import logo from "../../assets/aas_logo_small.svg";

const Vision = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "154px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          marginTop: { sm: "99px", xs: "70px" },
        }}
      >
        <img style={{ width: "162px" }} src={logo} alt="aas_logo_small" />
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "60px",
              zIndex: 2,
            }}
          >
            NAŠE CIELE A VÍZIA
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              width: "100%",
              marginTop: "4px",
              zIndex: 2,
            }}
          >
            Ak sa budeme pozerať ďalej do budúcnosti, budeme lepšie pripravení
            na riešenie všetkých možných výziev.
          </Typography>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              // maxWidth: "1197px",
              marginTop: "85px",
              gap: "27px",
              paddingLeft: { xs: "10px", sm: "0px" },
              paddingRight: { xs: "10px", sm: "0px" },
            }}
          >
            <Grid
              item
              sx={{
                backgroundColor: "#1b144a",
                height: "175px",
                maxWidth: "382px",
                width: { lg: "382px", xs: "100%" },
                borderRadius: "22px",
                transform: "scale(1)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  // Styles on hover
                  transform: "scale(1.1)",
                },
              }}
            >
              <Box sx={{ paddingTop: "29px", paddingRight: "50px" }}>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 28,
                    lineHeight: "30px",
                    color: "#3fcaea",
                    marginLeft: "26px",
                    marginBottom: { sm: "21px", xs: "10px" },
                  }}
                >
                  Zmysel pre detail
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    lineHeight: "22px",
                    color: "white",
                    marginLeft: "26px",
                  }}
                >
                  Spolupracujeme s profesionálmi, ktorí si všimnú aj tú
                  najlepšie ukrytú chybu.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                backgroundColor: "#1b144a",
                height: "175px",
                maxWidth: "382px",
                width: { lg: "382px", xs: "100%" },
                borderRadius: "22px",
                transform: "scale(1)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  // Styles on hover
                  transform: "scale(1.1)",
                },
              }}
            >
              <Box sx={{ paddingTop: "29px", paddingRight: "20px" }}>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 28,
                    lineHeight: "30px",
                    color: "#f6961d",
                    marginLeft: "26px",
                    marginBottom: { sm: "21px", xs: "10px" },
                  }}
                >
                  Zameraní pre rast
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    lineHeight: "22px",
                    color: "white",
                    marginLeft: "26px",
                  }}
                >
                  Našou víziou do budúcna je zlepšovať sa v každej oblasti a
                  využívať najmodernejšie technológie.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                backgroundColor: "#1b144a",
                height: "175px",
                maxWidth: "382px",
                width: { lg: "382px", xs: "100%" },
                borderRadius: "22px",
                transform: "scale(1)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  // Styles on hover
                  transform: "scale(1.1)",
                },
              }}
            >
              <Box sx={{ paddingTop: "29px", paddingRight: "10px" }}>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 28,
                    lineHeight: "30px",
                    color: "#7b7cd5",
                    marginLeft: "26px",
                    marginBottom: { sm: "21px", xs: "10px" },
                  }}
                >
                  Odborné zázemie
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    lineHeight: "22px",
                    color: "white",
                    marginLeft: "26px",
                  }}
                >
                  Stojíme na pevných pilieroch a využívame starostlivo
                  zdokonaľované know-how nášho tímu už od roku 2013.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </motion.div>
      </Box>
    </Box>
  );
};

export default Vision;
