import { Box, Button, Grid, Link as LinkMui, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutUs = ({ setContactForm }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      id="about_us"
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 170,
            right: 0,
            zIndex: 1,
          }}
          src={require("../../assets/elements4.png")}
          alt="intro_floating_icons"
        />
        <Box
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            zIndex: 2,
            marginTop: { sm: "152px", xs: "70px" },
          }}
        >
          <img
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "470px",
              // maxHeight: "100%",
              zIndex: 2,
            }}
            src={require("../../assets/logo_header.png")}
            alt="academy_logo_about_us"
          />
        </Box>
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
          style={{ zIndex: 2 }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "40px",
              zIndex: 2,
            }}
          >
            O AAS ACADEMY
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              wdith: "100%",
              marginTop: "4px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Vznikli sme, aby sme pomohli firmám vylepšiť svoje IT riešenia a
            poskytli im efektívne a funkčné služby.
          </Typography>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
          style={{ zIndex: 2 }}
        >
          <Box
            sx={{
              marginTop: "78px",
              width: "100%",
              minHeight: "604px",
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              // alignItems: "center",
              zIndex: 2,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: { lg: "space-between", xs: "center" },
                marginLeft: { lg: "120px", xs: "0px" },
                gap: "10px",
                width: { lg: "403px", xs: "100%" },
                paddingLeft: { xs: "10px", sm: "0px" },
                paddingRight: { xs: "10px", sm: "0px" },
              }}
            >
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "185px",
                  maxWidth: "403px",
                  width: "100%",
                  borderRadius: "22px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "35px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#3fcaea",
                      // marginLeft: "39px",
                      marginBottom: "21px",
                    }}
                  >
                    Prečo si vybrať nás
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 18,
                      lineHeight: "22px",
                      color: "white",
                      // marginLeft: "39px",
                    }}
                  >
                    Sme tvojou pravou rukou v oblasti IT a poskytujeme kvalitné
                    služby so špecialistami v testingu.
                  </Typography>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "185px",
                  maxWidth: "403px",
                  width: { lg: "403px", xs: "100%" },
                  borderRadius: "22px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "35px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#f6961d",
                      // marginLeft: "39px",
                      marginBottom: "21px",
                    }}
                  >
                    Naše služby
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 18,
                      lineHeight: "22px",
                      color: "white",
                      // marginLeft: "39px",
                    }}
                  >
                    Šetríme tvoj čas, peniaze a prinášame know-how ako lídri v
                    IT s najmodernejšími technológiami.
                  </Typography>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: "185px",
                  maxWidth: "403px",
                  width: "100%",
                  borderRadius: "22px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "35px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 28,
                      lineHeight: "28px",
                      color: "#7b7cd5",
                      // marginLeft: "39px",
                      marginBottom: "21px",
                    }}
                  >
                    Náš tím
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 18,
                      lineHeight: "22px",
                      color: "white",
                      // marginLeft: "39px",
                    }}
                  >
                    NAAŠE dobré tímové prostredie umožňuje našim odborníkom
                    poskytnúť pre klientov prvotriednu starostlivosť.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  marginRight: { lg: "8.61vw", xs: "0px" },
                  width: "auto",
                  display: { xs: "flex", lg: "block" },
                  justifyContent: "center",
                  alignContent: "center",
                  maxWidth: { xs: "600px", lg: "722px" },
                  marginLeft: "15px",
                  marginRight: { lg: "90px", xs: "15px" },
                  paddingBottom: "10px",
                  paddingTop: { xs: "10px", lg: "0px" },
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  src={require("../../assets/students.png")}
                  alt="students"
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { lg: "end", xs: "center" },
              marginTop: "59px",
              marginBottom: "104px",
            }}
          >
            <Button
              onClick={() => setContactForm(true)}
              // component="a"
              // href="mailto:robert.kotuliak@aas-slovakia.sk"
              sx={{
                borderRadius: "28px",
                border: "2px solid",
                borderColor: "#1b144a",
                fontFamily: "VisbyCF-Medium",
                fontSize: 16,
                cursor: "pointer",
                color: "#1b144a",
                height: "44px",
                width: "142px",
                textTransform: "initial",
                marginRight: "12px",
                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Kontaktovať
            </Button>
            <Button
              component="a"
              href="/skolenia"
              sx={{
                borderRadius: "28px",
                backgroundColor: "#f6961d",
                fontFamily: "VisbyCF-Bold",
                fontSize: 16,
                cursor: "pointer",
                color: "white",
                height: "44px",
                width: "186px",
                textTransform: "initial",
                marginRight: { lg: "90px", xs: "0px" },
                "&:hover": {
                  backgroundColor: "#3fcaea",
                },
              }}
            >
              Vybrať školenie
            </Button>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default AboutUs;
