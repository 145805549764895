import React, { useState, useEffect } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import specializedCourses from "../../data/specializedCourses";
import purplePlus from "../../assets/purple_plus.svg";
import purpleMinus from "../../assets/purple_minus.svg";
import darkMinus from "../../assets/dark_minus.svg";
import arrow from "../../assets/arrow_wide.svg";
import arrowWhite from "../../assets/arrow_white.svg";

const More = ({ itemsPerPage = 1, courseId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const course = specializedCourses.find((obj) => obj.id == courseId);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenOnline, setIsOpenOnline] = useState(false);

  const toggleCollapseOnline = () => {
    setIsOpenOnline(!isOpenOnline);
  };

  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)"; // Customize the shadow value as needed
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  // RENDERING PAGINATION

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(course.process.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = course.process.slice(startIndex, endIndex);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={{
            cursor: "pointer",
            backgroundColor: currentPage === i ? "white" : "#9b9b9b",
            borderRadius: "50%",
            border: currentPage === i ? "4px solid" : "none",
            borderColor: "#7b7cd5",
            width: "8px",
            height: "8px",
            marginTop: "20px",
            marginLeft: currentPage === i ? "14px" : "16px",
            marginRight: currentPage === i ? "14px" : "16px",
          }}
          onClick={() => handlePageClick(i)}
        ></Box>
      );
    }
    return pages;
  };

  // arrows for pagi
  const [timer, setTimer] = useState(null);

  const startInterval = () => {
    const newTimer = setInterval(() => {
      setCurrentPage((prevItem) =>
        prevItem === totalPages ? 1 : prevItem + 1
      );
    }, 3000);
    setTimer(newTimer);
  };

  const stopInterval = () => {
    clearInterval(timer);
    setTimer(null);
  };

  useEffect(() => {
    startInterval();

    return () => {
      if (timer) {
        stopInterval();
      }
    };
  }, [totalPages]);

  const handleMouseEnter = () => {
    if (timer) {
      stopInterval();
    }
  };

  const handleMouseLeave = () => {
    if (!timer) {
      startInterval();
    }
  };

  // width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Box
        onClick={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          backgroundColor: "#d8d8d8",
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            maxWidth: "1440px",
            width: "100%",
          }}
        >
          {totalPages > 1 && (
            <Box sx={{ maxWidth: "100%" }}>
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                  zIndex: 6,
                  // alignItems: "center",
                  marginTop: "250px",
                  marginLeft: { md: "15px", xs: "5px" },
                }}
              >
                <img
                  onClick={() => {
                    setCurrentPage((prevItem) =>
                      prevItem === 1 ? totalPages : prevItem - 1
                    );
                  }}
                  src={arrow}
                  style={{
                    height: windowWidth > 960 ? "65px" : "40px",
                    cursor: "pointer",
                  }}
                  alt="arrow_l"
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "center",
                  marginTop: "250px",
                  zIndex: 6,
                  right: 0,
                  marginRight: { md: "15px", xs: "5px" },
                }}
              >
                <img
                  onClick={() => {
                    setCurrentPage((prevItem) =>
                      prevItem === totalPages ? 1 : prevItem + 1
                    );
                  }}
                  src={arrow}
                  style={{
                    rotate: "180deg",
                    height: windowWidth > 960 ? "65px" : "40px",
                    cursor: "pointer",
                    marginBottom: "10px",
                    right: 0,
                  }}
                  alt="arrow_r"
                />
              </Box>
            </Box>
          )}
          {/* organizacia skolenia */}
          {courseId != 13 && (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.7 }}
            >
              <Box
                sx={{
                  paddingTop: "105px",
                  paddingBottom: "75px",
                  position: "relative",
                }}
              >
                {currentItems.map((c, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: { sm: "10vw", xs: "15vw" },
                        marginRight: { sm: "10vw", xs: "15vw" },
                        zIndex: 5,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#70ccee",
                          width: { sm: "273px", xs: "190px" },
                          height: "42px",
                          borderTopLeftRadius: "22px",
                          borderTopRightRadius: "22px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontFamily: "VisbyCF-Bold",
                            fontSize: "22px",
                            lineHeight: "36.6px",
                          }}
                        >
                          Priebeh školenia
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          maxWidth: "614px",
                          width: "100%",
                          minHeight: "311px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          shadowStyle: shadowStyle,
                          borderRadius: "20px",
                          marginLeft: "5vw",
                          marginRight: "5vw",
                          zIndex: 5,
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#eba62c",
                            fontFamily: "VisbyCF-Bold",
                            fontSize: "22px",
                            lineHeight: "22px",
                            marginBottom: "20px",
                            marginTop: "20px",
                            textAlign: "center",
                          }}
                        >
                          {c.header}
                          <br />
                          {c.headerTrvanie}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1b144a",
                            fontFamily: "VisbyCF-Medium",
                            fontSize: { sm: "18px", xs: "15px" },
                            lineHeight: "22px",
                            maxWidth: "400px",
                            width: "100%",
                            textAlign: "center",
                            marginBottom: "20px",
                          }}
                        >
                          {c.text}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                {totalPages > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "35px",
                      marginBottom: "50px",
                      zIndex: 5,
                    }}
                  >
                    {renderPages()}
                  </Box>
                )}
              </Box>
            </motion.div>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: "#1b144a",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            borderBottom: "2px solid",
            borderColor: "white",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginLeft: "9vw" }}>
              <Typography
                sx={{
                  color: "white",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "22px",
                  lineHeight: "26px",
                }}
              >
                Viac o priebehu{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  onsite školení
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginRight: { md: "9vw", xs: "20px" },
                width: "186px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={isOpen ? purpleMinus : purplePlus}
                alt="calendar_collapse"
                onClick={toggleCollapse}
              />
            </Box>
          </Box>
        </Box>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "70px",
            }}
          >
            <Typography
              sx={{
                color: "#1d1a56",
                fontFamily: "VisbyCF-Medium",
                fontSize: "28px",
                lineHeight: "32px",
                textAlign: "center",
                maxWidth: "950px",
                marginTop: "105px",
                marginBottom: "65px",
              }}
            >
              Onsite školenie = v školiacej miestnosti AAS Slovakia vedené
              školiteľom <br />
              <span style={{ fontFamily: "VisbyCF-Bold" }}>(“na mieste”)</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "50px",
                maxWidth: "1440px",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Typography
                sx={{
                  color: "#1d1a56",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  width: { md: "50%", xs: "auto" },
                  textAlign: { md: "start", xs: "center" },
                  marginLeft: { md: "9vw", xs: "0" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  Onsite školenie ponúka tradičnú metódu vzdelávania v
                  priestoroch AAS Slovakia pod vedením odborného
                  školiteľa/školiteľky. Je vhodné pre všetkých záujemcov, ktorí
                  uprednostňujú pri výučbe osobný kontakt.
                  <br />
                  <br /> AAS - onsite školenie
                </span>{" "}
                je podobne ako naše online školenie vysoko interaktívne a zahŕňa
                praktické cvičenia. Avšak je doplnené o osobný kontakt
                prítomnosťou v školiacej miestnosti spoločnosti AAS Slovakia na
                Kalinčiakovej ulici 27, Bratislava (1. posch.). <br />
                <br />
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  AAS - onsite trieda
                </span>{" "}
                začína vyučovací deň o 9:00 (prosíme, príď o 8:30) a trvá do
                17:00. Nemusíš sa obávať tempa výučby - školenie je sprevádzané
                pravidelnými oddychovými prestávkami niekoľkokrát za deň, zahŕňa
                obednú prestávku v trvaní 1 hod. a tiež občerstvenie ako
                káva/čaj.
              </Typography>
              <Typography
                sx={{
                  color: "#1d1a56",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  width: { md: "50%", xs: "auto" },
                  textAlign: { md: "start", xs: "center" },
                  marginLeft: { md: "9vw", xs: "0" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  Výhody onsite školenia <br />
                  <br />
                  1.
                </span>{" "}
                Osobná prítomnosť na školení môže byť pre niektorých účastníkov
                najefektívnejším, osvedčeným spôsobom vzdelávania.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  2.
                </span>{" "}
                Lokalita priestorov AAS Slovakia je výborne dostupná linkami
                MHD: Sídlime v pešej blízkosti zastávok Zimný štadión, Česká,
                Bajkalská.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  3.
                </span>
                Tvoje školiace materiály môžeš dostať v tlačenej podobe, ak si
                rád píšeš vlastné poznámky perom.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  4.
                </span>{" "}
                V blízkosti AAS Slovakia sa nachádza park JAMA, ideálny na relax
                počas prestávky.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  5.
                </span>{" "}
                Získaš potvrdenie o absolvovaní kurzu, ktoré slúži ako doklad
                pre certifikačnú skúšku.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  <br />
                  Online rezervácia školení
                </span>
                <br /> Jednoducho vyhľadaj školenie ako obvykle, vyber si
                školenie, ktoré je aktuálne dostupné vo forme onsite a objednaj.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#1b144a",

              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              borderBottom: "2px solid",
              borderColor: "white",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontFamily: "VisbyCF-Bold",
                fontSize: "26px",
                lineHeight: "26px",
                maxWidth: "950px",
                textAlign: "center",
                marginTop: "15px",
                marginBottom: "75px",
              }}
            >
              Naše onsite školenie - “na mieste” má pre účastníkov pridanú
              hodnotu v podobe obľúbeného osobného kontaktu počas celého trvania
              kurzu.
            </Typography>
            <Box
              sx={{
                width: "128px",
                height: "128px",
                overflow: "hidden",
                backgroundColor: "#eba62c",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
                position: "absolute",
                bottom: -65,
                cursor: "pointer",
              }}
              onClick={toggleCollapse}
            >
              <img
                style={{ height: "170px", marginTop: "-60px" }}
                src={arrowWhite}
                alt="arrow_up"
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: "#eba62c",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginLeft: "9vw" }}>
              <Typography
                sx={{
                  color: isOpenOnline ? "#1b144a" : "white",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "22px",
                  lineHeight: "26px",
                }}
              >
                Viac o priebehu{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  online školení
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                marginRight: { md: "9vw", xs: "20px" },
                width: "186px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={isOpenOnline ? darkMinus : purplePlus}
                alt="calendar_collapse"
                onClick={toggleCollapseOnline}
              />
            </Box>
          </Box>
        </Box>
        <Collapse in={isOpenOnline} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "70px",
            }}
          >
            <Typography
              sx={{
                color: "#1d1a56",
                fontFamily: "VisbyCF-Medium",
                fontSize: "28px",
                lineHeight: "32px",
                textAlign: "center",
                maxWidth: "950px",
                marginTop: "105px",
                marginBottom: "65px",
              }}
            >
              Online školenie = online trieda vedená školiteľom <br />
              <span style={{ fontFamily: "VisbyCF-Bold" }}>
                (Instructor Led Training)
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "50px",
                maxWidth: "1440px",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Typography
                sx={{
                  color: "#1d1a56",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  width: { md: "50%", xs: "auto" },
                  textAlign: { md: "start", xs: "center" },
                  marginLeft: { md: "9vw", xs: "0" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  Naše on-line školenie - “online trieda” (Instructor Led
                  Training) má rovnakú hodnotu ako klasické face-to-face
                  školenie v školiacej miestnosti. Ako teda prebieha?
                  <br />
                  <br /> AAS – online školenie -
                </span>{" "}
                online trieda ponúka školenia, ktoré sú vysoko interaktívne, s
                množstvom praktických cvičení. Poskytuje každému účastníkovi
                osobnú podporu a osobný kontakt so školiteľom a ostatnými
                účastníkmi. <br />
                <br />
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  AAS – online trieda -
                </span>{" "}
                technicky spája účastníkov vo virtuálnej učebni – školiacej
                miestnosti, kde prechádzajú a sú vedení školením svojím
                školiteľom. Sú vzájomne prepojení prostredníctvom zvuku a videa,
                čo im umožňuje sa vidieť a komunikovať so školiteľom aj so
                spolu-účastníkmi.
              </Typography>
              <Typography
                sx={{
                  color: "#1d1a56",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  width: { md: "50%", xs: "auto" },
                  textAlign: { md: "start", xs: "center" },
                  marginLeft: { md: "9vw", xs: "0" },
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                }}
              >
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  Výhody online školenia <br />
                  <br />
                  1.
                </span>{" "}
                Nulové/žiadne cestovné náklady.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  2.
                </span>{" "}
                Školiace materiály, príručky dostane každý účastník v
                elektronickej podobe.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  3.
                </span>
                Jednoduché pripojenie z kancelárie alebo z domu podľa
                preferencie účastníka.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  4.
                </span>{" "}
                Inštrukcie ohľadom pripojenia k školeniu sú poskytnuté vopred
                každému účastníkovi.{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  5.
                </span>{" "}
                Potvrdenie o absolvovaní kurzu, ktoré slúži ako doklad pre
                certifikačnú skúšku{" "}
                <span style={{ fontFamily: "VisbyCF-Bold" }}>
                  <br />
                  <br />
                  Rezervácia online školení
                </span>
                <br /> Jednoducho vyhľadaj školenie ako obvykle, vyber si
                školenie, ktoré je aktuálne dostupné vo forme online a objednaj.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#1b144a",

              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontFamily: "VisbyCF-Bold",
                fontSize: "26px",
                lineHeight: "26px",
                maxWidth: "950px",
                textAlign: "center",
                marginTop: "15px",
                marginBottom: "75px",
              }}
            >
              Naše on-line školenie - “online trieda” (Instructor Led Training)
              má rovnakú hodnotu ako klasické face-to-face školenie v školiacej
              miestnosti.{" "}
            </Typography>
            <Box
              sx={{
                width: "128px",
                height: "128px",
                overflow: "hidden",
                backgroundColor: "#eba62c",
                display: "flex",
                justifyContent: "center",
                borderRadius: "50%",
                position: "absolute",
                bottom: -65,
                cursor: "pointer",
              }}
              onClick={toggleCollapseOnline}
            >
              <img
                style={{ height: "170px", marginTop: "-60px" }}
                src={arrowWhite}
                alt="arrow_up"
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default More;
