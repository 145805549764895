const faq = [
  {
    id: 1,
    question:
      "Je rozdiel v cene školenia online/on site ? Alebo je cena jednotná?",
    answer: "Cena školenia je rovnaká pre obe formy školenia.",
  },
  {
    id: 2,
    question:
      "Je v cene školenia zarátaná aj certifikačná skúška alebo sa platí zvlášť?",
    answer:
      "Cena certifikačnej skúšky nie je súčasťou ceny školenia (cena CTFL skúšky je 120€ bez DPH).",
  },
  {
    id: 3,
    question:
      "Pre absolvovanie školenia je potrebné mať s testovaním preukázateľnú prax pol roka alebo je to len odporúčané?",
    answer:
      "Predchádzajúca prax v pozícii testera nie je pre väčšinu školení podmienkou, len odporúčaním. ISTQB školenia – Advanced level však predchádzajúcu prax vyžadujú.",
  },
  {
    id: 4,
    question:
      "Študijné materiály CTFL školenia sú v angličtine alebo slovenčine?",
    answer:
      "Študijný materiál pre školenie CTFL máme ako jediní na Slovensku akreditovaný v slovenskom jazyku. Pre lepšie pochopenie učiva a jednoznačnosť sú uvádzané aj anglické ekvivalenty pojmov.",
  },
  {
    id: 5,
    question: "Je možné CTFL kurz preplatiť úradom práce? ",
    answer:
      "Áno, prostredníctvom programov KOMPAS+ alebo Nestrať prácu – vzdelávaj sa.",
  },
  {
    id: 6,
    question: "Je CTFL kurz vhodný pre začiatočníka? ",
    answer:
      "Školenie je všeobecne odporúčané kandidátom, ktorí majú základný obraz o testovaní, resp. aspoň krátku praktickú skúsenosť s IT – v pozícii testera, akceptačného testera, alebo v akejkoľvek pozícii vývoja softvéru. Na druhej strane je CTFL základné školenie, ktoré otvára dvere do sveta testovania. Ak máte v pláne do budúcna sa testovaniu venovať, je určite prínosom. Predchádzajúca prax v pozícii testera nie je podmienkou, len odporúčaním. Školenie je užitočné pre všetkých, čo chcú získať, alebo si upevniť a certifikátom potvrdiť, základné vedomosti o testovaní, resp. sa testovaniu kariérne venovať. Jeden z benefitov je, že po absolvovaní školenia budete pripravení na certifikačnú skúšku CTFL. (Čo ale nie je zárukou úspešnej skúšky!) Mali sme viacerých začiatočníkov, ktorí skúšku po absolvovaní školenia úspešne zvládli a aj našli pracovne zaradenie v IT. ",
  },
  {
    id: 7,
    question: "Máte voľnú kapacitu CTFL kurzu v termíne ...?",
    answer:
      "Kapacitu konkrétneho termínu Vám potvrdíme odpoveďou na prihlásenie, resp. odpoveďou na otázku adresovanú na AAS Academy. Pre väčšiu skupinu (4+) vieme dohodnúť aj individuálny termín školenia.",
  },
  {
    id: 8,
    question: "Je (a kedy) možnosť získať zľavu na CTFL kurz? ",
    answer:
      "Čo sa týka možnosti zľavy, je možné ju poskytnúť pre väčšiu skupinu (4+). Taktiež uverejňujeme na sociálnych sieťach v rámci kampaní špeciálne zľavové promo kódy.",
  },
  {
    id: 9,
    question:
      "Je termín ...  CTFL garantovaný? Bude sa termín CTFL kurzu ... konať?",
    answer:
      "Predpokladom konania termínu je minimálna naplnenosť konkrétneho termínu (4+).  ",
  },
  {
    id: 10,
    question:
      "Bola by možnosť zorganizovať onsite (prezenčné)  ISTQB školenie aj mimo Vašich priestorov?",
    answer:
      "Áno. Cena školenia u zákazníka zohľadňuje počet účastníkov, vzdialenosť lokality, predpokladané náklady na cestovanie, ubytovanie a honorár školiteľa.",
  },
  {
    id: 11,
    question: "Online školenie - Ako hardware môžem použiť Mac?",
    answer:
      "Áno, môžete použiť na školenie počítač Mac. K online školeniu nie je potrebné nič  inštalovať, postačuje prehliadač na ľubovoľnom počítači. Link na školenie dostanete v pozvánke ku školeniu, následne aj linky na skúšobné online testy a feedback formulár.  ",
  },
  {
    id: 12,
    question:
      "Rád by som sa tiež opýtal či slovenský a aj anglický test sú na rovnakej úrovni resp. či je tak isto celosvetovo uznávaný?",
    answer:
      "Áno. Certifikačnú skúšku je možné vykonať v ľubovoľnom jazyku. Na skúšku v inom ako materinskom jazyku sa poskytuje extra čas navyše k štandardnému času konania skúšky. Certifikát je v anglickom jazyku – platný celosvetovo.",
  },
  {
    id: 13,
    question: "Je možnosť úhrady školenia na splátky? ",
    answer:
      "Platné obchodné podmienky ponúkajú aj možnosť úhrady ceny školenia vo viacerých splátkach. Je teda možné zabezpečiť aj úhradu formou splátok.",
  },
];

export default faq;
