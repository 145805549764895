import { Box, Typography, Grid, Button, Link as LinkMui } from "@mui/material";
import { Link } from "react-router-dom";
import dynatrace from "../../assets/dynatrace.svg";
import smartbear from "../../assets/smartbear.svg";
import platinum from "../../assets/platinum_partner.svg";
import scrollers from "../../assets/scrollers.svg";
import logoWhite from "../../assets/logo_AAS_academy_RGB_W.svg";
import facebook from "../../assets/facebook.svg";
import instragram from "../../assets/instragram.svg";
import linkedin from "../../assets/linkedin.svg";
import React, { useState, useEffect } from "react";

const Footer = ({ setContactForm, setContactMessage = "" }) => {
  const handlePhoneClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor element
    const phoneNumber = event.target.getAttribute("href");
    window.location.href = phoneNumber; // Initiates the phone call
  };

  const openForm = () => {
    setContactForm(true);
    if (setContactMessage) setContactMessage("");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // width handling

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#1d1a56",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {windowWidth > 1240 ? (
        <Grid
          container
          sx={{
            maxWidth: "1440px",
            width: "100%",
            display: "flex",
            marginTop: "69px",
            marginBottom: "34px",
            paddingLeft: "60px",
            paddingRight: "60px",
            justifyContent: { lg: "space-between", xs: "center" },
            alignItems: { lg: "unset", xs: "center" },
          }}
        >
          <Grid
            item
            sx={{
              display: { md: "block", xs: "flex" },
              justifyContent: { md: "unset", xs: "center" },
              alignItems: { md: "unset", xs: "center" },
              flexDirection: { md: "unset", xs: "column" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-Medium",
                fontSize: 18,
                color: "#f6961d",
                marginBottom: "20px",
              }}
            >
              Partneri
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "40px",
                marginBottom: "43px",
                flexDirection: { md: "unset", xs: "column" },
              }}
            >
              <Link to="http://www.istqb.org/" target="_blank" rel="noopener noreferrer">
                <img style={{ marginBottom: "20px", width: "188px" }} src={require("../../assets/platinum.png")} alt="platinum_partner_logo" />
              </Link>
              <Link to="http://smartbear.com/" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "166px" }} src={require("../../assets/smartbear.png")} alt="smartbear_logo" />
              </Link>
              {/* <Link
                to="https://www.dynatrace.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ marginTop: "2px", width: "99px" }}
                  src={require("../../assets/dynatrace.png")}
                  alt="dynatrace_logo"
                />
              </Link> */}
            </Box>
            <Box
              sx={{
                marginBottom: "50px",
                gap: { sm: "40px", xs: "20px" },
                display: "flex",
              }}
            >
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Academy
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={"/skolenia"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Školenia
                </Typography>
              </Link>
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/faq"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  FAQ
                </Typography>
              </Link>
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/kontakt"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: "18px",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Kontakt
                </Typography>
              </Link>
            </Box>
            {/* <Box
              sx={{
                display: { sm: "flex", xs: "none" },
                alignItems: "end",
                gap: "14px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 18,
                  color: "white",
                }}
              >
                ©2023. DESIGN CREATED By
              </Typography>
              <Link
                to="https://scrollers.studio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={scrollers}
                  alt="scrollers_logo"
                  style={{ marginBottom: "4px", width: "80px", height: "48px" }}
                />
              </Link>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "stretch",
                gap: "20px",
                marginTop: { sm: "30px", xs: "19px" },
              }}
            >
              <LinkMui
                target="_blank"
                rel="noopener noreferrer"
                href="https://aas-academy.sk/dokumenty/informacie_o_spracovani_osobnych_udajov"
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 16.5,
                  color: "white",
                  lineHeight: "26px",
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#f6961d",
                  },
                }}
              >
                ZÁSADA OCHRANY OSOBNÝCH ÚDAJOV
              </LinkMui>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 16.5,
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                |
              </Typography>
              <LinkMui
                target="_blank"
                rel="noopener noreferrer"
                href="https://aas-academy.sk/dokumenty/obchodne_podmienky"
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 16.5,
                  color: "white",
                  lineHeight: "26px",
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#f6961d",
                  },
                }}
              >
                OBCHODNÉ PODMIENKY
              </LinkMui>
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: { md: "block", xs: "flex" },
              justifyContent: { md: "unset", xs: "center" },
              alignItems: { md: "unset", xs: "center" },
              flexDirection: { md: "unset", xs: "column" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
                alignItems: { md: "unset", xs: "center" },
                flexDirection: { md: "unset", xs: "column" },
                minWidth: "531px",
              }}
            >
              <img style={{ width: "275px" }} src={logoWhite} alt="logo_footer" />
              <Button
                onClick={() => openForm()}
                sx={{
                  borderRadius: "28px",
                  border: "2px solid",
                  borderColor: "#3fcaea",
                  backgroundColor: "transparent",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 16,
                  cursor: "pointer",
                  color: "white",
                  height: "50px",
                  width: "154px",
                  textTransform: "initial",
                  padding: 0,
                  "&:hover": {
                    borderColor: "white",
                  },
                }}
              >
                Kontaktovať
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "55px",
                justifyContent: { md: "unset", xs: "center" },
                alignItems: { md: "unset", xs: "center" },
                flexDirection: { md: "unset", xs: "column" },
                width: { sm: "auto", xs: "350px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 18,
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                AAS Solutions a.s. <br />
                Kalinčiakova 27 <br />
                831 04 Bratislava
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: { sm: "62px", xs: "0px" },
                  justifyContent: { md: "unset", xs: "center" },
                  alignItems: { md: "unset", xs: "center" },
                }}
              >
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    color: "white",
                    lineHeight: "26px",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                  href="tel:+421220620621"
                  onClick={handlePhoneClick}
                >
                  +421 220 620 621
                </LinkMui>
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    color: "white",
                    lineHeight: "26px",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                  href="tel:+421915110948"
                  onClick={handlePhoneClick}
                >
                  +421 915 110 948
                </LinkMui>
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 18,
                    color: "white",
                    lineHeight: "26px",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                  href="mailto:academy@aassolutions.sk"
                >
                  academy@aassolutions.sk
                </LinkMui>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { sm: "end", xs: "center" },
                gap: "10px",
                marginTop: "35px",
              }}
            >
              <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/people/AAS-Academy/100090331511757/">
                <img src={facebook} alt="logo_facebook" style={{ height: "22px", marginTop: "2px" }} />
              </Link>
              <Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/aas_slovakia">
                <img src={instragram} alt="logo_instagram" />
              </Link>
              <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/company/aas-akcademy/">
                <img src={linkedin} alt="logo_linkedin" style={{ height: "24px", width: "24px", marginTop: "1px" }} />
              </Link>
            </Box>

            {/* <Box
              sx={{
                display: { sm: "none", xs: "flex" },
                alignItems: "end",
                gap: "14px",
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 18,
                  color: "white",
                }}
              >
                ©2023. DESIGN CREATED By
              </Typography>
              <Link
                to="https://scrollers.studio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={scrollers}
                  alt="scrollers_small_logo"
                  style={{ marginBottom: "4px", width: "80px", height: "48px" }}
                />
              </Link>
            </Box> */}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            maxWidth: "600px",
            width: "100%",
            display: "flex",
            marginTop: "69px",
            marginBottom: "34px",
            paddingLeft: "40px",
            paddingRight: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            {/* logo */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img style={{ width: "100%", maxWidth: "400px" }} src={logoWhite} alt="logo_footer" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              {/* social media */}
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/people/AAS-Academy/100090331511757/">
                  <img src={facebook} alt="logo_facebook" style={{ height: "22px", marginTop: "2px" }} />
                </Link>
                <Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/aas_slovakia">
                  <img src={instragram} alt="logo_instagram" />
                </Link>
                <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/company/aas-akcademy/">
                  <img src={linkedin} alt="logo_linkedin" style={{ height: "24px", width: "24px", marginTop: "1px" }} />
                </Link>
              </Box>
              {/* kontakt button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "24px",
                  alignItems: { md: "unset", xs: "center" },
                  flexDirection: { md: "unset", xs: "column" },
                }}
              >
                <Button
                  onClick={() => openForm()}
                  sx={{
                    borderRadius: "28px",
                    border: "2px solid",
                    borderColor: "#3fcaea",
                    backgroundColor: "transparent",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "white",
                    height: "50px",
                    width: "154px",
                    textTransform: "initial",
                    padding: 0,
                    "&:hover": {
                      borderColor: "white",
                    },
                  }}
                >
                  Kontaktovať
                </Button>
              </Box>
            </Box>
            {/* navigation */}
            <Box
              sx={{
                // gap: { sm: "40px", xs: "20px" },
                display: "flex",
                justifyContent: "space-between",
                marginTop: "70px",
                marginBottom: "70px",
              }}
            >
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 15 },
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: { sm: 18, xs: 15 },
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Academy
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={"/skolenia"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: { sm: 18, xs: 15 },
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Školenia
                </Typography>
              </Link>
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 15 },
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/faq"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: { sm: 18, xs: 15 },
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  FAQ
                </Typography>
              </Link>
              <Link
                style={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 15 },
                  textDecoration: "none",
                  color: "white",
                }}
                to={"/kontakt"}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: { sm: 18, xs: 15 },
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                >
                  Kontakt
                </Typography>
              </Link>
            </Box>
            {/* address */}
            <Box
              sx={{
                display: "flex",
                marginTop: "55px",
                justifyContent: "space-between",
                // flexDirection: { sm: "unset", xs: "column" },
                // alignItems: { sm: "unset", xs: "center" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                AAS Solutions a.s.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  display: { sm: "unset", xs: "none" },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                |
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                Kalinčiakova 27
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  display: { sm: "unset", xs: "none" },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                |
              </Typography>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                831 04 Bratislava
              </Typography>
            </Box>
            {/* contact */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Bold",
                    fontSize: { sm: 18, xs: 15 },
                    color: "white",
                    lineHeight: "26px",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                  href="tel:+421220620621"
                  onClick={handlePhoneClick}
                >
                  +421 220 620 621
                </LinkMui>
                <LinkMui
                  sx={{
                    fontFamily: "VisbyCF-Bold",
                    fontSize: { sm: 18, xs: 15 },
                    color: "white",
                    lineHeight: "26px",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#f6961d",
                    },
                  }}
                  href="tel:+421915110948"
                  onClick={handlePhoneClick}
                >
                  +421 915 110 948
                </LinkMui>
              </Box>
              <LinkMui
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 15 },
                  color: "white",
                  lineHeight: "26px",
                  textDecoration: "none",
                  marginBottom: "60px",
                  "&:hover": {
                    color: "#f6961d",
                  },
                }}
                href="mailto:academy@aassolutions.sk"
              >
                academy@aassolutions.sk
              </LinkMui>
            </Box>
            {/* separator */}
            <Box
              sx={{
                height: "1px",
                backgroundColor: "white",
                width: "100%",
                marginBottom: "25px",
              }}
            ></Box>
            {/* partners */}
            <Typography
              sx={{
                fontFamily: "VisbyCF-Medium",
                fontSize: 18,
                color: "#f6961d",
                marginBottom: "20px",
              }}
            >
              Partneri
            </Typography>
            {/* separator 2 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "40px",
                marginBottom: "5px",
                flexDirection: { md: "unset", xs: "column" },
                justifyContent: "center",
              }}
            >
              <Link to="http://www.istqb.org/" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "188px" }} src={require("../../assets/platinum.png")} alt="platinum_partner_logo" />
              </Link>
              <Link to="http://smartbear.com/" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "166px" }} src={require("../../assets/smartbear.png")} alt="smartbear_logo" />
              </Link>
              {/* <Link
                to="https://www.dynatrace.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ marginTop: "2px", width: "99px" }}
                  src={require("../../assets/dynatrace.png")}
                  alt="dynatrace_logo"
                />
              </Link> */}
            </Box>
            {/* gdpr */}
            <Box
              sx={{
                height: "1px",
                backgroundColor: "white",
                width: "100%",
                marginBottom: "40px",
                marginTop: "30px",
              }}
            ></Box>
            {/* gdpr2*/}
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "unset", xs: "column" },
                alignItems: { sm: "unset", xs: "center" },
                // flexDirection: { sm: "unset", xs: "column" },
                justifyContent: "space-between",
                gap: "20px",
                marginTop: { sm: "30px", xs: "19px" },
              }}
            >
              <LinkMui
                target="_blank"
                rel="noopener noreferrer"
                href="https://aas-academy.sk/dokumenty/informacie_o_spracovani_osobnych_udajov"
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 14, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#f6961d",
                  },
                }}
              >
                ZÁSADA OCHRANY OSOBNÝCH ÚDAJOV
              </LinkMui>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  display: { sm: "unset", xs: "none" },
                  fontSize: { sm: 14, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                }}
              >
                |
              </Typography>
              <LinkMui
                target="_blank"
                rel="noopener noreferrer"
                href="https://aas-academy.sk/dokumenty/obchodne_podmienky"
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 14, xs: 12 },
                  color: "white",
                  lineHeight: "26px",
                  textDecoration: "none",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#f6961d",
                  },
                }}
              >
                OBCHODNÉ PODMIENKY
              </LinkMui>
            </Box>
            {/* created by */}
            {/* <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "end",
                gap: "14px",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: { sm: 18, xs: 12 },
                  color: "white",
                  marginBottom: { sm: "-4px", xs: "0px" },
                }}
              >
                ©2023. DESIGN CREATED By
              </Typography>
              <Link
                to="https://scrollers.studio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={scrollers}
                  alt="scrollers_small_logo"
                  style={{ width: "80px", height: "48px" }}
                />
              </Link>
            </Box> */}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Footer;
