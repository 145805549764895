import React, { useState } from "react";
import { Box, Button, Grid, Collapse, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import redPlus from "../../assets/red_plus.svg";
import redMinus from "../../assets/red_minus.svg";
import orangePlus from "../../assets/orange_plus.svg";
import orangeMinus from "../../assets/orange_minus.svg";
import bluePlus from "../../assets/blue_plus.svg";
import blueMinus from "../../assets/blue_minus.svg";
import {scrollToSection} from "../../helpers/interactivityHelper";

const Loan = ({ setContactForm, setContactMessage }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const toggleCollapse = () => {
    if (!isOpen) {
      setIsOpenAbout(false);
      setIsOpenNegative(false);
    }
    setIsOpen(!isOpen);
  };
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const toggleCollapseAbout = () => {
    if (!isOpenAbout) {
      setIsOpen(false);
      setIsOpenNegative(false);
    }
    setIsOpenAbout(!isOpenAbout);
  };
  const [isOpenNegative, setIsOpenNegative] = useState(false);

  const toggleCollapseNegative = () => {
    if (!isOpenNegative) {
      setIsOpenAbout(false);
      setIsOpen(false);
        if (isOpenAbout) {
            scrollToSection("na-splatky-about");
        }
    }
    setIsOpenNegative(!isOpenNegative);
  };

  const openForm = () => {
    setContactForm(true);
    setContactMessage("FINANCOVANIE NA SPLÁTKY");
  };

  return (
    <Box
      id="na-splatky"
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginBottom: "104px",
          zIndex: 3,
        }}
      >
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
          style={{ zIndex: 3 }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              wdith: "100%",
              marginTop: "70px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            ROZLOŽ SI NÁKLADY
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "15px",
              zIndex: 2,
            }}
          >
            FINANCOVANIE N<span style={{ color: "#f6961d" }}>AA</span>{" "}
            <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
              <span style={{ color: "#f6961d" }}>S</span>PLÁTKY
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              wdith: "100%",
              marginTop: "4px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Táto možnosť je určená pre každého kto chce investovať do svojho
            vzdelania a kariéry ale jednorázová platba za školenie je priveľká
            záťaž na osobný či rodinný rozpočet.
          </Typography>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={
            inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
          }
          transition={{ duration: 0.7 }}
          style={{ zIndex: 3 }}
        >
          <Box
            sx={{
              marginTop: "78px",
              width: "100%",
              minHeight: "604px",
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              alignItems: "start",
              zIndex: 2,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginLeft: { lg: "120px", xs: "0px" },
                gap: "10px",
                width: { lg: "403px", xs: "100%" },
                paddingLeft: { xs: "10px", md: "0px" },
                paddingRight: { xs: "10px", md: "0px" },
              }}
            >
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpen ? "410px" : "100px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }} id="na-splatky-about">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#3fcaea",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      O možnosti{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        školenie na splátky
                      </span>
                    </Typography>
                    <Link onClick={toggleCollapse}>
                      <img
                        src={isOpen ? blueMinus : bluePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: { sm: "22px" },
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      Možnosť absolvovať školenie na splátky je určená pre
                      všetkých, ktorí chápu aké dôležité je investovať do
                      rozbehnutia, či rastu svojej kariéry v oblasti testovania.
                      Nie každému to však umožňuje aktuálny stav osobných
                      financií. Financovanie kurzu na splátky je preto ideálna
                      možnosť ako rozložiť náklady na dlhšie časové obdobie.
                      Nová pracovná pozícia v oblasti testovania či zlepšenie
                      finančného ohodnotenia po absolvovaní školenia pritom
                      niekoľkonásobne prevýši výšku takejto splátky.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenAbout ? "630px" : "85px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#f6961d",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      Ako získať úver
                    </Typography>
                    <Link onClick={toggleCollapseAbout}>
                      <img
                        src={isOpenAbout ? orangeMinus : orangePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenAbout} timeout={300} unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: "22px",
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      1. Máš záujem o štart či rast kariéry v oblasti
                      testovania? Vyber si vhodný kurz z našej ponuky. <br />
                      <br />
                      2.V prihlasovacom formulári zaškrtni Spôsob platby{" "}
                      <span style={{ fontFamily: "VisbyCF-Bold" }}>
                        „Záujem o financovanie formou pôžičky“{" "}
                      </span>
                      <br />
                      <br />
                      3. Zaškrtni navyše súhlas s odoslaním tvojich kontaktných
                      údajov nášmu finančnému partnerovi s ktorým máme dohodnuté
                      najlepšie podmienky úverov na finančnom trhu <br />
                      <br />
                      4. Náš zazmluvnený poskytovateľ ťa skontaktuje a dohodne s
                      tebou všetky detaily
                      <br />
                      <br />
                      5. Pôžičku je možné získať z pohodlia domova bez nutnosti
                      cestovať či presúvať sa na pobočku, či fyzicky podpisovať
                      tlačivá. <br />
                      <br />
                      6. Pôžičku je možné vybaviť do 48 hodín
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenNegative ? "280px" : "85px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#7b7cd5",
                        marginLeft: "39px",
                      }}
                    >
                      Dôvody neschválenia
                    </Typography>
                    <Link onClick={toggleCollapseNegative}>
                      <img
                        src={isOpenNegative ? redMinus : redPlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenNegative} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: "22px",
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      - Máš trvalé zamestnanie alebo príjem z podnikateľskej
                      činnosti menej ako tri mesiace <br />
                      <br />- Si v exekúcii <br />
                      <br />- Výška tvojich úverov a záväzkov neumožňuje
                      poskytnúť ďalší úver
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                onClick={() => openForm()}
                sx={{
                  backgroundColor: "#f6961d",
                  height: "85px",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Link style={{ textDecoration: "none" }}>
                  <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: "18px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 28,
                          lineHeight: "28px",
                          color: "#1b144a",
                          marginLeft: "29px",
                        }}
                      >
                        Pomôcť vybaviť{" "}
                        <span style={{ marginLeft: "25px" }}>{">>>"}</span>
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Grid>

            <Box
              sx={{
                marginTop: { md: "-75px", xs: "0px" },
                marginLeft: "15px",
                marginRight: { lg: "90px", xs: "15px" },
                paddingBottom: "10px",
                paddingTop: { xs: "10px", lg: "0px" },
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                src={require("../../assets/splatky.png")}
                alt="splatky"
              />
            </Box>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default Loan;
