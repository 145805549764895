import React, { useState } from "react";
import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import icon from "../assets/skolenie_ikona_orangeyellow.svg";

const Pagination = ({
  itemsPerPage,
  items,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={{
            cursor: "pointer",
            backgroundColor: currentPage === i ? "white" : "#9b9b9b",
            borderRadius: "50%",
            border: currentPage === i ? "4px solid" : "none",
            borderColor: "#7b7cd5",
            width: "8px",
            height: "8px",
            marginTop: "52px",
            marginLeft: currentPage === i ? "14px" : "16px",
            marginRight: currentPage === i ? "14px" : "16px",
          }}
          onClick={() => handlePageClick(i)}
        ></Box>
      );
    }
    return pages;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  return (
    <Box>
      <Grid
        container
        sx={{
          gap: "30px",
          justifyContent: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {currentItems.map((item) => (
          <Grid
            item
            sx={{
              // height: { md: "318px", sm: "400px", xs: "510px" },
              height: "auto",
              width: { md: "568px", sm: "420px", xs: "auto" },
              border: "1px solid",
              borderColor: "#7b7cd5",
              borderRadius: "5px",
              backgroundColor: "white",
              paddingBottom: "15px",
            }}
            key={item.id}
          >
            <Box
              sx={{
                display: "flex",
                marginTop: "31px",
                marginLeft: "31px",
              }}
            >
              {/* img */}
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "80px",
                  height: "80px",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    width: "80px",
                    height: "80px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={icon}
                    style={{
                      height: "150px",
                      position: "absolute",
                    }}
                    alt="student_hat"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 22,
                    color: "#7b7cd5",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Box>
            <Paper
              style={{
                maxHeight: { md: "318px", sm: "400px", xs: "500px" },
                overflowY: "auto",
                boxShadow: "none",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Medium",
                  fontSize: 16,
                  color: "#9c9c9c",
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "32px",
                  maxHeight: { sm: "unset", xs: "336px" },
                  // minHeight: { sm: "unset", xs: "170px" },
                }}
              >
                {item.text}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   gap: "16px",
        }}
      >
        {renderPages()}
      </Box>
    </Box>
  );
};

export default Pagination;
