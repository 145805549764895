import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";

const Loopingpromo = () => {
  const btnStyle = {
    fontFamily: "VisbyCF-DemiBold",
    color: "#1d1a56",
    textTransform: "initial",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: 22,
  };

  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true);
    }, 15100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "hidden",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1b144a",
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "55px",
          backgroundColor: "#1b144a",
          width: "1440px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Typography
          className={styles.slide}
          sx={{
            fontFamily: "VisbyCF-Medium",
            fontSize: 22,
            color: "white",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
            Financujte V
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            AAŠ
          </span>
            e školenie cez{" "}
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            nové programy Úradu práce od 8.4.2024
          </span>
          <span style={{ marginRight: "55px" }} />
            Financujte V
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            AAŠ
          </span>
            e školenie cez{" "}
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            nové programy Úradu práce od 8.4.2024
          </span>
        </Typography>
        <Typography
          className={styles.slide2}
          sx={{
            fontFamily: "VisbyCF-Medium",
            fontSize: 22,
            color: "white",
            paddingLeft: 5,
            paddingRight: 5,
            visibility: showElement ? "visible" : "hidden",
          }}
        >
            Financujte V
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            AAŠ
          </span>
            e školenie cez {" "}
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            nové programy Úradu práce od 8.4.2024
          </span>
          <span style={{ marginRight: "55px" }} />
            Financujte V
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            AAŠ
          </span>
            e školenie cez{" "}
          <span
            style={{
              color: "#3fcaea",
              fontFamily: "VisbyCF-ExtraBold",
            }}
          >
            nové programy Úradu práce od 8.4.2024
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Loopingpromo;
