import { Box, Button, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import Her from "../../assets/her.png";
import herSmall from "../../assets/she.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useState, useEffect } from "react";
import icon from "../../assets/skolenie_ikona_blue.svg";
import iconNew from "../../assets/new.png";

const Intro = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)";

  // width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#7b7cd5",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          maxWidth: "1800px",
          width: "100%",
        }}
      >
        <img
          className={styles.element}
          style={{
            marginLeft: "650px",
            position: "absolute",
            top: 70,
            zIndex: 2,
            display: windowWidth > 700 ? "unset" : "none",
          }}
          src={require("../../assets/elements.png")}
          alt="intro_floating_icons"
        />
        <Box
          sx={{
            position: "absolute",
            zIndex: 3,
            right: windowWidth < 1250 && windowWidth > 650 ? 5 : windowWidth < 650 && windowWidth > 550 ? -40 : "unset",
            marginLeft: windowWidth >= 1250 ? "950px" : "unset",
            transform:
              (windowWidth < 1340 && windowWidth > 650) || windowWidth < 550
                ? "scale(0.9)"
                : windowWidth < 650 && windowWidth > 550
                ? "scale(0.7)"
                : "unset",
            bottom: windowWidth < 550 ? 60 : 200,
            // display: windowWidth >= 980 ? "block" : "none",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(15deg)",
                width: "279px",
              }}
              className={styles.shake2}
            >
              {/* <Box
              sx={{
                height: "37px",
                width: "178px",
                backgroundColor: "#3fcaea",
                borderTopLeftRadius: "22px",
                borderTopRightRadius: "22px",
                justifyContent: "center",
                display: "flex",
                alignItems: "end",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-ExtraBold",
                  fontSize: 18,
                  color: "white",
                  marginBottom: "1px",
                }}
              >
                online/onsite
              </Typography>
            </Box> */}
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  boxShadow: shadowStyle,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "77px",
                      height: "77px",
                      marginTop: "10px",
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={iconNew}
                      alt="new"
                      style={{
                        position: "absolute",
                        height: "80px",
                        zIndex: 10,
                        top: -30,
                        right: 90,
                      }}
                    />
                    <img
                      src={icon}
                      alt="student_hat"
                      style={{
                        position: "absolute",
                        height: "130px",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-DemiBold",
                      fontSize: 19,
                      marginTop: "10px",
                      color: "#7b7cd5",
                      textAlign: "center",
                      marginRight: "15px",
                      marginLeft: "15px",
                      lineHeight: "26px",
                    }}
                  >
                    Preplácanie kurzov cez programy ÚP
                  </Typography>
                </Box>
                <Box>
                <Link to="/skolenia#kompas" style={{ textDecoration: "none" }}>
                  <Button
                    component="a"
                    sx={{ 
                      borderRadius: "28px",
                      border: "2px solid",
                      my: "10px",
                      borderColor: "#7b7cd5",
                      fontFamily: "VisbyCF-Medium",
                      fontSize:  16,
                      cursor: "pointer",
                      color: "#1d1a56",
                      height: "44px",
                      width: "186px",
                      textTransform: "initial",
                      "&:hover": {
                        backgroundColor: "#7b7cd5",
                        color: "white",
                      },
                    }}
                  > 
                    Detaily
                  </Button>
                </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {windowWidth > 550 ? (
          <Box
            sx={{
              backgroundImage: `url(${Her})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "630px",
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingTop: "10px",
              position: "relative",
            }}
          >
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.7 }}
            >
              <Box
                sx={{
                  maxWidth: { md: "580px", xs: "355px" },
                  marginLeft: "6.6vw",
                  marginRight: "6.6vw",
                  position: "relative",
                  zIndex: 3,
                }}
              >
                <Box sx={{ marginLeft: "12px", marginBottom: "27px" }}>
                  <Typography
                    sx={{
                      fontSize: { md: 55, xs: 35 },
                      fontFamily: "VisbyCF-Medium",
                      color: "#1b144a",
                      lineHeight: { md: "65px", xs: 1.2 },
                      marginBottom: "21px",
                    }}
                  >
                    <span style={{ color: "white" }}>AA</span>B<span style={{ color: "white" }}>S</span>OLVUJ školenie, <br />s ktorým budeš <br />
                    napredovať
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: 28, xs: 22 },
                      fontFamily: "VisbyCF-DemiBold",
                      color: "#1b144a",
                      lineHeight: { md: "38px", sx: 1.2 },
                    }}
                  >
                    Poskytujeme akreditované ISTQB školenia a odborné špecializované školenia rôznych oblastí testovania.
                  </Typography>
                </Box>
                <Box>
                  <Link to="/skolenia#specializovane" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        borderRadius: "28px",
                        border: "2px solid",
                        borderColor: "#3fcaea",
                        backgroundColor: "transparent",
                        fontFamily: "VisbyCF-Medium",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "346px",
                        marginRight: "14px",
                        textTransform: "initial",
                        padding: 0,
                        "&:hover": {
                          borderColor: "white",
                        },
                      }}
                    >
                      Viac o špecializovaných školeniach
                    </Button>
                  </Link>
                  <Link to="/skolenia#istqb" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        borderRadius: "28px",
                        backgroundColor: "#f6961d",
                        fontFamily: "VisbyCF-Bold",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "186px",
                        marginRight: "14px",
                        marginTop: { xs: "10px", md: "0px" },
                        textTransform: "initial",
                        "&:hover": {
                          backgroundColor: "#3fcaea",
                        },
                      }}
                    >
                      Viac o ISTQB
                    </Button>
                  </Link>
                </Box>
              </Box>
            </motion.div>
          </Box>
        ) : (
          <Box
            sx={{
              // height: "630px",
              maxWidth: "1440px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              paddingTop: "10px",
              position: "relative",
            }}
          >
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 0.7 }}
              style={{ width: "100%", marginTop: "50px" }}
            >
              <Box
                sx={{
                  maxWidth: { md: "580px", xs: "355px" },
                  marginLeft: "6.6vw",
                  marginRight: "6.6vw",
                  position: "relative",
                  zIndex: 3,
                }}
              >
                <Box sx={{ marginLeft: "12px", marginBottom: "27px" }}>
                  <Typography
                    sx={{
                      fontSize: { md: 55, xs: 35 },
                      fontFamily: "VisbyCF-Medium",
                      color: "#1b144a",
                      lineHeight: { md: "65px", xs: 1.2 },
                      marginBottom: "21px",
                    }}
                  >
                    <span style={{ color: "white" }}>AA</span>B<span style={{ color: "white" }}>S</span>OLVUJ školenie, <br />s ktorým budeš <br />
                    napredovať
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: 28, xs: 22 },
                      fontFamily: "VisbyCF-DemiBold",
                      color: "#1b144a",
                      lineHeight: { md: "38px", sx: 1.2 },
                    }}
                  >
                    Poskytujeme akreditované ISTQB školenia a odborné špecializované školenia rôznych oblastí testovania.
                  </Typography>
                </Box>
                <Box>
                  <Link to="/skolenia#specializovane" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        borderRadius: "28px",
                        border: "2px solid",
                        borderColor: "#3fcaea",
                        backgroundColor: "transparent",
                        fontFamily: "VisbyCF-Medium",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "346px",
                        marginRight: "14px",
                        textTransform: "initial",
                        padding: 0,
                        "&:hover": {
                          borderColor: "white",
                        },
                      }}
                    >
                      Viac o špecializovaných školeniach
                    </Button>
                  </Link>
                  <Link to="/skolenia#istqb" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        borderRadius: "28px",
                        backgroundColor: "#f6961d",
                        fontFamily: "VisbyCF-Bold",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "186px",
                        marginRight: "14px",
                        marginTop: { xs: "10px", md: "0px" },
                        textTransform: "initial",
                        "&:hover": {
                          backgroundColor: "#3fcaea",
                        },
                      }}
                    >
                      Viac o ISTQB
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  height: "470px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    width: "550px",
                    // marginLeft: "100px",
                    bottom: 20,
                  }}
                  src={herSmall}
                  alt="her_small"
                />
                <img
                  className={styles.element}
                  style={{
                    marginRight: "-350px",
                    position: "absolute",
                    top: -45,
                    zIndex: 2,
                  }}
                  src={require("../../assets/elements.png")}
                  alt="intro_floating_icons"
                />
              </Box>
            </motion.div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Intro;
