import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Workwithus = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "#1d1a56",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "48px",
        paddingBottom: "61px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1440px",
          width: "100%",
          display: "flex",
          justifyContent: { lg: "space-between", xs: "center" },
          alignItems: { lg: "unset", xs: "center" },
          position: "relative",
          // height: "160px",
          flexDirection: { lg: "row", xs: "column" },
          paddingLeft: { lg: "0px", xs: "10px" },
          paddingRight: { lg: "0px", xs: "10px" },
        }}
      >
        <Box
          sx={{
            maxWidth: "605px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            marginLeft: { lg: "140px", xs: "0px" },
          }}
        >
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-Medium",
                fontSize: 55,
                lineHeight: "55px",
                color: "white",
                textAlign: { lg: "start", xs: "center" },
                marginBottom: "15px",
              }}
            >
              PR<span style={{ color: "#f6961d" }}>AA</span>CUJ{" "}
              <span style={{ color: "#f6961d" }}>S</span> NAMI
            </Typography>
            <Typography
              sx={{
                fontFamily: "VisbyCF-Medium",
                fontSize: 22,
                lineHeight: "28px",
                color: "white",
                textAlign: { lg: "start", xs: "center" },
                marginBottom: "15px",
              }}
            >
              Kariéra v testingu je nielen zaujímavá, ale aj mimoriadne
              rozmanitá. Aktuálne pre teba máme otvorené tieto pozície:
            </Typography>
            <Box
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.aas-slovakia.sk/position.php?id=11"
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-Bold",
                  fontSize: 22,
                  lineHeight: "28px",
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: { lg: "start", xs: "center" },
                }}
              >
                Senior Automatizovaný Tester
              </Typography>
            </Box>
          </motion.div>
        </Box>
        <Box
          sx={{
            // height: "100%",
            display: "flex",
            alignItems: "end",
            marginRight: { lg: "124px", xs: "0px" },
            marginTop: { lg: "0px", xs: "35px" },
          }}
        >
          <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.aas-slovakia.sk/career.php"
            sx={{
              borderRadius: "27px",
              backgroundColor: "#3fcaea",
              fontFamily: "VisbyCF-Bold",
              fontSize: 16,
              cursor: "pointer",
              color: "white",
              height: "55px",
              width: "186px",
              textTransform: "initial",
              "&:hover": {
                backgroundColor: "#3fcaea",
              },
            }}
          >
            Zistiť viac
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Workwithus;
