import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Bubble from "../../assets/bubble.png";
import Pagination from "../Pagination";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import arrow from "../../assets/arrow_wide.svg";

const Testimonials = ({ itemsPerPage = 1 }) => {
  const isSkoleniaPath = window.location.pathname === "/skolenia";

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  // Reviews list
  const reviews = [
    {
      name: "Dominika J.",
      id: "1",
      text: "Na školení sa mi páčila najmä odbornosť školiteľov a prístup ku školeniu, ktoré bolo pohodové a uvoľnené, no zároveň odborné a vzdelávajúce. Jediné “mínus” (ak sa to tak dá nazvať) je pre mňa osobne, ako nováčika, veľa nových informácií v krátkom čase, takže jedine viac času.😄",
    },
    {
      name: "Daniel B.",
      id: "2",
      text: "Podľa mňa školenie prebehlo na profesionálnej úrovni, nemám nič čo by som mohol vytknúť :) Verím že vďaka práve tomuto školeniu sa mi otvoria brány do sveta IT - testingu. Ďakujem za školenie a v prípade (môjho) kariérneho rastu a potreby ďalšieho vzdelávania nebudem váhať nad vyšším stupňom kurzu.",
    },
    {
      name: "B.C.",
      id: "3",
      text: "So školením som bola veľmi spokojná s prístupom oboch školiacich. Veľmi mi vyhovovala online forma akou školenie prebiehalo. Určite taktiež môžem vyzdvihnúť aj malé testy na konci každej kapitoly kde sme si preverili čo sme si zapamätali.",
    },
    {
      name: "Laura J.",
      id: "4",
      text: "Po absolvovaní plateného kurz na Software Testera som mala veľký problém prepojiť si teóriu s praxou, sylaby ISTQB som čítala niekoľkokrát, ale stále mi to nezapadalo do seba. Pravé až toto trojdňové skolenie mi pomohlo pochopiť súvislosti a všetko do seba zapadlo ako puzzle :) Verím, že sa mi podarí skúšku spraviť na prvýkrát a v práci budem ľahšie a sebavedomejšie využívať teoretické poznatky. Vytknúť naozaj nemám čo :)",
    },
    {
      name: "Eva G.",
      id: "5",
      text: "Veľmi mi vyhovovala praktická stránka školenia, veľa prípadov z praxe, nakoľko otázky v teste sú do veľkej miery koncipované s nutnosťou uvažovať v širšom kontexte s ohľadom na praktickú stránku. Nešlo iba o suchopárny výklad teórie. Skolenia s Robom dokonca boli zábavné, čo je veľké plus keďže ide o celkom náročný obsah. Kladne hodnotím aj priebežné testy, ktoré určite pomohli v príprave na tretí deň školenia prevažne pozostávajúci z testov. Samotné testy takisto hodnotím ako super prípravu a ako najväčší bonus je spoločný výklad jednotlivých otázok a príslušných možností. Neviem či toto mi nerozšírilo obzory rovnako ako teoretická stránka výkladu z predošlých dvoch dní. Nesmierne obdivujem naozaj rozsah vedomostí a skúsenosti obidvoch školiteľov bez toho by tieto školenia určite nenadobudli patričný rozmer a hĺbku.",
    },
    {
      name: "Marek D.",
      id: "6",
      text: "Páčila sa mi profesionálna úroveň školenia. Veľmi priateľská atmosféra mi dovolila sa viac uvoľniť a fokusovať sa preberanú tému.",
    },
    {
      name: "Sára V.",
      id: "7",
      text: "Páčilo sa mi striedanie 2 školiteľov, vďaka tomu bolo školenie živšie.",
    },
      {
          name: "Zdenka K.",
          id: "8",
          text: "Veľmi sa mi páčilo vypracovanie praktických príkladov a ich vysvetlenie. Vyhovuje mi spôsob skúšobných testov. Očakávania splnené. Či na sto percent napíšem, keď urobím certifikáciu 😀",
      },
      {
          name: "Zuzana H.",
          id: "9",
          text: "Prístup Roba a Beatky super, teóriu podkladali pracovnými skúsenosťami, bolo to zaujímavé a poučné zároveň, ich nadšenie pre testovanie je nákazlivé a motivujúce. Školenie som si platila sama a neľutujem to. Minulý rok som si dala za cieľ zvládnuť CTFL ISTQB certifikáciu, ale kvôli každodenným starostiam som si ako tak naštudovala sekciu 1 sylabov a hneď aj bola zmenená ich verzia. Keď som videla Robov post na LinkedIn-e, prečo je dobré zúčastniť sa kurzu, hneď som sa prihlásila. Za tri dni som sa naučila viac ako za rok, a o dva týždne som prihlásená na skúšku. Určite aspoň táto základná skúška je podľa mňa užitočná pre každého testera minimálne kvôli pojmom, testovacím technikám a všeobecnej orientácii v oblasti. Kurz je výborné prepracovaný, ja by som nemenila nič, možno keby sa uskutočnil osobne.",
      },
      {
          name: "Ondrej H.",
          id: "10",
          text: "Skvelá interakcia školiteľ - poslucháč. Teoretické časti kapitol doplnené príkladmi z praxe pre ľahšie pochopenie tém. Praktické príklady. Odpovede na otázky prostredníctvom Kahoot. Naučil som sa veľa nových vecí, ktoré budem určite vedieť uplatniť na projekte. Chcel by som pokračovať v oblasti automatizácie testingu, ideálne pre letecký priemysel.",
      },
      {
          name: "Marcel B.",
          id: "11",
          text: "Chcem uplatniť skúsenosti v praxi, verím, že budem zapojený do projektu, kde si ich budem môcť vyskúšať. Chcem sa rozvíjať aj ako automatizovaný tester a isť na Cyber Security.",
      },

  ];

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(reviews.length / itemsPerPage);

  const [timer, setTimer] = useState(null);

  const startInterval = () => {
    const newTimer = setInterval(() => {
      setCurrentPage((prevItem) =>
        prevItem === totalPages ? 1 : prevItem + 1
      );
    }, 3000);
    setTimer(newTimer);
  };

  const stopInterval = () => {
    clearInterval(timer);
    setTimer(null);
  };

  useEffect(() => {
    startInterval();

    return () => {
      if (timer) {
        stopInterval();
      }
    };
  }, [totalPages]);

  const handleMouseEnter = () => {
    if (timer) {
      stopInterval();
    }
  };

  const handleMouseLeave = () => {
    if (!timer) {
      startInterval();
    }
  };

  // width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      onClick={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        // backgroundColor: "white",
        // backgroundImage: `url(${Bubble})`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        height: { md: 1160, sm: 1300, xs: 1200 },
        cursor: "pointer",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ position: "absolute", zIndex: 1, marginTop: "200px" }}>
        <img
          src={Bubble}
          alt="bubble"
          style={{
            width: windowWidth < 600 ? "600px" : "auto",
          }}
        ></img>
      </Box>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
        style={{ zIndex: 10, width: "100%", maxWidth: "800px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-ExtraBold",
              fontSize: 50,
              color: "#7b7cd5",
              textAlign: "center",
              marginBottom: windowWidth > 600 ? "100px" : "80px",
              marginTop: windowWidth > 929 ? "70px" : "25px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            accredited
            {windowWidth < 930 ? <br /> : " "}
            <span style={{ color: "#3fcaea" }}>and </span>
            <span style={{ color: "#f6961d" }}>skilled</span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginBottom: "3px",
            }}
          >
            Testimonials
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              marginBottom: "55px",
            }}
          >
            Prečítaj si, čo hovoria naši študenti <br />o ich skúsenostiach s
            našou akadémiou.
          </Typography>
          <Box sx={{ position: "relative", maxWidth: "1440px", width: "100%" }}>
            <Box sx={{ display: { xs: "none", sm: "block", width: "100%" } }}>
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                  zIndex: 1,
                  alignItems: "center",
                  marginLeft: "15px",
                  height: 350,
                }}
              >
                <img
                  onClick={() => {
                    setCurrentPage((prevItem) =>
                      prevItem === 1 ? totalPages : prevItem - 1
                    );
                  }}
                  src={arrow}
                  style={{
                    // rotate: "180deg",
                    height: windowWidth > 960 ? "65px" : "65px",
                    cursor: "pointer",
                  }}
                  alt="arrow_l"
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  zIndex: 1,
                  right: 0,
                  marginRight: "15px",
                  height: 350,
                }}
              >
                <img
                  onClick={() => {
                    setCurrentPage((prevItem) =>
                      prevItem === totalPages ? 1 : prevItem + 1
                    );
                  }}
                  src={arrow}
                  style={{
                    rotate: "180deg",
                    fill: "#3fcaea",
                    height: windowWidth > 960 ? "65px" : "65px",
                    cursor: "pointer",
                    marginBottom: "10px",
                    right: 0,
                  }}
                  alt="arrow_r"
                />
              </Box>
            </Box>
            <Pagination
              itemsPerPage={1}
              items={reviews}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </Box>
          <Link
            style={{
              textDecoration: "none",
              // marginBottom: windowWidth > 600 ? "200px" : "80px",
              marginTop: "52px",
            }}
            to={"/skolenia"}
          >
            <Button
              onClick={() =>
                isSkoleniaPath ? scrollToSection("header") : null
              }
              sx={{
                borderRadius: "28px",
                border: "2px solid",

                borderColor: "#1b144a",
                fontFamily: "VisbyCF-Medium",
                fontSize: 16,
                cursor: "pointer",
                color: "#1b144a",
                height: "44px",
                width: "186px",
                textTransform: "initial",

                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Vybrať školenie
            </Button>
          </Link>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Testimonials;
