import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const TeamBanner = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "6.6vw",
        paddingRight: "6.6vw",
        paddingTop: "27px",
        paddingBottom: "37px",
      }}
    >
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Typography
          sx={{
            fontFamily: "VisbyCF-Medium",
            fontSize: 38,
            color: "#1b144a",
            textAlign: "center",
          }}
        >
          Náš tím školiteľov
        </Typography>
        <Typography
          sx={{
            fontFamily: "VisbyCF-Medium",
            fontSize: 22,
            lineHeight: "28px",
            color: "#1b144a",
            textAlign: "center",
            maxWidth: "510px",
            marginTop: "2px",
          }}
        >
          Spoznaj našich skúsených školiteľov - odborníkov, ktorí vás
          sprevádzajú školeniami a odovzdávajú svoje know-how.
        </Typography>
      </motion.div>
    </Box>
  );
};

export default TeamBanner;
