import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledToTop = currentScrollPos === 0;

      if (currentScrollPos < lastScrollPos && !isScrolledToTop) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isVisible && (
        <Button
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 20,
            borderRadius: "50%",
            width: "50px",
            height: "52px",
            backgroundColor: "#f6961d",
            "&:hover": {
              backgroundColor: "#3fcaea",
            },
          }}
          onClick={scrollToTop}
          variant="contained"
        >
          <img
            style={{ width: "20px" }}
            src={require("../../assets/fat_arrow.png")}
            alt="wave"
          />
        </Button>
      )}
    </div>
  );
}

export default ScrollToTopButton;
