let lectors;

export default lectors = [
  {
    name: "Robert Kotuliak",
    id: "1",
    role: "Head of Academy & Testing",
    description: `Robo pôsobil ako SW Quality & Test Specialist na IT&Network oddelení telekomunikačnej spoločnosti. Má dlhoročné skúsenosti z pôsobenia v domácich ako aj v zahraničných korporáciách. Pôsobil ako developer, projektový manažér a ostatných viac ako 10 rokov pracuje v oblasti testingu. Vďaka pôsobeniu na “všetkých stranách barikády” sa snaží skĺbiť to najlepšie z testingu s potrebami projektu a možnosťami vývoja.`,
    highlight: "Vzdelávanie s Robom je nielen efektívne, ale aj zábavné!",
  },
  {
    name: "Beáta Maláková",
    id: "2",
    role: "odborná školiteľka",
    description: `Beáta pôsobí v IT viac ako 20 rokov, v bankách, telekomunikačných spoločnostiach, v automotive prostredí, ako aj verejnej sfére. Prešla si rôznymi testerskými pozíciami na IT projektoch v domácom a zahraničnom prostredí, aj v multikultúrnych tímoch. Ostatných 20 rokov zastrešovala testovanie na pozícii test manažéra. O získané vedomosti a skúsenosti sa s Vami prostredníctvom školenia rada podelí.`,
    highlight:
      "Beátin individuálny prístup je podporený veľkou ochotou a úsmevom.",
  },
  {
    name: "Martin Kozlovský",
    id: "3",
    role: "odborný školiteľ",
    description: `Martin Kozlovský, pôsobí v IT viac ako 15 rokov. V testingu prešiel rôznymi pozíciami od manuálneho testera, test manažéra, test analytika, performance testera, manažéra testerského tímu o veľkosti 50 testerov. V agilnom prostredí prešiel projektami z viacerých odvetví, ako medicína, telekomunikácie či bankovníctvo. Má viac ako 10 ročné skúsenosti s agilným svetom, na pozícii, testera, test manažéra, Scrum mastra, aj agilného coacha. Je držiteľom ISTQB – Full Advanced certifikácie.`,
    highlight:
      "Školenie s Martinom ti prinesie podrobné informácie o preberanej látke a množstvo zaujímavých cvičení.",
  },
  {
    name: "Jana Lenoráková",
    id: "4",
    role: "odborná školiteľka",
    description: `Jana pôsobí v IT viac ako 15 rokov. Prešla si rôznymi testerskými pozíciami od testera juniora po test manažéra a tím lídra. Základ jej skúseností pochádza z bankového, poisťovníckeho a telekomunikačného sektora. Jana aktuálne koordinuje akceptačné testovanie test teamov na projektoch poisťovníckej korporácie.`,
    highlight:
      "Výučba s Janou je zrozumiteľná a ústretová k všetkým úrovniam kvalifikácie študentov.",
  },
  {
    name: "Daniel Kráľ",
    id: "5",
    role: "odborný školiteľ",
    description: `Daniel začal s programovaním ešte počas školy. Prax priniesla veľa zaujímavých výziev, a získala si ho automatizácia a performance testy. Počas svojho viac ako 15-ročného pôsobenia v oblasti testovania sa primárne venuje leadovaniu projektov a performance testom. Nájde si však čas aj na prednášanie na FEI. Školenie, kde sa spojí prístup „pohoďáka“, špecialistu a pedagóga bude pre vás príjemným zážitkom a odborným obohatením.`,
    highlight:
      "Učiť sa s Danielom je ľahšie vďaka jeho neformálnemu vystupovaniu a vedomostiam na špičkovej úrovni.",
  },
  {
    name: "Tomáš Pekarovič",
    id: "6",
    role: "odborný školiteľ",
    description: `Tomáš pôsobí v testingu a test automatizácií uz takmer 20 rokov. Na pár rokov si odskočil do developmentu ale vždy sa s radosťou vrátil k testingu. Posledných pár rokov okrem testovania aj vyvíja automatizačné riešenia pre firmy a navrhuje a pripravuje CI/CD pipeline. Zároveň je aj držiteľom viacerých ISTQB certifikátov, niektorí ho dokonca nazývajú ich zberateľom.`,
    highlight:
      "Tomáš je vyhľadávaný školiteľ s množstvom osobných profesionálnych skúseností, ktoré dokáže zrozumiteľným spôsobom odovzdávať ďalej",
  },
];
