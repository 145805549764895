import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import Girl from "../../assets/girl.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon from "../../assets/skolenie_ikona_blue.svg";
import iconNew from "../../assets/new.png";

const IntroCourses = ({ windowWidth }) => {
  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)";
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "#7b7cd5",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        paddingTop: "85px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          maxWidth: "1440px",
          width: "100%",
        }}
      >
        <img
          className={styles.element}
          style={{
            position: "absolute",
            zIndex: 2,
            bottom: 110,
          }}
          src={require("../../assets/elements_c.png")}
          alt="intro_floating_icons"
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            // marginRight: "-800px",
            right: windowWidth <= 720 ? -40 : 30,
            bottom: windowWidth >= 1150 ? 200 : windowWidth <= 720 ? -5 : 50,
            transform: windowWidth <= 720 ? "scale(0.7)" : "unset",
            // display: windowWidth >= 980 ? "block" : "none",
          }}
        >
          {/* <Box
        onClick={() => scrollToSection("kompas")}
        sx={{
          position: "absolute",
          zIndex: 2,
          marginLeft: "600px",
          top: 60,
          display: { xs: "none", md: "block" },
          transform: "scale(1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      > */}
          {/* <Link>
          <img
            className={styles.shake}
            src={require("../../assets/sticker.png")}
            alt="sticker"
          />
        </Link> */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(15deg)",
                width: "279px",
              }}
              className={styles.shake2}
            >
              {/* <Box
              sx={{
                height: "37px",
                width: "178px",
                backgroundColor: "#3fcaea",
                borderTopLeftRadius: "22px",
                borderTopRightRadius: "22px",
                justifyContent: "center",
                display: "flex",
                alignItems: "end",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "VisbyCF-ExtraBold",
                  fontSize: 18,
                  color: "white",
                  marginBottom: "1px",
                }}
              >
                online/onsite
              </Typography>
            </Box> */}
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  boxShadow: shadowStyle,
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "77px",
                      height: "77px",
                      marginTop: "10px",
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={iconNew}
                      alt="new"
                      style={{
                        position: "absolute",
                        height: "80px",
                        zIndex: 10,
                        top: -30,
                        right: 90,
                      }}
                    />
                    <img
                      src={icon}
                      alt="student_hat"
                      style={{
                        position: "absolute",
                        height: "130px",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-DemiBold",
                      fontSize: 19,
                      marginTop: "10px",
                      color: "#7b7cd5",
                      textAlign: "center",
                      marginRight: "15px",
                      marginLeft: "15px",
                      lineHeight: "26px",
                    }}
                  >
                    Kurz CTFL 4.0 - zmeny
                  </Typography>
                </Box>
                <Button
                  component="a"
                  href={`/skolenia/istqb/14`}
                  sx={{
                    borderRadius: "28px",
                    border: "2px solid",
                    my: "10px",
                    borderColor: "#7b7cd5",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "#1d1a56",
                    height: "44px",
                    width: "186px",
                    textTransform: "initial",
                    "&:hover": {
                      backgroundColor: "#7b7cd5",
                      color: "white",
                    },
                  }}
                >
                  Viac o školení
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${Girl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            maxWidth: "1440px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
            paddingBottom: "292px",
          }}
        >
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
          >
            <Box
              sx={{
                position: "relative",
                zIndex: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  marginBottom: "19px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    // fontSize: { md: 55, xs: 35 },
                    fontSize: 55,
                    fontFamily: "VisbyCF-Medium",
                    color: "#1b144a",
                    lineHeight: "65px",
                    // lineHeight: { md: "65px", xs: 1.2 },
                    marginBottom: "14px",
                    maxWidth: "350px",
                    textAlign: "center",
                  }}
                >
                  HĽ<span style={{ color: "white" }}>AA</span>DÁŠ <span style={{ color: "white" }}>S</span>I ŠKOLENIE?
                </Typography>
                <Typography
                  sx={{
                    fontSize: 28,
                    fontFamily: "VisbyCF-Medium",
                    color: "white",
                    lineHeight: "38px",
                  }}
                >
                  Nasmeruj svoju kariéru
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: "365px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => scrollToSection("istqb")}
                  sx={{
                    borderRadius: "28px",
                    border: "2px solid",
                    borderColor: "#3fcaea",
                    backgroundColor: "transparent",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "white",
                    height: "41px",
                    width: "107px",
                    marginRight: "14px",
                    textTransform: "initial",
                    padding: 0,
                    "&:hover": {
                      borderColor: "white",
                    },
                  }}
                >
                  ISTQB
                </Button>
                <Button
                  onClick={() => scrollToSection("specializovane")}
                  sx={{
                    borderRadius: "28px",
                    border: "2px solid",
                    borderColor: "#3fcaea",
                    backgroundColor: "transparent",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "white",
                    height: "41px",
                    width: "165px",
                    textTransform: "initial",
                    "&:hover": {
                      borderColor: "white",
                    },
                  }}
                >
                  Špecializované
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default IntroCourses;
