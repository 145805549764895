import React, { useState } from "react";
import { IconButton, Drawer, Box, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/aas_logo_small.svg";
import { useEffect } from "react";

const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const params = useParams();

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const scrollToSection = (sectionId) => {
    setOpen(false);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 1);
  };

  // width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 900) toggleDrawer(false);
  }, [windowWidth]);

  return (
    <Box>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => toggleDrawer(true)}
      >
        <MenuIcon style={{ color: "#1d1a56" }} />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={() => toggleDrawer(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: { md: "7.78vw", xs: "0px" },
            maxWidth: "469px",
            minWidth: "300px",
            width: { md: "100%" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: { md: "0px", xs: "15px" },
          }}
        >
          <IconButton
            sx={{ position: "absolute", left: 20, marginTop: "20px" }}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(false)}
          >
            <MenuIcon style={{ color: "#1d1a56" }} />
          </IconButton>
          <img
            style={{ width: "100px", marginTop: "10px" }}
            src={logo}
            alt="aas_logo_small"
          />
          <Link
            style={{
              textDecoration: "none",
              width: "100%",
            }}
            to={"/"}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-DemiBold",
                color: "#1d1a56",
                textTransform: "initial",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: { sm: 20, xs: 18 },
                // marginTop: "8px",
                justifyContent: "start",
                paddingLeft: "20px",
                height: "40px",
                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Academy
            </Typography>
          </Link>
          <Link
            style={{
              textDecoration: "none",
              width: "100%",
            }}
            to={"/skolenia"}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-DemiBold",
                textTransform: "initial",
                display: "flex",
                color: "#1d1a56",
                alignItems: "center",
                textDecoration: "none",
                fontSize: { sm: 20, xs: 18 },
                justifyContent: "start",
                paddingLeft: "20px",
                height: "40px",
                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Školenia
            </Typography>
          </Link>
          <Box>
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => {
                  scrollToSection("istqb");
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                  Školenia ISTQB
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#istqb"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  Školenia ISTQB
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("specializovane")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                  Základy testovania
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#specializovane"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  Základy testovania
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("kompas")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                Zručnosti pre trh práce <span style={{ color: "orange", marginLeft:"5px" }}>pre NEZAMESTNANÝCH</span>
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#kompas"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                Zručnosti pre trh práce <span style={{ color: "orange", marginLeft:"5px", fontSize:"12px" }}>pre NEZAMESTNANÝCH</span>
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("nestracaj-pracu")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    paddingRight: "20px",
                    marginTop:"5px",
                    marginBottom:"5px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    whiteSpace: "nowrap",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                Príspevok na vzdelávanie <span style={{ color: "orange", marginLeft:"5px" }}>pre ZAMESTNANÝCH</span>
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#nestracaj-pracu"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    marginTop:"5px",
                    marginBottom:"5px",
                    textTransform: "initial",
                    whiteSpace: "nowrap",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    paddingRight: "20px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                        Príspevok na vzdelávanie <span style={{ color: "orange", marginLeft:"5px", fontSize:"12px" }}>pre ZAMESTNANÝCH</span>
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("financovanie")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                  Financovanie školení
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#financovanie"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  Financovanie školení
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("na-splatky")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                  N<span style={{ color: "#f6961d" }}>AA S</span>PLÁTKY{" "}
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#na-splatky"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  N<span style={{ color: "#f6961d" }}>AA S</span>PLÁTKY{" "}
                </Typography>
              </Link>
            )}
            {isActive("/skolenia") ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  cursor: "pointer",

                  // borderTopLeftRadius: "21px",
                  // borderTopRightRadius: "21px",
                  width: "100%",
                  justifyContent: "start",
                }}
                onClick={() => scrollToSection("online-skolenie")}
              >
                <Typography
                  sx={{
                    paddingLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    width: "100%",
                  }}
                >
                  Online školenie
                </Typography>
              </Box>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "flex",
                }}
                to={"/skolenia#online-skolenie"}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    // borderTopLeftRadius: "21px",
                    // borderTopRightRadius: "21px",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 20, xs: 18 },
                    height: "45px",
                    width: "100%",
                    justifyContent: "start",
                    paddingLeft: "40px",
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  Online školenie
                </Typography>
              </Link>
            )}
          </Box>
          <Link
            style={{
              textDecoration: "none",
              width: "100%",
            }}
            to={"/faq"}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-DemiBold",
                textTransform: "initial",
                color: "#1d1a56",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: { sm: 20, xs: 18 },
                height: "40px",
                justifyContent: "start",
                paddingLeft: "20px",
                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              FAQ
            </Typography>
          </Link>
          <Link
            style={{
              textDecoration: "none",
              width: "100%",
            }}
            to={"/kontakt"}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              sx={{
                fontFamily: "VisbyCF-DemiBold",
                textTransform: "initial",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                fontSize: { sm: 20, xs: 18 },
                color: "#1d1a56",
                // marginTop: "8px",
                height: "40px",
                justifyContent: "start",
                paddingLeft: "20px",
                "&:hover": {
                  backgroundColor: "#1b144a",
                  color: "white",
                },
              }}
            >
              Kontakt
            </Typography>
          </Link>
        </Box>
      </Drawer>
    </Box>
  );
};

export default HamburgerMenu;
