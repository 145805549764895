import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css"; // Import the CSS file
import istqbCourses from "../../../data/istqbCourses";
import specializedCourses from "../../../data/specializedCourses";

const MyCalendar = ({ courseId, isSpecialized = false, terms }) => {
  const highlightedDates = [];
  let course;
  if (!isSpecialized) {
    course = istqbCourses.find((obj) => Number(obj.id) === Number(courseId));
  } else {
    course = specializedCourses.find((obj) => Number(obj.id) === Number(courseId));
  }
  for (let i = 0; i < terms?.length; i++) {
    if (new Date(terms[i].date_from) > new Date()) {
      highlightedDates.push({
        start: new Date(terms[i].date_from),
        end: new Date(terms[i].date_to),
      });
    }
  }

  const getTileClassName = ({ date }) => {
    const isInRange = highlightedDates.some(
      ({ start, end }) =>
        (date >= start && date <= end) ||
        (date.getDate() === start.getDate() && date.getMonth() === start.getMonth() && date.getFullYear() === start.getFullYear()) ||
        (date.getDate() === end.getDate() && date.getMonth() === end.getMonth() && date.getFullYear() === end.getFullYear())
    );
    const isDefaultValue = date.toDateString() === new Date().toDateString();

    if (isDefaultValue) {
      return "default-value";
    }

    if (isInRange && !isDefaultValue) {
      return "highlight";
    }

    return "";
  };

  return (
    <div>
      <Calendar
        tileClassName={getTileClassName}
        defaultValue={new Date()} // Set the default value to today's date
      />
    </div>
  );
};

export default MyCalendar;
