import { Box, Typography, Grid, Button } from "@mui/material";
import styles from "../global/header.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Financing = ({ finances, setContactForm, setContactMessage }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)"; // Customize the shadow value as needed

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const openForm = () => {
    setContactForm(true);
    setContactMessage("Potrebujem poradiť na tému FINANCOVANIE ŠKOLENÍ");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        id="financovanie"
        sx={{
          maxWidth: "1440px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            marginTop: "120px",
            marginBottom: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            position: "relative",
          }}
        >
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 22,
                lineHeight: "28px",
                color: "#1d1a56",
                maxWidth: "480px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              FINANCOVANIE ŠKOLENÍ
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 38,
                color: "#1d1a56",
                marginTop: "20px",
                marginBottom: "20px",
                lineHeight: "44px",
              }}
            >
              S N<span style={{ color: "#f6961d" }}>AA</span>MI{" "}
              <span style={{ color: "#f6961d" }}>S</span>A MÔŽEŠ
              <br /> VZDELÁVAŤ AJ NAA SPLÁTKY.
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 22,
                lineHeight: "28px",
                color: "#1d1a56",
                maxWidth: "480px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              Ak sa ti nehodí školenie zaplatiť v plnej výške, môžeš sa
              rozhodnúť pre možnosť financovania formou úveru na splátky.
            </Typography>
          </motion.div>
        </Box>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={
            inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
          }
          transition={{ duration: 0.7 }}
        >
          <Box>
            <Grid
              container
              sx={{
                paddingLeft: { xs: "9vw", lg: "0px" },
                paddingRight: { xs: "9vw", lg: "0px" },
                position: "relative",
                zIndex: 2,
                display: "flex",
                justifyContent: { lg: "center", xs: "center" },
                alignItems: "center",
                gap: "50px",
                marginBottom: "120px",
              }}
            >
              {finances.map((finance, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    // xs={12}
                    // sm={6}
                    // md={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "23px",
                      width: "283px", // Styles for the element
                      // Set initial scale
                      transform: "scale(1)",
                      transition: "transform 0.3s ease",
                      "&:hover": {
                        // Styles on hover
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "368px",
                          backgroundColor: "white",
                          borderRadius: "20px",
                          boxShadow: shadowStyle,
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          style={{
                            marginTop: "-28px",
                            width: "130px",
                          }}
                          src={require(`../../assets/${finance.image}`)}
                          alt="blue_close"
                        />
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            fontSize: 22,
                            marginTop: "30px",
                            color: "#7b7cd5",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                            lineHeight: "26px",
                          }}
                        >
                          {finance.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-Medium",
                            fontSize: 18,
                            lineHeight: "22px",
                            color: "#1b144a",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                            marginTop: "30px",
                          }}
                        >
                          {finance.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: { lg: "end", xs: "center" },
                  marginTop: "30px",
                  marginBottom: "73px",
                  flexDirection: { sm: "row", xs: "column" },
                  alignItems: { sm: "unset", xs: "center" },
                  gap: { sm: "0px", xs: "20px" },
                  marginRight: { lg: "240px", xs: "0px" },
                  maxWidth: "1440px",
                }}
              >
                <Button
                  onClick={() => scrollToSection("header")}
                  sx={{
                    borderRadius: "28px",
                    border: "2px solid",
                    borderColor: "#1b144a",
                    fontFamily: "VisbyCF-Medium",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "#1b144a",
                    height: "44px",
                    width: "174px",
                    textTransform: "initial",
                    marginRight: { sm: "12px", xs: "0px" },
                    "&:hover": {
                      backgroundColor: "#1b144a",
                      color: "white",
                    },
                  }}
                >
                  Vybrať školenie
                </Button>
                <Button
                  // component="a"
                  // href="mailto:robert.kotuliak@aas-slovakia.sk"
                  onClick={() => openForm()}
                  sx={{
                    borderRadius: "28px",
                    backgroundColor: "#f6961d",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: 16,
                    cursor: "pointer",
                    color: "white",
                    height: "44px",
                    width: "219px",
                    textTransform: "initial",
                    // marginRight: { sm: "70px", xs: "0px" },
                    "&:hover": {
                      backgroundColor: "#3fcaea",
                    },
                  }}
                >
                  Potrebujem poradiť
                </Button>
              </Box>
            </Grid>
          </Box>
        </motion.div>

        <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 100,
            zIndex: 1,
          }}
          src={require("../../assets/elements2.png")}
          alt="academy_logo"
        />
      </Box>
    </Box>
  );
};

export default Financing;
