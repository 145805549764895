import { Box, Typography, Button, Collapse } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import HamburgerMenu from "./Hamburger";
import logo from "../../assets/aas_logo_small.svg";
import bigLogo from "../../assets/logo_header.png";

const Header = () => {
  const [isScrolledBelowThreshold, setIsScrolledBelowThreshold] =
    useState(false);

  const handleScroll = () => {
    const threshold = 54; // Set your desired threshold value

    const isBelowThreshold = window.scrollY > threshold;
    setIsScrolledBelowThreshold(isBelowThreshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const btnStyle = {
    fontFamily: "VisbyCF-DemiBold",
    color: "#1d1a56",
    textTransform: "initial",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: 22,
  };

  return (
    <Box>
      <Box
        id="header"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "29px",
          paddingBottom: "27px",
          backgroundColor: "white",
          zIndex: 50,
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "1440px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              marginLeft: { md: "6.6vw", xs: "0px" },
              marginRight: { md: "20px", xs: "0px" },
              display: { md: "block", xs: "none" },
            }}
          >
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <img
                src={bigLogo}
                style={{ maxWidth: "357px", maxHeight: "66px" }}
                alt="academy_logo"
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              paddingLeft: "9vw",
              paddingRight: "9vw",
              justifyContent: "end",
              // alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ position: "absolute", top: "-10px", left: "20px" }}>
              <HamburgerMenu />
            </Box>
            <Box
              sx={{
                marginLeft: { md: "6.6vw", xs: "0px" },
                marginRight: { md: "20px", xs: "0px" },
              }}
            >
              <Link style={{ textDecoration: "none" }} to={"/"}>
                {windowWidth > 500 ? (
                  <img
                    src={require("../../assets/logo_header.png")}
                    style={{ width: "357px", height: "66px" }}
                    alt="academy_logo"
                  />
                ) : (
                  <img
                    src={bigLogo}
                    style={{ width: "250px" }}
                    alt="academy_logo"
                  />
                )}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              marginRight: { md: "7.78vw", xs: "0px" },
              maxWidth: "469px",
              width: { md: "100%" },
              justifyContent: "space-between",
              gap: { md: "0px", xs: "15px" },
            }}
          >
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                Academy
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/faq"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/faq") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                FAQ
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/kontakt"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/kontakt") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                Kontakt
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        id="header"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "29px",
          paddingBottom: "27px",
          backgroundColor: "white",
          position: "fixed",
          top: 0,
          borderBottom: isScrolledBelowThreshold ? "1px solid" : "unset",
          zIndex: 50,
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "1440px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              marginLeft: { md: "6.6vw", xs: "0px" },
              marginRight: { md: "20px", xs: "0px" },
              display: { md: "block", xs: "none" },
            }}
          >
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <img
                src={bigLogo}
                style={{ maxWidth: "357px", maxHeight: "66px" }}
                alt="academy_logo"
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              paddingLeft: "9vw",
              paddingRight: "9vw",
              justifyContent: "end",
              // alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ position: "absolute", top: "-10px", left: "20px" }}>
              <HamburgerMenu />
            </Box>
            <Box
              sx={{
                marginLeft: { md: "6.6vw", xs: "0px" },
                marginRight: { md: "20px", xs: "0px" },
              }}
            >
              <Link style={{ textDecoration: "none" }} to={"/"}>
                {windowWidth > 500 ? (
                  <img
                    src={require("../../assets/logo_header.png")}
                    style={{ width: "357px", height: "66px" }}
                    alt="academy_logo"
                  />
                ) : (
                  <img
                    src={bigLogo}
                    style={{ width: "250px" }}
                    alt="academy_logo"
                  />
                )}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              marginRight: { md: "7.78vw", xs: "0px" },
              maxWidth: "469px",
              width: { md: "100%" },
              justifyContent: "space-between",
              gap: { md: "0px", xs: "15px" },
            }}
          >
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                Academy
              </Typography>
            </Link>
            <Box sx={{ position: "relative" }}>
              <Link
                style={{ textDecoration: "none" }}
                to={"/skolenia"}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-DemiBold",
                    color: isActive("/skolenia") ? "#f6961d" : "#1d1a56",
                    textTransform: "initial",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    fontSize: { sm: 22, xs: 18 },
                    marginTop: "8px",
                  }}
                >
                  Školenia
                </Typography>
              </Link>
              {isHovered && (
                <Box
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  sx={{
                    paddingTop: "15px",
                    position: "absolute",
                    zIndex: 150,
                    right: -110,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      minWidth: "300px",

                      display: { md: "flex", xs: "none" },

                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: "25px",
                      border: "2px solid",
                      borderColor: "#1b144a",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("istqb")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          Školenia ISTQB
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#istqb"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                          Školenia ISTQB
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("specializovane")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          Základy testovania
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#specializovane"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                          Základy testovania
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("kompas")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          Zručnosti pre trh práce <span style={{ color: "orange", marginLeft:"5px" }}>pre NEZAMESTNANÝCH</span>
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#kompas"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                        Zručnosti pre trh práce <span style={{ color: "orange", marginLeft:"5px" }}>pre NEZAMESTNANÝCH</span>
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("nestracaj-pracu")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            whiteSpace: "nowrap",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                        Príspevok na vzdelávanie <span style={{ color: "orange", marginLeft:"5px" }}>pre ZAMESTNANÝCH</span>
                        
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#nestracaj-pracu"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            whiteSpace: "nowrap",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                        Príspevok na vzdelávanie <span style={{ color: "orange", marginLeft:"5px" }}>pre ZAMESTNANÝCH</span>
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("financovanie")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          Financovanie školení
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#financovanie"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                          Financovanie školení
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("na-splatky")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          N<span style={{ color: "#f6961d" }}>AA S</span>
                          PLÁTKY{" "}
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#na-splatky"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                          N<span style={{ color: "#f6961d" }}>AA S</span>
                          PLÁTKY{" "}
                        </Typography>
                      </Link>
                    )}
                    {isActive("/skolenia") ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          cursor: "pointer",

                          // borderTopLeftRadius: "21px",
                          // borderTopRightRadius: "21px",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => scrollToSection("online-skolenie")}
                      >
                        <Typography
                          sx={{
                            paddingLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            height: "45px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            width: "100%",
                          }}
                        >
                          Online školenie
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          display: "flex",
                        }}
                        to={"/skolenia#online-skolenie"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            color: "#1d1a56",
                            textTransform: "initial",
                            display: "flex",
                            // borderTopLeftRadius: "21px",
                            // borderTopRightRadius: "21px",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: { sm: 20, xs: 18 },
                            height: "45px",
                            width: "100%",
                            justifyContent: "start",
                            paddingLeft: "20px",
                            "&:hover": {
                              backgroundColor: "#1b144a",
                              color: "white",
                            },
                          }}
                        >
                          Online školenie
                        </Typography>
                      </Link>
                    )}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("specializovane")}
                    >
                      Základy testovania
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#specializovane"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        Základy testovania
                      </Typography>
                    </Link>
                  )} */}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("kompas")}
                    >
                      KOMPAS+
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#kompas"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        KOMPAS+
                      </Typography>
                    </Link>
                  )} */}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("nestracaj-pracu")}
                    >
                      Nestrať prácu - vzdelávaj sa
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#nestracaj-pracu"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        Nestrať prácu vzdelávaj sa
                      </Typography>
                    </Link>
                  )} */}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("financovanie")}
                    >
                      Financovanie školení
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#financovanie"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        Financovanie školení
                      </Typography>
                    </Link>
                  )} */}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("na-splatky")}
                    >
                      N<span style={{ color: "#f6961d" }}>AA S</span>PLÁTKY{" "}
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#na-splatky"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        N<span style={{ color: "#f6961d" }}>AA S</span>PLÁTKY{" "}
                      </Typography>
                    </Link>
                  )} */}
                    {/* {isActive("/skolenia") ? (
                    <Box
                      sx={{
                        fontFamily: "VisbyCF-DemiBold",
                        color: "#1d1a56",
                        textTransform: "initial",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: { sm: 20, xs: 18 },
                        cursor: "pointer",
                        height: "45px",
                        width: "100%",
                        borderBottomLeftRadius: "21px",
                        borderBottomRightRadius: "21px",
                        justifyContent: "center",
                        "&:hover": {
                          backgroundColor: "#1b144a",
                          color: "white",
                        },
                      }}
                      onClick={() => scrollToSection("online-skolenie")}
                    >
                      Online školenie
                    </Box>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", width: "100%" }}
                      to={"/skolenia#online-skolenie"}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-DemiBold",
                          color: "#1d1a56",
                          textTransform: "initial",
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: { sm: 20, xs: 18 },
                          borderBottomLeftRadius: "21px",
                          borderBottomRightRadius: "21px",
                          height: "45px",
                          width: "100%",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#1b144a",
                            color: "white",
                          },
                        }}
                      >
                        Online školenie
                      </Typography>
                    </Link>
                  )} */}
                  </Box>
                </Box>
              )}
            </Box>
            <Link style={{ textDecoration: "none" }} to={"/faq"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/faq") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                FAQ
              </Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={"/kontakt"}>
              <Typography
                sx={{
                  fontFamily: "VisbyCF-DemiBold",
                  color: isActive("/kontakt") ? "#f6961d" : "#1d1a56",
                  textTransform: "initial",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: { sm: 22, xs: 18 },
                  marginTop: "8px",
                }}
              >
                Kontakt
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
