import { Box, Button, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import specializedCourses from "../../data/specializedCourses";
import istqbCourses from "../../data/istqbCourses";
import lectors from "../../data/lectors";
import arrow from "../../assets/arrow_biela.svg";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Stats = () => {
  const [studentCount, setStudentCount] = useState(null);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const yearsPassed = () => {
    const currentDate = new Date();
    const targetDate = new Date(2013, 8, 1); // Months are zero-based, so 8 represents September

    const timeDiff = currentDate.getTime() - targetDate.getTime();
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Accounting for leap years

    const years = Math.floor(timeDiff / millisecondsInYear);

    return years;
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  // FETCHING DATA
  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}get_no_trained_participants`
        );
        const data = response.data;
        setStudentCount(data.no_participants);
        console.log(studentCount);
      } catch (error) {
        console.log(error);
      }
    };

    fetchStudentCount();
  }, []);

  const stats = [
    {
      title: "akreditovaných školiteľov",
      number: lectors.length,
    },
    {
      title: "vyškolených študentov",
      number: studentCount !== null ? studentCount : "250",
    },
    {
      title: "ISTQB školení",
      number: istqbCourses.length,
    },
    {
      title: "špecializovaných školení",
      number: specializedCourses.length,
    },
    {
      title: "odškolených rokov na trhu",
      number: yearsPassed(),
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(stats.length / 1);

  const startIndex = (currentPage - 1) * 1;
  const endIndex = startIndex + 1;
  const currentItems = stats.slice(startIndex, endIndex);

  const [timer, setTimer] = useState(null);

  const startInterval = () => {
    const newTimer = setInterval(() => {
      setCurrentPage((prevItem) =>
        prevItem === totalPages ? 1 : prevItem + 1
      );
    }, 5000);
    setTimer(newTimer);
  };

  const stopInterval = () => {
    clearInterval(timer);
    setTimer(null);
  };

  useEffect(() => {
    startInterval();

    return () => {
      if (timer) {
        stopInterval();
      }
    };
  }, [totalPages]);

  // width handling

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {windowWidth < 600 ? (
        <Grid
          container
          sx={{
            maxWidth: "1440px",
            display: "flex",
            width: "100%",
            justifyContent: { lg: "space-between", xs: "center" },
            alignItems: "center",
            marginTop: "70px",
            marginBottom: "21px",
            paddingLeft: "6.6vw",
            paddingRight: "6.6vw",
            gap: "25px",
          }}
        >
          {currentItems.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{
                  width: "168px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  item
                  sx={{
                    marginBottom: "21px",
                    height: "130px",
                    width: "130px",
                    borderRadius: "50%",
                    border: "2px solid",
                    borderColor: "#1d1a56",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-ExtraBold",
                      fontSize: 40,
                      color: "#f6961d",
                    }}
                  >
                    {/* {item.number} */}
                    <CountUp start={0} end={item.number} duration={3} />
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "VisbyCF-DemiBold",
                    fontSize: 22,
                    lineHeight: "26px",
                    color: "#1d1a56",
                  }}
                >
                  {item.title}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            maxWidth: "1440px",
            display: "flex",
            width: "100%",
            justifyContent: { lg: "space-between", xs: "center" },
            alignItems: "start",
            marginTop: "70px",
            marginBottom: "21px",
            paddingLeft: "6.6vw",
            paddingRight: "6.6vw",
            gap: "25px",
          }}
        >
          {stats.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                sx={{
                  width: "168px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  item
                  sx={{
                    marginBottom: "21px",
                    height: "130px",
                    width: "130px",
                    borderRadius: "50%",
                    border: "2px solid",
                    borderColor: "#1d1a56",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-ExtraBold",
                      fontSize: 40,
                      color: "#f6961d",
                    }}
                  >
                    <CountUp start={0} end={item.number} duration={7} />
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "VisbyCF-DemiBold",
                    fontSize: 22,
                    lineHeight: "26px",
                    color: "#1d1a56",
                  }}
                >
                  {item.title}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}

      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Button
          onClick={() => scrollToSection("about_us")}
          sx={{
            borderRadius: "28px",
            backgroundColor: "#f6961d",
            fontFamily: "VisbyCF-Bold",
            fontSize: 16,
            cursor: "pointer",
            color: "white",
            height: "55px",
            width: "268px",
            marginTop: "50px",
            textTransform: "initial",
            marginBottom: "62px",
            "&:hover": {
              backgroundColor: "#3fcaea",
            },
          }}
          endIcon={
            <Box
              sx={{
                width: "21px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  rotate: "180deg",
                  width: "60px",
                  position: "absolute",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
                src={arrow}
                alt="academy_logo"
              />
            </Box>
          }
        >
          Viac o AAS ACADEMY
        </Button>
      </motion.div>
    </Box>
  );
};

export default Stats;
