import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Collapse,
  Link as LinkMui,
  Button,
} from "@mui/material";
import FormUcastnik from "../../components/global/FormUcastnik";

const Ucastnik = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        style={{
          paddingTop: "35px",
          paddingBottom: "35px",
          width: "357px",
          height: "66px",
        }}
        src={require("../../assets/logo_header.png")}
        alt="academy_logo"
      />
      <Typography
        sx={{
          fontSize: 35,
          fontFamily: "VisbyCF-Medium",
          color: "#1b144a",
          lineHeight: "65px",
          marginBottom: "35px",
          textAlign: "center",
        }}
      >
        Pridať účastníkov kurzu
      </Typography>
      <FormUcastnik />
    </Box>
  );
};

export default Ucastnik;
