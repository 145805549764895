import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Switch, Typography } from "@mui/material";
import lectors from "../data/lectors";
import styles from "./header.module.css";
import Robo from "../assets/robo.png";
import Beata from "../assets/beata.png";
import Martin from "../assets/martin.png";
import Jana from "../assets/jana.png";
import Daniel from "../assets/daniel.png";
import Tomas from "../assets/profile/Tomas-main.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import arrow from "../assets/arrow_wide_white.svg";

const Lectors = ({ itemsPerPage, lectorId }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(lectors.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [timer, setTimer] = useState(null);

  const startInterval = () => {
    const newTimer = setInterval(() => {
      setCurrentPage((prevItem) =>
        prevItem === totalPages ? 1 : prevItem + 1
      );
    }, 3000);
    setTimer(newTimer);
  };

  const stopInterval = () => {
    clearInterval(timer);
    setTimer(null);
  };

  useEffect(() => {
    startInterval();

    return () => {
      if (timer) {
        stopInterval();
      }
    };
  }, [totalPages]);

  const handleMouseEnter = () => {
    if (timer) {
      stopInterval();
    }
  };

  const handleMouseLeave = () => {
    if (!timer) {
      startInterval();
    }
  };

  useEffect(() => {
    if (lectorId) {
      setCurrentPage(lectorId + 1);
      stopInterval();
    }
  }, [lectorId]);

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnPhoto = (id) => {
    var image;
    id = Number(id);
    switch (id) {
      case 1:
        image = Robo;
        break;
      case 2:
        image = Beata;
        break;
      case 3:
        image = Martin;
        break;
      case 4:
        image = Jana;
        break;
      case 5:
        image = Daniel;
        break;
      case 6:
        image = Tomas;
    }
    return image;
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Box
          key={i}
          sx={{
            cursor: "pointer",
            backgroundColor: currentPage === i ? "white" : "#9b9b9b",
            borderRadius: "50%",
            border: currentPage === i ? "4px solid" : "none",
            borderColor: "#7b7cd5",
            width: "8px",
            height: "8px",
            marginTop: "85px",
            marginLeft: currentPage === i ? "14px" : "16px",
            marginRight: currentPage === i ? "14px" : "16px",
          }}
          onClick={() => handlePageClick(i)}
        ></Box>
      );
    }
    return pages;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = lectors.slice(startIndex, endIndex);

  return (
    <Box
      onClick={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id="lectors"
      sx={{
        maxWidth: "1440px",
        width: "100%",
        position: "relative",
        cursor: "pointer",
        // height: windowWidth < 1020 ? "1200px" : "unset",
        height: {
          xs: "1200px",
          sm: "1000px",
          md: windowWidth > 1020 ? "unset" : "900px",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: windowWidth > 1020 ? "center" : "unset",
          zIndex: 1,
        }}
      >
        <img
          onClick={() => {
            setCurrentPage((prevItem) =>
              prevItem === 1 ? totalPages : prevItem - 1
            );
          }}
          src={arrow}
          style={{
            height: windowWidth > 600 ? "65px" : "40px",
            cursor: "pointer",
            marginTop: windowWidth > 1020 ? 0 : "280px",
          }}
          alt="arrow_l"
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: windowWidth > 1020 ? "center" : "unset",
          zIndex: 1,
          right: 0,
        }}
      >
        <img
          onClick={() => {
            setCurrentPage((prevItem) =>
              prevItem === totalPages ? 1 : prevItem + 1
            );
          }}
          src={arrow}
          style={{
            rotate: "180deg",
            height: windowWidth > 600 ? "65px" : "40px",
            cursor: "pointer",
            marginBottom: "10px",
            marginTop: windowWidth > 1020 ? 0 : "280px",
            right: 0,
          }}
          alt="arrow_r"
        />
      </Box>
      {currentItems.map((item) => (
        <motion.div
          ref={ref}
          key={item.id}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Box>
            {windowWidth > 1020 ? (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "1440px",
                  width: "100%",
                  zIndex: 10,
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50%",
                    backgroundImage: `url(${returnPhoto(item.id)})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    marginTop: "184px",
                    width: { md: "300px", xs: "150px" },
                    height: { md: "300px", xs: "150px" },
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    marginLeft: { lg: "189px", xs: "9vw" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-ExtraBold",
                      fontSize: 29,
                      color: "white",
                      letterSpacing: 2,
                      marginLeft: item.id == 6 ? "20px" : "25px",
                    }}
                  >
                    A
                    <span style={{ color: "#f6961d" }}>
                      dopt
                      <br />
                    </span>
                    A
                    <span style={{ color: "#3fcaea" }}>
                      nother
                      <br />
                    </span>
                    S<span style={{ color: "#7b7cd5" }}>kill</span>
                  </Typography>
                  <img
                    className={styles.element}
                    style={{
                      position: "absolute",
                      top: "230px",
                      marginLeft: "35px",
                    }}
                    src={require("../assets/wave.png")}
                    alt="wave"
                  />
                </Box>
                <Box
                  sx={{
                    width: "490px",
                    height: "450px",
                    marginTop: "85px",
                    marginRight: windowWidth > 1300 ? "260px" : "9vw",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 38,
                      color: "white",
                      lineHeight: "44px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      marginTop: "3px",
                      fontSize: 20,
                      color: "#f6961d",
                    }}
                  >
                    {item.role}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 22,
                      color: "white",
                      marginTop: "30px",
                      lineHeight: "28px",
                    }}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Bold",
                      fontSize: { md: 22, xs: 18 },
                      color: "white",
                      marginTop: "22px",
                    }}
                  >
                    {item.highlight}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "1440px",
                  width: "100%",
                  marginTop: "50px",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        backgroundImage: `url(${returnPhoto(item.id)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        width: "300px",
                        height: "300px",
                        display: "flex",
                        position: "relative",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-ExtraBold",
                          fontSize: 22,
                          color: "white",
                          letterSpacing: 2,
                          marginLeft: "25px",
                        }}
                      >
                        A
                        <span style={{ color: "#f6961d" }}>
                          dopt
                          <br />
                        </span>
                        A
                        <span style={{ color: "#3fcaea" }}>
                          nother
                          <br />
                        </span>
                        S<span style={{ color: "#7b7cd5" }}>kill</span>
                      </Typography>
                      {/* <img
                        className={styles.element}
                        style={{
                          position: "absolute",
                          top: "230px",
                          marginLeft: "35px",
                        }}
                        src={require("../assets/wave.png")}
                        alt="wave"
                      /> */}
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 38,
                          color: "white",
                          lineHeight: "44px",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          marginTop: "3px",
                          fontSize: 20,
                          color: "#f6961d",
                          textAlign: "center",
                        }}
                      >
                        {item.role}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 18,
                      color: "white",
                      marginTop: "30px",
                      lineHeight: "28px",
                      textAlign: "center",
                      paddingRight: "9vw",
                      paddingLeft: "9vw",
                    }}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "VisbyCF-Bold",
                      fontSize: 18,
                      color: "white",
                      marginTop: "22px",
                      textAlign: "center",
                      paddingRight: "9vw",
                      paddingLeft: "9vw",
                    }}
                  >
                    {item.highlight}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </motion.div>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "50px",
          position: "relative",
          zIndex: 3,
        }}
      >
        {/* <img
          onClick={() => {
            setCurrentPage((prevItem) =>
              prevItem === 1 ? totalPages : prevItem - 1
            );
          }}
          src={require("../assets/fat_arrow.png")}
          style={{
            width: "16px",
            rotate: "270deg",
            marginTop: "85px",
            cursor: "pointer",
          }}
          alt="wave"
        /> */}
        {renderPages()}
        {/* <img
          onClick={() => {
            setCurrentPage((prevItem) =>
              prevItem === totalPages ? 1 : prevItem + 1
            );
          }}
          src={require("../assets/fat_arrow.png")}
          style={{
            width: "16px",
            rotate: "90deg",
            marginTop: "85px",
            cursor: "pointer",
          }}
          alt="wave"
        /> */}
      </Box>
    </Box>
  );
};

export default Lectors;
