import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Link as LinkMui } from "@mui/material";
import styles from "./header.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon from "../../assets/skolenie_ikona_blue.svg";
import axios from "axios";

const Products = ({ courses, openContactForm, setContactForm, windowWidth }) => {
  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)"; // Customize the shadow value as needed
  const [fetchedData, setFetchedData] = useState([]);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  // FETCHING DATA
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}trainings?filter=with_valid_terms`);
        const data = response.data;
        setFetchedData(data.data);
        console.log(`fetched` + fetchedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseData();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxWidth: "1440px",
          width: "100%",
          position: "relative",
        }}
      >
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          {/* <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        > */}
          <Box
            sx={{
              marginTop: "37px",
              // backgroundColor: "red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              position: "relative",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 38,
                color: "#1d1a56",
                marginBottom: "15px",
              }}
            >
              Vybrané školenia{" "}
            </Typography>

            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 22,
                lineHeight: "28px",
                color: "#1d1a56",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              Ponúkame školenia formou online výuky. <br />
              Možnosť aj onsite školení na základe dohody.
            </Typography>
            <LinkMui
              onClick={() => setContactForm(true)}
              style={{
                textAlign: "center",
                fontFamily: "VisbyCF-Bold",
                fontSize: 22,
                lineHeight: "28px",
                color: "#1d1a56",
                decoration: "underline",
                textDecorationColor: "#1d1a56",
                cursor: "pointer",
              }}
            >
              kontaktovať
            </LinkMui>
          </Box>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Grid
            container
            sx={{
              paddingLeft: windowWidth < 2700 ? "9vw" : "0",
              paddingRight: windowWidth < 2700 ? "9vw" : "0",
              marginTop: "20px",
              position: "relative",
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              gap: "50px",
              marginBottom: "80px",
            }}
          >
            {courses.map((course, index) => {
              return (
                <Grid
                  key={index}
                  item
                  // xs={12}
                  // sm={6}
                  // md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "23px",
                    width: "283px",
                    transform: "scale(1)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      // Styles on hover
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "37px",
                        width: "178px",
                        backgroundColor: "#3fcaea",
                        borderTopLeftRadius: "22px",
                        borderTopRightRadius: "22px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-ExtraBold",
                          fontSize: 18,
                          color: "white",
                          marginBottom: "1px",
                        }}
                      >
                        online/onsite
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "542px",
                        // TODO
                        // width: "281px",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        boxShadow: shadowStyle,
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "225px",
                        }}
                      >
                        <img
                          src={icon}
                          alt="student_hat"
                          style={{
                            // width: "77px",
                            height: "150px",
                            // marginTop: "30px",
                            position: "absolute",
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            fontSize: 22,
                            marginTop: "150px",
                            color: "#7b7cd5",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                            lineHeight: "26px",
                          }}
                        >
                          {fetchedData.find((obj) => obj.id === course.id) ? fetchedData.find((obj) => obj.id === course.id).name : ""}
                        </Typography>
                        {course.duration && (
                          <Typography
                            sx={{
                              fontFamily: "VisbyCF-DemiBold",
                              fontSize: 22,
                              color: "#7b7cd5",
                              textAlign: "center",
                              marginRight: "25px",
                              marginLeft: "25px",
                              lineHeight: "26px",
                            }}
                          >
                            {fetchedData.find((obj) => obj.id === course.id) ? fetchedData.find((obj) => obj.id === course.id).duration_text : ""}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 18,
                          lineHeight: "22px",
                          color: "#1b144a",
                          textAlign: "center",
                          marginRight: "25px",
                          marginLeft: "25px",
                        }}
                      >
                        {course.description}
                      </Typography>

                      {course.showPrice ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "120px",
                          }}
                        >
                          {fetchedData.find((obj) => obj.id === course.id) && (
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontFamily: "VisbyCF-ExtraBold",
                                  fontSize: 32,
                                  lineHeight: "36px",
                                  color: "#1d1a56",
                                  textAlign: "center",
                                }}
                              >
                                {fetchedData.find((obj) => obj.id === course.id).price} €
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "VisbyCF-Medium",
                                  fontSize: 16,
                                  marginTop: "2px",
                                  marginLeft: "6px",
                                  lineHeight: "36px",
                                  color: "#1d1a56",
                                  textAlign: "center",
                                }}
                              >
                                bez DPH
                              </Typography>
                            </Box>
                          )}
                          <Button
                            component="a"
                            href={course.istqb ? `/skolenia/istqb/${course.id}` : `/skolenie/${course.id}`}
                            sx={{
                              borderRadius: "28px",
                              border: "2px solid",
                              marginTop: "21px",
                              borderColor: "#7b7cd5",
                              fontFamily: "VisbyCF-Medium",
                              fontSize: 16,
                              cursor: "pointer",
                              color: "#1d1a56",
                              height: "44px",
                              width: "186px",
                              textTransform: "initial",
                              "&:hover": {
                                backgroundColor: "#7b7cd5",
                                color: "white",
                              },
                            }}
                          >
                            Viac o školení
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "120px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                fontFamily: "VisbyCF-ExtraBold",
                                fontSize: 22,
                                lineHeight: "36px",
                                color: "#1d1a56",
                                textAlign: "center",
                              }}
                            >
                              Na požiadanie
                            </Typography>
                          </Box>
                          <Button
                            component="a"
                            href={course.istqb ? `/skolenia/istqb/${course.id}` : `/skolenie/${course.id}`}
                            sx={{
                              borderRadius: "28px",
                              border: "2px solid",
                              marginTop: "23px",
                              borderColor: "#7b7cd5",
                              fontFamily: "VisbyCF-Medium",
                              fontSize: 16,
                              cursor: "pointer",
                              color: "#1d1a56",
                              height: "44px",
                              width: "186px",
                              textTransform: "initial",
                              "&:hover": {
                                backgroundColor: "#7b7cd5",
                                color: "white",
                              },
                            }}
                          >
                            Viac o školení
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </motion.div>
        <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 100,
            zIndex: 1,
          }}
          src={require("../../assets/elements2.png")}
          alt="academy_logo"
        />
      </Box>
    </Box>
  );
};

export default Products;
