// import * as React from "react";
import { Box } from "@mui/material";
import IntroCourses from "../../components/courses/IntroCourses";
import Loopingpromo from "../../components/global/Loopingpromo";
import Istqbcourses from "../../components/courses/Istqbcourses";
import Courses from "../../components/courses/Courses";
import WhyIstqb from "../../components/courses/WhyIstqb";
import Testimonials from "../../components/global/Testimonials";
import TeamBanner from "../../components/global/TeamBanner";
import Lectors from "../../components/Lectors";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/global/Footer";
import Kompas from "../../components/courses/Kompas";
import Dontlosejob from "../../components/courses/Dontlosejob";
import Financing from "../../components/courses/Financing";
import Conditions from "../../components/courses/Conditions";
import Accredited from "../../components/courses/Accredited";
import istqbCourses from "../../data/istqbCourses";
import financing from "../../data/financing";
import ScrollToTopButton from "../../components/global/ScrollToTop";
import coursesSpecialized from "../../data/specializedCourses";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import FormKontaktGlobal from "../../components/global/FormKontaktGlobal";
import FormNewsletter from "../../components/global/FormNewsletter";
import Loan from "../../components/courses/Loan";
import Header from "../../components/global/Header";
import ButtonFinancovanie from "../../components/global/ButtonFinancovanie";
import axios from "axios";

const Skolenia = () => {
  // popup kontakt form
  const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);
  const background = useRef(null);
  const [openContactForm, setContactForm] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [lectorId, setLector] = useState("");
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    // Get the URL parameters
    const searchParams = new URLSearchParams(window.location.search);
    const lector = searchParams.get("lector");
    setLector(lector);
    // Use the 'lector' parameter value
    console.log(lector);

    // Do further processing with the 'lector' value as needed
  }, []);

  const handleClick = (e) => {
    if (e.target.id === "bg") setContactForm(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.hash.substring(1)) {
      const hash = window.location.hash.substring(1);
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);

  // newsletter handling
  const backgroundNews = useRef(null);
  const [openNewsForm, setNewsForm] = useState(false);
  const handleClickNews = (e) => {
    if (e.target.id === "bgnews") setNewsForm(false);
  };

  // btn fin
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200; // Adjust this value as needed

      if (scrollPosition > threshold) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // width handling

  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // FETCHING DATA
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}trainings?filter=with_valid_terms`);
        const data = response.data;
        setFetchedData(data.data);
        // console.log(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseData();
  }, []);

  return (
    <Box>
      {showContent && windowWidth > 1100 && <ButtonFinancovanie isSkolenia={true} />}
      <Header />
      <IntroCourses windowWidth={windowWidth} />
      <Loopingpromo />
      <Istqbcourses courses={istqbCourses} fetchedData={fetchedData} windowWidth={windowWidth} />
      <ScrollToTopButton />
      <WhyIstqb />
      <Courses
        courses={coursesSpecialized}
        setContactForm={setContactForm}
        setContactMessage={setContactMessage}
        fetchedData={fetchedData}
        windowWidth={windowWidth}
      />
      <Testimonials />
      <Kompas setContactForm={setContactForm} setContactMessage={setContactMessage} />
      <Dontlosejob setContactForm={setContactForm} setContactMessage={setContactMessage} />
      <Financing finances={financing} setContactForm={setContactForm} setContactMessage={setContactMessage} />
      <Loan setContactForm={setContactForm} setContactMessage={setContactMessage} />
      {/* <Conditions /> */}
      <Accredited />
      <TeamBanner />
      <Box
        sx={{
          backgroundColor: "#1b144a",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Lectors itemsPerPage={1} lectorId={parseInt(lectorId)}></Lectors>
      </Box>
      {/* newsletter subscription */}
      <Newsletter setNewsForm={setNewsForm} />
      {/* form newsletter */}
      {openNewsForm && (
        <Box
          onClick={handleClickNews}
          id="bgnews"
          ref={backgroundNews}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          <FormNewsletter setNewsForm={setNewsForm} />
        </Box>
      )}
      <Footer setContactForm={setContactForm} setContactMessage={setContactMessage} />
      {openContactForm && (
        <Box
          onClick={handleClick}
          id="bg"
          ref={background}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          > */}
          <FormKontaktGlobal setContactForm={setContactForm} contactMessage={contactMessage} />
          {/* </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default Skolenia;
