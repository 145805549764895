import React, { useState, useRef } from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "./formCourse.css";
import axios from "axios";

const FormNewsletter = ({ setNewsForm, contactMessage = "" }) => {
  const [SubmitDisabled, setSubmitDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
  const recaptchaRef = useRef(null);

  const handleExpiredToken = () => {
    let token = recaptchaRef.current.getValue();
    if (token) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  };

  const textStyle = {
    "& .MuiOutlinedInput-root": {
      "& input": {
        // padding: "10px",
        // fontSize: "14px", // Font size for the input text
        fontFamily: "VisbyCF-Medium", // Font family for the input text
      },
      "& input::placeholder": {
        // fontSize: "14px", // Font size for the placeholder text
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
    "& .MuiInputLabel-root": {
      typography: {
        // fontSize: "14px", // Font size for the label
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
  };

  const checkBox = {
    height: "20px",
    "& .MuiTypography-root": {
      fontFamily: "VisbyCF-Medium",
      fontSize: 14,
    },
  };

  const [formValues, setFormValues] = useState({
    menoPriezvisko: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSubmitDisabled(true);
    const requestBody = {
      name: formValues.menoPriezvisko,
      email: formValues.email,
      g_recaptcha_response: recaptchaRef.current.getValue(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}newsletter_subscriptions`,
        requestBody
      );
      const data = response.data;
      setSubmitted(true);
      setLoading(false);
      setSubmitDisabled(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setErrorModal(true);
      setLoading(false);
      setSubmitDisabled(false);
    }
  };

  // if (state.succeeded) {
  //   return (
  //     <Box
  //       sx={{
  //         maxWidth: "500px",
  //         width: "100%",
  //         backgroundColor: "white",
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         paddingTop: "10px",
  //         paddingBottom: "50px",
  //         paddingLeft: "15px",
  //         paddingRight: "15px",
  //         borderRadius: "22px",
  //         position: "relative",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           width: "100%",
  //           display: "flex",
  //           justifyContent: "end",
  //           marginTop: "10px",
  //         }}
  //       >
  //         <img
  //           onClick={() => setNewsForm(false)}
  //           style={{ cursor: "pointer", rotate: "45deg" }}
  //           src={require("../../assets/red_plus.png")}
  //           alt="arrow_close"
  //         />
  //       </Box>
  //       <Typography
  //         sx={{
  //           color: "#1b144a",
  //           fontFamily: "VisbyCF-Bold",
  //           fontSize: "28px",
  //           lineHeight: "22px",
  //           marginBottom: "5px",
  //           textAlign: { md: "start", xs: "center" },
  //         }}
  //       >
  //         Ďakujeme!
  //       </Typography>
  //       <Typography
  //         sx={{
  //           color: "#1b144a",
  //           fontFamily: "VisbyCF-Bold",
  //           fontSize: "22px",
  //           lineHeight: "22px",
  //           marginBottom: "5px",
  //           textAlign: { md: "start", xs: "center" },
  //         }}
  //       >
  //         Žiadosť úspešne odoslaná.
  //       </Typography>
  //     </Box>
  //   );
  // }

  return (
    <Box>
      {!submitted && !errorModal && (
        <Box
          sx={{
            background: "white",
            zIndex: 5,
            maxWidth: "300px",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "22px",
            border: "1px solid",
            borderColor: "black",
            paddingBottom: "20px",
            height: "auto",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <img
                onClick={() => setNewsForm(false)}
                style={{ cursor: "pointer", rotate: "45deg" }}
                src={require("../../assets/red_plus.png")}
                alt="arrow_close"
              />
            </Box>
            <TextField
              sx={textStyle}
              id="meno-priezvisko"
              label="Meno a Priezvisko"
              placeholder="Meno a Priezvisko"
              variant="outlined"
              fullWidth
              margin="normal"
              name="menoPriezvisko"
              required
              value={formValues.menoPriezvisko}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 30, // Maximum character limit
              }}
            />

            <TextField
              sx={textStyle}
              id="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 50, // Maximum character limit
              }}
            />

            <Box
              sx={{
                height: "auto",
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={SITE_KEY}
                onChange={handleExpiredToken}
              />
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <Button
                disabled={SubmitDisabled}
                type="submit"
                variant="contained"
                sx={{ fontFamily: "VisbyCF-Medium", fontSize: 16 }}
              >
                Odoslať
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {submitted && (
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <img
              onClick={() => setNewsForm(false)}
              style={{ cursor: "pointer", rotate: "45deg" }}
              src={require("../../assets/red_plus.png")}
              alt="arrow_close"
            />
          </Box>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Ďakujeme!
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "22px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Žiadosť úspešne odoslaná.
          </Typography>
        </Box>
      )}
      {errorModal && (
        <Box
          sx={{
            maxWidth: "300px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            onClick={() => setNewsForm(false)}
            style={{
              cursor: "pointer",
              rotate: "45deg",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            src={require("../../assets/red_plus.png")}
            alt="arrow_close"
          />
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Nastala chyba
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormNewsletter;
