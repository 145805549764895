import React, { useState } from "react";
import { Box, Button, Grid, Collapse, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import redPlus from "../../assets/red_plus.svg";
import redMinus from "../../assets/red_minus.svg";
import orangePlus from "../../assets/orange_plus.svg";
import orangeMinus from "../../assets/orange_minus.svg";
import bluePlus from "../../assets/blue_plus.svg";
import blueMinus from "../../assets/blue_minus.svg";
import arrow from "../../assets/arrow_blue.svg";
import {scrollToSection} from "../../helpers/interactivityHelper";

const Dontlosejob = ({ setContactForm, setContactMessage }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const toggleCollapse = () => {
    if (!isOpen) {
      setIsOpenAbout(false);
      setIsOpenNegative(false);
    }
    setIsOpen(!isOpen);
  };
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const toggleCollapseAbout = () => {
    if (!isOpenAbout) {
      setIsOpen(false);
      setIsOpenNegative(false);
    }
    setIsOpenAbout(!isOpenAbout);
  };
  const [isOpenNegative, setIsOpenNegative] = useState(false);

  const toggleCollapseNegative = () => {
    if (!isOpenNegative) {
      setIsOpenAbout(false);
      setIsOpen(false);
        if (isOpenAbout) {
            scrollToSection("nestracaj-pracu-about");
        }
    }
    setIsOpenNegative(!isOpenNegative);
  };

  const openForm = () => {
    setContactForm(true);
    setContactMessage(
      "Potrebujem poradiť na tému Príspevok na vzdelávanie pre zamestnaných"
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        id="nestracaj-pracu"
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginBottom: "104px",
        }}
      >
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              wdith: "100%",
              marginTop: "70px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            DOPLŇ SI KVALIFIKÁCIU
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "15px",
              // maxWidth: "358px",
              zIndex: 2,
            }}
          >
            PRÍSPEVOK NA VZDELÁVANIE
            <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
              <br />
              PRE ZAMESTNANÝCH
            </span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              maxWidth: "602px",
              wdith: "100%",
              marginTop: "4px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Tento program je určený na podporu vzdelávania v oblasti
            nedostatkových profesií, digitalizácie, automatizácie a zeleného
            hospodárstva.
          </Typography>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={
            inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
          }
          transition={{ duration: 0.7 }}
        >
          <Box
            sx={{
              marginTop: "78px",
              width: "100%",
              minHeight: "604px",
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              alignItems: "start",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  // marginRight: { lg: "8.61vw", xs: "0px" },
                  width: "auto",
                  display: { xs: "flex", lg: "block" },
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  maxWidth: { xs: "450px", lg: "722px" },
                  marginRight: { lg: "15px", sm: "0", xs: "10px" },
                  marginLeft: { lg: "9vw", sm: "0", xs: "10px" },
                  paddingBottom: "10px",
                  paddingTop: { xs: "10px", lg: "0px" },
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  src={require("../../assets/man_job.png")}
                  alt="students"
                />
                <Box
                  sx={{
                    minWidth: { sm: "500px", xs: "0px" },
                    width: "100%",
                    display: "flex",
                    justifyContent: { sm: "start", xs: "center" },
                    flexDirection: { lg: "row", xs: "column" },
                    gap: { lg: "0px", xs: "20px" },
                    alignItems: { lg: "row", xs: "center" },
                    marginTop: "59px",
                  }}
                >
                  <Button
                    component="a"
                    href="https://www.upsvr.gov.sk/sluzby-zamestnanosti/nastroje-aktivnych-opatreni-na-trhu-prace/narodny-projekt-zrucnosti-pre-trh-prace/poskytovanie-prispevkov-na-podporu-vzdelavania-zaujemcov-o-zamestnanie.html?page_id=1346524"
                    target="_blank"
                    sx={{
                      borderRadius: "28px",
                      border: "2px solid",
                      borderColor: "#1b144a",
                      fontFamily: "VisbyCF-Bold",
                      fontSize: 16,
                      overflow: "hidden",
                      cursor: "pointer",
                      color: "#1b144a",
                      height: "41px",
                      width: "221px",
                      textTransform: "initial",
                      marginRight: "12px",
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#1b144a",
                        color: "white",
                      },
                    }}
                  >
                    VIAC INFORMÁCII
                    <img
                      style={{
                        // marginLeft: "10px",
                        position: "absolute",
                        width: "150px",
                        rotate: "180deg",
                        right: -45,
                        marginTop: "10px",
                      }}
                      src={arrow}
                      alt="blue_download"
                    />
                    <Box sx={{ width: "30px" }}></Box>
                  </Button>
                  <Button
                    component="a"
                    href="/docs/ziadost_o_poskytnutie_prispevku_na_podporu_vzdelavania__zaujemcu_o_zamestnanie.docx"
                    target="_blank"
                    sx={{
                      borderRadius: "28px",
                      border: "2px solid",
                      borderColor: "#1b144a",
                      fontFamily: "VisbyCF-Bold",
                      fontSize: 16,
                      cursor: "pointer",
                      color: "#1b144a",
                      height: "41px",
                      width: "334px",
                      position: "relative",
                      textTransform: "initial",
                      marginRight: "12px",
                      overflow: "hidden",
                      "&:hover": {
                        backgroundColor: "#1b144a",
                        color: "white",
                      },
                    }}
                  >
                    Žiadosť o poskytnutie príspevku
                    <img
                      style={{
                        // marginLeft: "10px",
                        position: "absolute",
                        width: "150px",
                        rotate: "180deg",
                        right: -45,
                        marginTop: "10px",
                      }}
                      src={arrow}
                      alt="blue_download"
                    />
                    <Box sx={{ width: "30px" }}></Box>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                // marginLeft: { lg: "120px", xs: "0px" },
                marginRight: { lg: "120px", xs: "0px" },
                gap: "10px",
                width: { lg: "403px", xs: "100%" },
                paddingLeft: { xs: "10px", md: "0px" },
                paddingRight: { xs: "10px", md: "0px" },
              }}
            >
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpen ? "475px" : "130px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }} id="nestracaj-pracu-about">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#3fcaea",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      O programe Zručnosti pre trh práce pre zamestnaných
                    </Typography>
                    <Link onClick={toggleCollapse}>
                      <img
                        src={isOpen ? blueMinus : bluePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: { sm: "22px" },
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                      Zručnosti pre trh práce pre zamestnaných
                      </span>{" "}
                      je program určený pre tých z vás, ktorí chcú rozvíjať
                      svoje zručnosti v aktuálnom pracovnom zaradení v súlade s
                      aktuálnymi potrebami trhu práce, alebo sa chcú pripraviť
                      na hľadanie novej práce v budúcnosti. Ak aktuálne nie si
                      evidovaný ako nezamestnaný na úrade práce, uvažuješ o
                      zmene zamestnania, si na materskej alebo rodičovskej
                      dovolenke, SZČO, študent strednej alebo vysokej školy.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenAbout ? "1050px" : "85px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#f6961d",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      Ako ho získať
                    </Typography>
                    <Link onClick={toggleCollapseAbout}>
                      <img
                        src={isOpenAbout ? orangeMinus : orangePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenAbout} timeout={300} unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: { sm: "22px" },
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        Ako získať program Zručnosti pre trh práce pre zamestnaných:
                      </span>
                      <br />
                      <br /> 1.{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        Máš záujem o uplatnenie v IT?
                      </span>{" "}
                      Vyber si kurz z našej ponuky. Ak sa nevieš rozhodnúť, radi
                      ti s výberom pomôžeme, stačí nás kontaktovať. <br />
                      <br />
                      2.{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                      Vyplň
                      </span>{" "}
                      {" "}
                      <Link
                        style={{
                          fontFamily: "VisbyCF-ExtraBold",
                          fontSize: 18,
                          lineHeight: "22px",
                          color: "white",
                        }}
                        to="/docs/ziadost_o_poskytnutie_prispevku_na_podporu_vzdelavania__zaujemcu_o_zamestnanie.docx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        žiadost o poskytnutie príspevku na vzdelávanie ZoZ.
                      </Link>{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        My vyplníme časť B
                      </span>{" "}
                      a poskytneme dokumenty potrebné k schváleniu. Ak si si s
                      niečím neistý/á radi ti pri vypĺňaní pomôžeme. <br />
                      <br />
                      3.
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        {" "}
                        Pre zvýšenie svojich šancí otestuj svoje vedomosti
                        digitálnych zručností bezplatným online testom{" "}
                        <Link
                          style={{
                            fontFamily: "VisbyCF-ExtraBold",
                            fontSize: 18,
                            lineHeight: "22px",
                            color: "white",
                          }}
                          to="https://itfitness.eu/sk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          IT Fitness Test
                        </Link>
                        .
                      </span>{" "}
                      Výsledkom bude tvoje percentuálne skóre z testu na
                      Certifikáte. <br />
                      <br />
                      4.{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        Odovzdaj vytlačenú žiadosť (nepovinne pridaj vytlačený
                        certifikát z IT Fitness testu) na úrade práce minimálne
                        30 dní pred termínom školenia
                      </span>
                      , ktorého sa chceš zúčastniť. <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        5. O schválení žiadosti nás ihneď informuj
                      </span>{" "}
                      a prihlás sa na vybraný termín a typ kurzu prostredníctvom
                      nášho webu. Úrad s tebou uzavrie dohodu o poskytnutí
                      príspevku a poskytne ti doklady, ktoré nám je potrebné
                      odovzdať najneskôr v deň začatia kurzu. <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        6. Uži si kurz
                      </span>{" "}
                      a získaj cenné vedomosti pre svoje aktuálne alebo budúce
                      uplatnenie. <br />
                      <br />
                      7.{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        Po absolvovaní kurzu od nás získaš certifikát a ďalšie
                        doklady, ktoré následne odovzdáš úradu.
                      </span>{" "}
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenNegative ? "372px" : "85px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#7b7cd5",
                        marginLeft: "39px",
                      }}
                    >
                      Dôvody neschválenia
                    </Typography>
                    <Link onClick={toggleCollapseNegative}>
                      <img
                        src={isOpenNegative ? redMinus : redPlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenNegative} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: 18,
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: { sm: "22px" },
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        Pozor na najčastejšie dôvody neschválenia kurzu:
                      </span>{" "}
                      <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        ● Nespĺňaš predpoklady definované úradom.
                      </span>{" "}
                      V prvom rade je potrebné zaevidovať sa na ktoromkoľvek
                      úrade práce ako záujemca o zamestnanie. <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                        ● Nepodal/a si žiadosť včas.
                      </span>{" "}
                      Žiadosť je potrebné podať na Úrad práce včas, najneskôr 30
                      pracovných dní pred preferovaným termínom začiatku kurzu.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                onClick={() => openForm()}
                sx={{
                  backgroundColor: "#f6961d",
                  height: "85px",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Link style={{ textDecoration: "none" }}>
                  <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: "18px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 28,
                          lineHeight: "28px",
                          color: "#1b144a",
                          marginLeft: "29px",
                        }}
                      >
                        Pomôcť vybaviť{" "}
                        <span style={{ marginLeft: "25px" }}>{">>>"}</span>
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Grid>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default Dontlosejob;
