import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Loopingpromo from "../../components/global/Loopingpromo";
import Description from "../../components/courseDetail/istqb/description";
import Calendar from "../../components/courseDetail/istqb/calendar";
import More from "../../components/courseDetail/istqb/More";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/global/Footer";
import FormKontaktGlobal from "../../components/global/FormKontaktGlobal";
import FormNewsletter from "../../components/global/FormNewsletter";
import axios from "axios";
import Header from "../../components/global/Header";

const SkolenieIstqb = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [isSelectedDate, setIsSelectedDate] = useState("");
  const [isOpenOnline, setIsOpenOnline] = useState(false);
  const [fetchedData, setFetchedData] = useState("");

  const handleFormOpen = () => {
    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.hash.substring(1)) {
      const hash = window.location.hash.substring(1);
      const section = document.getElementById(hash);
      setIsOpenOnline(true);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);

  const [openContactForm, setContactForm] = useState(false);
  const background = useRef(null);
  const handleClick = (e) => {
    if (e.target.id === "bg") setContactForm(false);
  };

  // newsletter handling
  const backgroundNews = useRef(null);
  const [openNewsForm, setNewsForm] = useState(false);
  const handleClickNews = (e) => {
    if (e.target.id === "bgnews") setNewsForm(false);
  };

  // FETCHING DATA
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}trainings/${id}?filter=with_valid_terms`);
        const data = response.data;
        setFetchedData(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseData();
  }, []);

  return (
    <Box>
      <Header />
      <Loopingpromo />
      <Description
        courseId={id}
        handleFormOpen={handleFormOpen}
        isFormOpen={isFormOpen}
        isSelectedDate={isSelectedDate}
        price={fetchedData.price}
        terms={fetchedData.terms}
        courseName={fetchedData.name}
        duration={fetchedData.duration_text}
        certification={fetchedData.certification}
      />
      <Calendar
        courseId={id}
        handleFormOpen={handleFormOpen}
        setContactMessage={setContactMessage}
        isFormOpen={isFormOpen}
        isSelectedDate={setIsSelectedDate}
        setContactForm={setContactForm}
        terms={fetchedData.terms}
        price={fetchedData.price}
      />
      <More courseId={id} isOpenOnline={isOpenOnline} setIsOpenOnline={setIsOpenOnline} />
      {/* newsletter subscription */}
      <Newsletter setNewsForm={setNewsForm} />
      {/* form newsletter */}
      {openNewsForm && (
        <Box
          onClick={handleClickNews}
          id="bgnews"
          ref={backgroundNews}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          <FormNewsletter setNewsForm={setNewsForm} />
        </Box>
      )}
      <Footer setContactForm={setContactForm} setContactMessage={setContactMessage} />
      {openContactForm && (
        <Box
          onClick={handleClick}
          id="bg"
          ref={background}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          <FormKontaktGlobal setContactForm={setContactForm} contactMessage={contactMessage} />
        </Box>
      )}
    </Box>
  );
};

export default SkolenieIstqb;
