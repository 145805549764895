import { Box, Typography } from "@mui/material";
import symbolEuro from "../../assets/symbol-euro.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import financovanie from "../../assets/financovanie_euro.svg";

const ButtonFinancovanie = ({ isSkolenia = false }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return !isSkolenia ? (
    <Link to="/skolenia#financovanie" style={{ textDecoration: "none" }}>
      <Box
        sx={{
          position: "fixed",
          right: -120,
          top: 400,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#3fcaea",
          padding: "15px 20px 20px 20px",
          borderTopLeftRadius: "22px",
          borderTopRightRadius: "22px",
          cursor: "pointer",
          rotate: "270deg",
        }}
      >
        <Box sx={{ position: "relative", width: "30px", height: "30px" }}>
          <ReactSVG
            src={financovanie}
            style={{
              rotate: "90deg",
              marginRight: "10px",
              width: "60px",
              position: "absolute",
              left: -30,
              top: -20,
            }}
          />
        </Box>
        <Typography
          sx={{
            fontFamily: "VisbyCF-Bold",
            fontSize: 18,
            color: "white",
          }}
        >
          FINANCOVANIE ŠKOLENÍ
        </Typography>
      </Box>
    </Link>
  ) : (
    <Link
      onClick={() => scrollToSection("financovanie")}
      style={{ textDecoration: "none" }}
    >
      <Box
        sx={{
          position: "fixed",
          right: -120,
          top: 400,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#3fcaea",
          padding: "10px 20px 5px 20px",
          borderTopLeftRadius: "22px",
          borderTopRightRadius: "22px",
          cursor: "pointer",
          rotate: "270deg",
        }}
      >
        <ReactSVG
          src={symbolEuro}
          style={{ rotate: "90deg", marginRight: "10px" }}
        />
        <Typography
          sx={{
            fontFamily: "VisbyCF-Bold",
            fontSize: 18,
            color: "white",
          }}
        >
          FINANCOVANIE ŠKOLENÍ
        </Typography>
      </Box>
    </Link>
  );
};

export default ButtonFinancovanie;
