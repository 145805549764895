import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Newsletter = ({ setNewsForm }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: { md: "165px", xs: "100px" },
        paddingBottom: { md: "165px", xs: "100px" },
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "1440px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 28,
              lineHeight: "28px",
              color: "#1d1a56",
              marginBottom: "27px",
            }}
          >
            Newsletter
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 55,
              lineHeight: "55px",
              color: "#1d1a56",
              marginBottom: { md: "65px", xs: "30px" },
              textAlign: "center",
            }}
          >
            ODOBERAJ N<span style={{ color: "#3fcaea" }}>AA</span>ŠE{" "}
            <span style={{ color: "#3fcaea" }}>S</span>NEAK PEEK
          </Typography>
          <Button
            onClick={() => setNewsForm(true)}
            sx={{
              borderRadius: "28px",
              backgroundColor: "#f6961d",
              fontFamily: "VisbyCF-Bold",
              fontSize: 16,
              cursor: "pointer",
              color: "white",
              height: "55px",
              width: "186px",
              textTransform: "initial",
              "&:hover": {
                backgroundColor: "#3fcaea",
              },
            }}
          >
            Prihlásiť
          </Button>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Newsletter;
